import { getShipmentIconColor } from "scripts/helpers";

function ShipmentBookedNoSquare(props: any) {
  const { backgroundColor }: any = getShipmentIconColor(props?.status);
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M13.334 2h-.667V.667h-1.333V2H4.667V.667H3.334V2h-.667c-.733 0-1.333.6-1.333 1.334V14c0 .734.6 1.334 1.333 1.334h10.667c.733 0 1.333-.6 1.333-1.334V3.334c0-.734-.6-1.334-1.333-1.334zm0 12H2.667V5.334h10.667V14z"
        fill={backgroundColor}
      />
    </svg>
  );
}

export default ShipmentBookedNoSquare;
