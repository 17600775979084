import * as React from "react";

const User = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 5.90356C12.9226 5.90356 10.4187 8.40723 10.4187 11.4849C10.4187 14.5623 12.9226 17.0659 16 17.0659C19.0774 17.0659 21.5813 14.5623 21.5813 11.4849C21.5813 8.40723 19.0774 5.90356 16 5.90356Z"
      fill="currentColor"
    />
    <path
      d="M16 0C7.17749 0 0 7.17749 0 16C0 20.082 1.53735 23.811 4.06226 26.6409C4.60303 24.2664 5.84399 22.0999 7.65601 20.4121C8.92773 19.2275 10.4158 18.3411 12.0242 17.7898C9.93433 16.4673 8.5437 14.1355 8.5437 11.4849C8.5437 7.37329 11.8887 4.02856 16 4.02856C20.1113 4.02856 23.4561 7.37329 23.4561 11.4849C23.4561 14.1343 22.0667 16.4653 19.9783 17.7881C21.7205 18.3848 23.3174 19.3755 24.658 20.7161C26.2959 22.354 27.4268 24.4128 27.9365 26.6418C30.4624 23.812 32 20.0825 32 16C32 7.17749 24.8225 0 16 0Z"
      fill="currentColor"
    />
    <path
      d="M5.69385 28.228C8.47998 30.5801 12.0769 32 16 32C19.9231 32 23.52 30.5798 26.3062 28.228C26.0496 25.897 25.002 23.7117 23.3323 22.042C21.3733 20.083 18.7693 19.0042 16 19.0042C10.7256 19.0042 6.26636 23.0278 5.69385 28.228Z"
      fill="currentColor"
    />
  </svg>
);

export default User;
