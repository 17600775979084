import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LOCATION_CHANGE, SERVICE_NAME } from "scripts/constants";

const Container = ({ children }: any) => {
  const location = useLocation();
  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(LOCATION_CHANGE, {
        bubbles: true,
        detail: SERVICE_NAME + location?.pathname,
      }),
    );
  }, [location?.pathname]);
  return <>{children}</>;
};

export default memo(Container);
