import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentCommitted(props:any) {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <rect x={1} y={1} width={26} height={26} rx={9} fill={backgroundColor} />
      <path
        d="M19.73 7.75h-1.563V6.056a.39.39 0 00-.39-.391h-1.303a.39.39 0 00-.39.39V7.75h-4.167V6.057a.39.39 0 00-.39-.391h-1.303a.39.39 0 00-.39.39V7.75H8.27c-.863 0-1.563.7-1.563 1.563V20.77c0 .863.7 1.563 1.563 1.563h11.458c.863 0 1.563-.7 1.563-1.563V9.312c0-.863-.7-1.563-1.563-1.563zm-.196 13.02H8.466a.195.195 0 01-.195-.195v-9.7h11.458v9.7a.195.195 0 01-.195.195zm-1.72-6.531l-4.633 4.595a.39.39 0 01-.552-.002l-2.445-2.464a.39.39 0 01.003-.553l.74-.733a.39.39 0 01.552.002l1.435 1.447 3.616-3.586a.39.39 0 01.552.002l.734.74a.39.39 0 01-.002.552z"
        fill={iconColor}
      />
      <rect x={1} y={1} width={26} height={26} rx={9} stroke={borderColor} strokeWidth={2} />
    </svg>
  );
}

export default ShipmentCommitted
