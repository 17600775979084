import * as React from "react";

function BackTop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" fill="none" {...props}>
      <g opacity={0.4}>
        <path
          d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20z"
          fill="#1F2937"
        />
        <path
          d="M19.168 14.653v-.5h-3.29L20 10.04l4.124 4.113h-3.29v10.68h-1.667v-10.18zM24.835 28.5v1.667h-9.667V28.5h9.667z"
          fill="#fff"
          stroke="#fff"
        />
      </g>
    </svg>
  );
}

const MemoBackTop = React.memo(BackTop);
export default MemoBackTop;
