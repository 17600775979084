import { Spin } from "antd";
import "./SpinLoading.scss";

const SpinLoading = ({ className = "" }: { className?: string }) => {
  return (
    <div className={`spin-loading-wrapper ${className}`}>
      <Spin />
    </div>
  );
};

export default SpinLoading;
