import Container from "components/Container";
import PublicRoute from "components/PublicRoute";
import i18next from "i18next";
import NotFound from "pages/NotFound";
import { memo, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "ScollToTop";
import { getRedux } from "scripts/helpers";
import { PUBLIC_ROUTES } from "scripts/routes";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-with-locales-es6";
import "./translations/moment/en";
import "./translations/moment/vi";
import { BackTop } from "antd";
import MemoBackTop from "components/svgs/BackTop";

const App = ({ path = "" }) => {
  const { currentLanguage: language = "en" } = getRedux(`app`, {});

  useEffect(() => {
    moment.locale(language);
    i18next.changeLanguage(language);
  }, [language]);

  return (
    <div className="skyviews h-full">
      <BackTop className="right-[16px] z-[9999]">
        <MemoBackTop />
      </BackTop>
      <ToastContainer
        style={{ width: "auto", maxWidth: 500 }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <BrowserRouter basename={path}>
        <Container>
          <ScrollToTop>
            <Routes>
              {PUBLIC_ROUTES?.map((item: any) => (
                <Route
                  path={item.path}
                  key={item.path}
                  element={
                    <PublicRoute exact component={item.component} path={item.path} {...item} />
                  }
                />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ScrollToTop>
        </Container>
      </BrowserRouter>
    </div>
  );
};
export default memo(App);
