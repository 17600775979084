import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentDeliveredNoSquare(props: any) {
  const { backgroundColor }: any = getShipmentIconColor(props?.status);
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86615 10.5999L3.06615 7.79987L2.13281 8.7332L5.86615 12.4665L13.8661 4.46654L12.9328 3.5332L5.86615 10.5999Z"
        fill={backgroundColor}
      />
    </svg>
  );
}

export default ShipmentDeliveredNoSquare;
