import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentCreatedNoSquare(props: any) {

  const { backgroundColor }: any = getShipmentIconColor(props?.status);
  
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M2 3.333v9.333h12V3.333H2zm2.667 1.333V6H3.333V4.666h1.334zm-1.334 4V7.333h1.334v1.333H3.333zm0 1.334h1.334v1.333H3.333V10zm9.334 1.333H6V10h6.667v1.333zm0-2.667H6V7.333h6.667v1.333zm0-2.666H6V4.666h6.667V6z"
        fill={backgroundColor}
      />
    </svg>
  );
}

export default ShipmentCreatedNoSquare;
