import i18next from "i18next";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import App from "./app";
import store from "./store";
import "./assets/css/tailwind.css";
import "./assets/scss/app.scss";

import "./translations";

const queryClient = new QueryClient({});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </QueryClientProvider>,
  document.getElementById("console-public-website"),
);
