import * as React from "react";

function EmptyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="64px" height="40px" viewBox="0 0 64 40" fill="none" {...props}>
      <path
        d="M32 39.703c17.673 0 32-3.11 32-6.948 0-3.837-14.327-6.948-32-6.948-17.673 0-32 3.11-32 6.948 0 3.837 14.327 6.948 32 6.948z"
        fill="#D8E1FE"
      />
      <path
        d="M55 13.665L44.854 2.25C44.367 1.47 43.656 1 42.907 1H21.093c-.749 0-1.46.47-1.947 1.248L9 13.666v9.17h46v-9.17z"
        stroke="#7897FF"
      />
      <path
        d="M41.613 16.813c0-1.593.994-2.908 2.227-2.91H55v18.003c0 2.107-1.32 3.834-2.95 3.834h-40.1c-1.63 0-2.95-1.728-2.95-3.834V13.904h11.16c1.233 0 2.227 1.313 2.227 2.906v.022c0 1.593 1.005 2.88 2.237 2.88h14.752c1.232 0 2.237-1.3 2.237-2.892v-.007z"
        fill="#EFF3FF"
        stroke="#7897FF"
      />
    </svg>
  );
}

const Empty = React.memo(EmptyIcon);

export default Empty;
