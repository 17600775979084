import APP_TYPES from "../types/app";

const initState = {
  showLoading: false,
  notification: {
    title: "Opp!",
    showMessage: false,
    message: "",
  },
  currentLanguage: "en",
  eventMasterData: [],
};

const app = (state: any = initState, action: any) => {
  switch (action.type) {
    case APP_TYPES.SHOW_LOADING:
      return { ...state, showLoading: true };
    case APP_TYPES.HIDE_LOADING:
      return { ...state, showLoading: false };
    case APP_TYPES.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...initState?.notification,
          showMessage: true,
          message: action?.message,
        },
      };
    case APP_TYPES.HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...initState?.notification,
          showMessage: false,
          message: "",
        },
      };

    case APP_TYPES?.CHANGE_LANGUAGE:
      return { ...state, currentLanguage: action.payload };

    case APP_TYPES?.SAVE_EVENTS_MASTER_DATA: {
      return {
        ...state,
        eventMasterData: action?.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default app;
