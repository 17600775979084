import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

const ShipmentAtPort = (props: any) => {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);
  return (
    <svg width="28" height="28" viewBox="0 0 29 29" fill="none" {...props}>
      <rect x={1.801} y={1.004} width={26} height={26} rx={9} fill={backgroundColor} />
      <g clipPath="url(#prefix__clip0_16261_149993)">
        <path
          d="M22.594 16.627a.42.42 0 00-.34-.12l-3.76.416a.417.417 0 00-.248.709l1.18 1.18a6.641 6.641 0 01-4.618 1.859 6.64 6.64 0 01-4.628-1.867l1.172-1.172a.416.416 0 00-.248-.709l-3.759-.416a.417.417 0 00-.46.461l.425 3.75a.416.416 0 00.71.247l.982-.983a8.3 8.3 0 005.806 2.355 8.3 8.3 0 005.797-2.346l.974.974a.416.416 0 00.71-.247l.424-3.75a.417.417 0 00-.119-.341z"
          fill={iconColor}
        />
        <path
          d="M17.308 7.336c0-1.379-1.12-2.5-2.5-2.5a2.503 2.503 0 00-2.5 2.5c0 1.086.698 2.01 1.667 2.354l-.008 12.646a.834.834 0 101.666 0V9.69a2.509 2.509 0 001.675-2.354zm-2.5.833a.834.834 0 11.002-1.668.834.834 0 01-.002 1.668z"
          fill={iconColor}
        />
        <path
          d="M17.723 11.085h-5.834a.417.417 0 01-.416-.417v-.833c0-.23.186-.417.416-.417h5.834c.23 0 .416.187.416.417v.833c0 .23-.186.417-.416.417zM15.633 12.336v-1.25h-1.667v1.25h1.667z"
          fill={borderColor}
        />
      </g>
      <rect
        x={1.801}
        y={1.004}
        width={26}
        height={26}
        rx={9}
        stroke={borderColor}
        strokeWidth={2}
      />
      <defs>
        <clipPath id="prefix__clip0_16261_149993">
          <path fill="#fff" transform="translate(4.8 4.004)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShipmentAtPort;
