import * as React from "react";

function Clock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        d="M13.5 3a9 9 0 00-9 9h-3l3.89 3.89.07.14L9.5 12h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0013.5 21a9 9 0 000-18zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8h-1.5z"
        fill="#1F2937"
      />
    </svg>
  );
}

export default Clock;
