import { trimSpaces } from "scripts/helpers";
import "./Tag.scss";

interface TagProps {
  theme: TagThemeProps;
  size?: "extra-small" | "small" | "middle" | "large";
  leftIcon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  block?: boolean;
}
export type TagThemeProps =
  | "default"
  | "purple"
  | "yellow"
  | "green"
  | "blue"
  | "red"
  | "grey"
  | "disabled";

const Tag = ({
  theme = "default",
  size = "small",
  leftIcon,
  children,
  className = "",
  block = false,
}: TagProps) => {
  if (children)
    return (
      <div
        className={trimSpaces(
          `console-tag console-tag__theme-${theme} console-tag__size-${size} ${className} ${
            block ? "w-fit" : ""
          }`,
        )}
      >
        {leftIcon && <div className="console-tag__left-icon">{leftIcon}</div>}
        <div className="console-tag__content">{children}</div>
      </div>
    );
  else return <></>;
};

export default Tag;
