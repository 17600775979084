import { useNavigate } from "react-router-dom";
import useQueryData from "./useQueryData";

export const useNavigateCustom = () => {
  const navigate = useNavigate();
  return (href?: any, props?: any) => {
    const QUERY_DATA = useQueryData();
    const { orgId } = QUERY_DATA;
    let newHref;
    if (typeof href === "string") {
      newHref = orgId ? `${href}${href?.indexOf("?") > 0 ? "&" : "?"}orgId=${orgId}` : href;
    } else {
      newHref = href;
    }
    navigate(newHref, props);
  };
};

export default useNavigateCustom;
