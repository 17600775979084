import { compact } from "lodash";
import { useEffect, useState } from "react";
import ContainerListView from "./ContainerListView";

const ContainerListContainer = ({
  containers,
  loading = false,
  shipmentDetails,
  tabActive
}: {
  containers: any[];
  loading?: boolean;
  shipmentDetails?: any;
  tabActive?:string;
}) => {
  const [containerList, setContainerList]: any = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([] as any);

  useEffect(() => {
    setContainerList(containers);
    setCollapseActiveKeys(containers?.map(() => "uncollapse"));
  }, [containers]);

  const onSearchDO = (keyword: string) => {
    setIsSearchLoading(true);
    const containerResult: any = [];
    if (keyword !== "") {
      containers?.map((containerItem) => {
        const { deliveryOrderNumbers,packageDetails } = containerItem;
        const isExisted = deliveryOrderNumbers?.find((id: string) =>
          id?.toLowerCase()?.includes(keyword?.toLowerCase())
        );
        const isIncludedDes = packageDetails.find((it: any) =>
          it?.itemDescription?.toLowerCase()?.includes(keyword.toLowerCase())
        ); 
        if (isExisted !== undefined || isIncludedDes !== undefined) {
          containerResult.push({
            ...containerItem,
          });
        }
      });
      setTimeout(() => {
        setContainerList(containerResult);
        setCollapseActiveKeys(
          compact(containerResult?.map((e: any) => `panel-` + e?.containerNumber)),
        );
        setIsSearchLoading(false);
      }, 1000);
    } else {
      setContainerList(containers);
      setIsSearchLoading(false);
    }
  };

  return (
    <ContainerListView
      {...{
        collapseActiveKeys,
        setCollapseActiveKeys,
        containerList,
        loading,
        isSearchLoading,
        onSearchDO,
        shipmentDetails,
        tabActive
      }}
    />
  );
};

export default ContainerListContainer;
