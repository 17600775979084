/* eslint-disable @typescript-eslint/no-empty-function */
import axios, { AxiosRequestConfig, Method } from "axios";
import useQueryData from "hooks/useQueryData";
import { includes, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { ERROR_MESSAGES, HTTP_STATUS_CODES } from "scripts/constants";
import variables from "scripts/variables";
import i18next from "translations";

const baseApi = async (
  path = "",
  method: Method = "GET",
  body = {},
  headers = {},
  mockData = {},
) => {
  if (!isEmpty(mockData)) {
    return Promise.resolve({
      code: 200,
      data: mockData,
    });
  } else {
    const QUERY_DATA = useQueryData();
    const { orgId } = QUERY_DATA;
    let endpoint = `${variables.apiUrl}${path}`;
    let bodyData = body;
    if (orgId) {
      if (method === "GET") {
        endpoint = orgId
          ? `${endpoint}${endpoint?.indexOf("?") > 0 ? "&" : "?"}orgId=${orgId}`
          : endpoint;
      }

      if (includes(["POST", "PUT", "PATCH"], method)) {
        bodyData = orgId
          ? {
              ...bodyData,
              orgId,
            }
          : bodyData;
      }
    }

    const options: AxiosRequestConfig = {
      method,
      url: endpoint,
      headers,
      data: bodyData,
    };
    try {
      const res: any = await axios(options);
      if (!res) return Promise.reject();
      const { data: response } = res;
      if (includes([HTTP_STATUS_CODES.SUCCESS, HTTP_STATUS_CODES.CREATED], response?.code)) {
        return Promise.resolve({ data: response?.data || {} });
      } else {
        toast.error(ERROR_MESSAGES(i18next)?.GENERAL_ERROR, {});
        toast.clearWaitingQueue();
        return Promise.reject({
          code: response?.code || 9999,
          errorMessage: response?.message || ERROR_MESSAGES(i18next)?.GENERAL_ERROR,
        });
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.data ||
          ERROR_MESSAGES(i18next)?.GENERAL_ERROR,
      );
      toast.clearWaitingQueue();
      return Promise.reject({
        code: (error as any)?.response?.status || 9999,
        errorMessage:
          (error as any)?.response?.data?.message ||
          (error as any)?.response?.data?.data ||
          ERROR_MESSAGES(i18next)?.GENERAL_ERROR,
      });
    }
  }
};

export default baseApi;
