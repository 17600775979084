import { Dropdown, Menu } from "antd";
import USAFlag from "components/svgs/UsaFlag";
// import VietNamFlag from "icons/VietNamFlag";
import VietNamFlag from "components/svgs/VietNamFlag";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRedux } from "scripts/helpers";
import { changeLanguage } from "store/actions/app";
import "./LanguageDropdown.scss";
import useQueryData from "hooks/useQueryData";
import useOnChangeFilter from "hooks/useOnChangeFilter";
import i18next from "i18next";

const LanguageDropdown = () => {

  const LANGUAGE_NAME = {
    en: i18next.t("English"),
    vi: i18next.t("Vietnamese"),
  };

  const DropdownData = () => [
    {
      name: LANGUAGE_NAME['en'],
      icon: <USAFlag />,
      code: "en",
    },
    {
      name: LANGUAGE_NAME['vi'],
      icon: <VietNamFlag />,
      code: "vi",
    },
  ];



  const dispatch = useDispatch();
  const QUERY_DATA = useQueryData();
  const onChangeFilter = useOnChangeFilter();
  const language: any = getRedux(`app.currentLanguage`, "en");

  const [languageSelected, setLanguageSelected]: any = useState(DropdownData()[0]);

  useEffect(() => {
    const selected = DropdownData().find((it: any) => it.code === language);
    setLanguageSelected(selected);
    onChangeFilter({
      ...QUERY_DATA,
      lang: language,
    });
  }, [language]);

  console.log("languageSelected", languageSelected);

  return (
    <div className="language-dropdown-wrapper">
      <Dropdown
        overlayClassName="rounded sm-language-dropdown"
        className="hover:cursor-pointer"
        trigger={["click"]}
        overlay={
          <Menu>
            {DropdownData().map((item: any, index: number) => (
              <Menu.Item
                key={index}
                onClick={() => {
                  dispatch(changeLanguage(item?.code));
                  localStorage.setItem("currentLanguage", item?.code);
                }}
              >
                {item.name}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div className="rounded border-gray200 hover:border-blue200 hover:text-blue500 hover:bg-blue50 border-[1px] border-solid flex flex-row h-[32px] items-center">
          <div className="py-[6px] px-[16px] sm_body_b2_reg">
            {LANGUAGE_NAME[languageSelected?.code]}
          </div>
          <div className="bg-gray200 w-[1px] h-[30px]" />
          <div className=" px-[8px] flex">{languageSelected?.icon}</div>
        </div>
      </Dropdown>
    </div>
  );
};

export default LanguageDropdown;
