import { Col, Row } from "antd";
import Image from "components/Image";
import { useTranslation } from "react-i18next";
import { DEFAULT_VALUES } from "scripts/constants";

const CarrierInformation = ({ shipmentItemData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="sm-head pt-0">
      <div className="sm-head-content">
        <Row gutter={[15, 15]}>
          <Col {...{ xs: 8 }}>
            <div className="sm-head-item">
              <div className="sm-head-item-label">{t("Carrier")}</div>
              <div className="sm-head-item-value block">
                <Image
                  type="favicon"
                  className="logo"
                  src={shipmentItemData?.carrier?.logo}
                  width={16}
                />
                <span>
                  <span className="ml-1">
                    {shipmentItemData?.carrier?.name ?? DEFAULT_VALUES?.noData}
                  </span>
                </span>
              </div>
            </div>
          </Col>
          <Col {...{ xs: 8 }}>
            <div className="sm-head-item">
              <div className="sm-head-item-label">
                <span className="mr-1">{t("Origin")}</span>
              </div>
              <div className="sm-head-item-value">
                {shipmentItemData?.origin?.location ?? DEFAULT_VALUES?.noData}
              </div>
            </div>
          </Col>
          <Col {...{ xs: 8 }}>
            <div className="sm-head-item">
              <div className="sm-head-item-label">
                <span className="mr-1">{t("Destination")}</span>
              </div>
              <div className="sm-head-item-value">
                {shipmentItemData?.destination?.location ?? DEFAULT_VALUES?.noData}
              </div>
            </div>
          </Col>

          {/* <Col {...{ xs: 4 }}>
            <div className="sm-head-item">
              <div className="sm-head-item-label">{t("Carrier tracking #")}</div>
              <div className="sm-head-item-value">
                {shipmentItemData?.carrierTrackingNumber}
                <CopyButton
                  className="ml-2"
                  message={shipmentItemData?.carrierTrackingNumber || ``}
                />
              </div>
            </div>
          </Col>
          {shipmentItemData?.committedDate && (
            <Col {...{ xs: 4 }}>
              <div className="sm-head-item">
                <div className="sm-head-item-label">{t("PO Committed Date")}</div>
                <div className="sm-head-item-value">
                  {shipmentItemData?.committedDate
                    ? moment.unix(shipmentItemData?.committedDate)?.format(DATE_FORMAT)
                    : ""}
                </div>
              </div>
            </Col>
          )}
          {newEstimateDeliveredDateTime !== 0 && (
            <Col {...{ xs: 4 }}>
              <div className="sm-head-item">
                <div className="sm-head-item-label">{t("NewEst.DeliveryDate")}</div>
                <div className="sm-head-item-value" style={{ color: "#E65C5C" }}>
                  {newEstimateDeliveredDateTime
                    ? moment.unix(newEstimateDeliveredDateTime)?.format(DATE_FORMAT)
                    : ""}
                </div>
              </div>
            </Col>
          )} */}
        </Row>
      </div>
    </div>
  );
};

export default CarrierInformation;
