import { notification } from "antd";
import Copy from "components/svgs/Copy";
import { Button } from "console-system-components";
import { useTranslation } from "react-i18next";

const CopyButton = ({ className = "", message = "" }: any) => {
  const { t } = useTranslation();
  const onCopyButtonClick = (ev: any) => {
    ev.stopPropagation();
    navigator?.clipboard?.writeText(message);
    notification?.success({
      message: t("Copied to clipboard"),
    });
  };

  return (
    <Button
      square
      className={`console-copy-button ${className}`}
      type="default"
      theme="standard"
      size="small"
      onClick={onCopyButtonClick}
    >
      <Copy />
    </Button>
  );
};

export default CopyButton;
