import { Tooltip } from "antd";
import Empty from "components/Empty";
import EstimateDateTimeModal from "components/EstimateDateTimeModal";
import SpinLoading from "components/Loading/SpinLoding";
import WrapperSection from "components/WrapperSection";
import Clock from "components/svgs/Clock";
import Info from "components/svgs/Info";
import Marker from "components/svgs/Marker";
import Warning from "components/svgs/Warning";
import { EventMasterData, ExceptionProps, IssueProps } from "interface/general";
import { ShipmentDetailsProps, ShipmentEventProps } from "interface/shipment";
import { cloneDeep, findLast, isEmpty, last, orderBy } from "lodash";
import { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  DATA_EXCEPTION_MESSAGE_CONTAINER,
  SHIPMENT_DATA_EXCEPTION_MESSAGES,
  SHIPMENT_STATUS,
  SHIPMENT_STATUSES,
  STATUS_EXCEPTIONS_DESCRIPTION,
} from "scripts/constants";
import { formatLastUpdatedTime, getRedux, renderActivitiesEventIcon } from "scripts/helpers";
import "./ActivitiesList.scss";
import ShipmentStep from "./ShipmentStep";

interface ActivitiesListViewProps {
  shipmentDetails: ShipmentDetailsProps;
  isOpenActivities?: boolean;
  tabActive?: string;
  isLoading?: boolean;
  eventMasterData: EventMasterData[];
}

export interface Activities {
  status: string;
  minDate: number;
  events: ShipmentEventActivityProps[];
  dataExceptions?: ExceptionProps[];
}

interface ShipmentEventActivityProps extends ShipmentEventProps {
  passed?: boolean;
  code?: string;
  dateTime?: number;
  isFirstActivity?: boolean;
  exception?: ExceptionProps;
}

const ActivitiesListView: FC<ActivitiesListViewProps> = ({
  shipmentDetails,
  isOpenActivities,
  isLoading,
  eventMasterData,
}) => {
  const { t } = useTranslation();
  const { currentLanguage: language = "en" }: any = getRedux(`app`, {});

  const {
    events,
    isDeliveredOnTime,
    isReceivedOnTime,
    estimatedDateTimeList,
    B6Events,
    C1Events,
    containers,
  }: any = shipmentDetails;

  const [dataList, setDataList] = useState<Activities[]>([]);
  const [eventCodeActive, setEventCodeActive] = useState<"X2" | "B6" | "C1" | null>(null);

  const lastB6Step = last(
    orderBy(
      events?.filter((it: ShipmentEventProps) => it?.code === "B6"),
      ["index", "asc"],
    ),
  );
  const lastC1Step = last(
    orderBy(
      events?.filter((it: ShipmentEventProps) => it?.code === "C1"),
      ["index", "asc"],
    ),
  );

  const maxLengthOfCode =
    events?.reduce((max: number, it: ShipmentEventProps) => {
      if (it?.code && it?.code?.length > max) {
        max = it?.code?.length;
      }
      return max;
    }, 0) ?? 0;

  const lastEstimate = last(
    orderBy(
      events?.filter((it: ShipmentEventProps) => it?.code === "X2"),
      ["index", "asc"],
    ),
  );

  const isGroupByLocation = false;
  const initialActivitiesGroup: Activities[] = [
    {
      status: SHIPMENT_STATUS.RECEIVED,
      minDate: 0,
      events: [],
      dataExceptions: [],
    },
    {
      status: SHIPMENT_STATUS.DELIVERED,
      minDate: 0,
      events: [],
      dataExceptions: [],
    },
    {
      status: SHIPMENT_STATUS.INTRANSIT,
      minDate: 0,
      events: [],
      dataExceptions: [],
    },
    {
      status: SHIPMENT_STATUS.BOOKED,
      minDate: 0,
      events: [],
      dataExceptions: [],
    },
    // {
    //   status: SHIPMENT_STATUS.CREATED,
    //   minDate: 0,
    //   events: [],
    // },
  ];

  const transformActivitiesGroup = () => {
    //transform datTime base on eventDateTime for comparison
    let eventList: ShipmentEventActivityProps[] = cloneDeep(events) ?? [];
    const initialActivitiesGroupInstance = cloneDeep(initialActivitiesGroup);

    eventList = eventList?.map((el) => {
      if (el?.eventDateTime?.date) {
        el.dateTime = el?.eventDateTime?.date + el?.eventDateTime?.time;
      }
      return el;
    });
    //Push event to correct group by status and set minDate for that group by earliest event
    initialActivitiesGroupInstance?.map((el) => {
      eventList?.map((it) => {
        if (it?.status?.code === el?.status) {
          if (el?.events?.length === 0) el.minDate = it?.dateTime as number;
          el?.events?.push(it);
          if (it?.dateTime && it?.dateTime < el?.minDate) {
            el.minDate = it?.dateTime;
          }
          // Remove this event from initial eventList
          eventList = eventList?.filter((item) => JSON.stringify(item) !== JSON.stringify(it));
        }
      });
      const dataExceptionByCode = shipmentDetails?.dataExceptions?.filter(
        (exception: ExceptionProps) => exception?.status === el?.status,
      );
      if (!isEmpty(dataExceptionByCode)) {
        el.dataExceptions = dataExceptionByCode;
      }
      return el;
    });

    // Push other which is not belong to 4 main status (CREATED, BOOKED, IN-TRANSIT, DELIVERED) to correct section by dateTime
    initialActivitiesGroupInstance?.map((el) => {
      if (!isEmpty(el?.events)) {
        eventList?.map((it) => {
          if (it.dateTime && it.dateTime > el?.minDate) {
            if (it?.status?.code === SHIPMENT_STATUS.ESTIMATE && it?.code === "X2") {
              if (it?.index === lastEstimate?.index) {
                el?.events.push(it);
              }
            } else if (it?.code === "B6") {
              if (it?.index === lastB6Step?.index) el?.events.push(it);
            } else if (it?.code === "C1") {
              if (it?.index === lastC1Step?.index) el?.events.push(it);
            } else el?.events.push(it);
            // Remove this event from eventList
            eventList = eventList?.filter((item) => JSON.stringify(item) !== JSON.stringify(it));
          }
        });
      }
      return el;
    });

    if (!isEmpty(eventList)) {
      initialActivitiesGroupInstance?.map((el) => {
        if (el?.status === SHIPMENT_STATUS.BOOKED) {
          eventList?.forEach((it) => {
            if (it?.status?.code === SHIPMENT_STATUS.ESTIMATE && it?.code === "X2") {
              if (it?.index === lastEstimate?.index) {
                el?.events.push(it);
              }
            } else if (it?.code === "B6") {
              if (it?.index === lastB6Step?.index) el?.events.push(it);
            } else if (it?.code === "C1") {
              if (it?.index === lastC1Step?.index) el?.events.push(it);
            } else {
              el?.events.push(it);
            }
          });
          return el;
        }
      });
    }

    // Sort by dateTime for each section
    initialActivitiesGroupInstance?.map((el) =>
      el?.events.sort((a, b) => {
        const x = a["dateTime"];
        const y = b["dateTime"];
        if (x && y) return x > y ? -1 : x < y ? 1 : 0;
        return 1;
      }),
    );

    // Highlight the last event to break section by status
    initialActivitiesGroupInstance?.map((el) =>
      el?.events?.map((it, index: number) => {
        if (index === 0) {
          it.isFirstActivity = true;
          it.exception = findLast(
            shipmentDetails?.statusExceptions,
            (o) => o?.code === it?.status?.code,
          );
          if (it?.status?.code === SHIPMENT_STATUS.ESTIMATE) {
            it.status.code = el?.status;
          }
          if (!it?.status) {
            it.status = { code: el?.status };
          }
        } else it.isFirstActivity = false;
        return it;
      }),
    );
    const activitiesGroup = initialActivitiesGroupInstance?.filter(
      (el) => !isEmpty(el?.events) || !isEmpty(el?.dataExceptions),
    );

    const dataListGroupLocation = (
      isGroupByLocation
        ? activitiesGroup?.map((it: Activities) => ({
            ...it,
            events: it?.events
              ?.reduce((acc: any, el: any) => {
                if (
                  acc?.length &&
                  acc?.[acc?.length - 1]?.[0]?.location?.location === el?.location.location
                ) {
                  acc?.[acc?.length - 1].push(el);
                } else {
                  acc.push([el]);
                }
                return acc;
              }, [])
              ?.map((event: any) => ({
                location: event?.[0]?.location?.location,
                eventsByLocation: event,
              })),
          }))
        : activitiesGroup
    )?.map((item: any) => {
      if ([SHIPMENT_STATUS.DELIVERED, SHIPMENT_STATUS.RECEIVED]?.includes(item?.status)) {
        item.status =
          item?.status === SHIPMENT_STATUS.DELIVERED && isDeliveredOnTime === false
            ? SHIPMENT_STATUS.DELIVERED_LATE
            : item?.status === SHIPMENT_STATUS.RECEIVED && isReceivedOnTime === false
            ? SHIPMENT_STATUS.RECEIVED_LATE
            : item?.status;
      }

      return item;
    });

    return dataListGroupLocation;
  };

  useEffect(() => {
    if (!isEmpty(shipmentDetails)) {
      const dataTransformed = transformActivitiesGroup();
      setDataList(dataTransformed);
    }
  }, [isOpenActivities, shipmentDetails, isGroupByLocation]);

  const eventDate = +(shipmentDetails?.lastShipmentEventReceived?.date || 0);
  const eventTime = +(shipmentDetails?.lastShipmentEventReceived?.time || 0);

  const onShowMoreInfo = (eventCode: any) => {
    setEventCodeActive(eventCode);
  };

  const exceptionsCpn = (exception: any) => {
    return (
      <Tooltip
        overlayClassName="sm-tooltip-exception min-w-[236px]"
        autoAdjustOverflow={true}
        placement="top"
        title={
          <>
            <div className="sm_body_b1_semi text-white p-2 bg-gray600 rounded-t-[4px]">
              {t("Data Exception(s)")}
            </div>
            <div className="exception-content__wrapper px-2 py-3 bg-[#00000066] flex flex-col gap-2 rounded-b-[4px] overflow-auto max-h-[250px]">
              {exception?.map((it: ExceptionProps) => {
                const msg =
                  containers?.length > 1
                    ? `${SHIPMENT_DATA_EXCEPTION_MESSAGES(t)?.[it.exception ?? ""]} ${
                        it?.containerNumber ?? "-"
                      }`
                    : DATA_EXCEPTION_MESSAGE_CONTAINER(t)?.[it.exception ?? ""];

                return (
                  <div
                    className="flex items-center space-x-4 mt-4 first:mt-0"
                    key={JSON.stringify(it)}
                  >
                    {it?.eventCode && (
                      <div className="bg-gray500 rounded px-2 text-[#FFFFFF] sm_body_b2_semi">
                        {it?.eventCode}
                      </div>
                    )}
                    <div>
                      <div className="sm_body_b3_reg mb-1 text-white">{msg}</div>
                      <div className="sm_body_b3_reg">{msg}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        }
      >
        <Warning className="align-middle ml-2" />
      </Tooltip>
    );
  };

  const getEstimateTimeList = () => {
    switch (eventCodeActive) {
      case "X2":
        return estimatedDateTimeList;
      case "B6":
        return B6Events;
      case "C1":
        return C1Events;
      default:
        return [];
    }
  };

  return (
    <WrapperSection type="shadow" className="sm-activities-section mt-0">
      <div className="flex items-center justify-between mb-4 w-full">
        <div className="flex justify-start items-center space-x-2">
          <Clock />
          <div className="sm_sub_title_semi text-gray800">{t("Activities")}</div>

          {!isLoading && eventDate > 0 && (
            <div className="sm_body_b2_reg text-gray500">
              <Trans
                defaults="Last updated {{time}}"
                values={{
                  time: formatLastUpdatedTime(eventDate + eventTime),
                }}
              />
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <SpinLoading />
      ) : (
        <div className="sm-activities-wrapper">
          {isEmpty(dataList) ? (
            <Empty />
          ) : (
            <div className="activities-section mt-4">
              {dataList?.map((activity: Activities, index: number) => {
                const issue = findLast(
                  shipmentDetails?.statusExceptions,
                  (it: IssueProps) => it?.status === activity?.status,
                );
                const isHaveIssue =
                  [
                    SHIPMENT_STATUS.DELIVERED_LATE as string,
                    SHIPMENT_STATUS.RECEIVED_LATE as string,
                  ]?.includes(activity?.status) || !isEmpty(issue);

                return (
                  <div key={index}>
                    <div
                      className={`relative bg-secondary500  rounded-lg h-7 w-full px-2 py-1 flex items-center sm_body_b2_semi text-primaryText500 mb-4 z-10`}
                    >
                      {index === dataList?.length - 1 && isEmpty(activity?.events) ? null : (
                        <div
                          className={`bg-gray200 absolute top-7 left-[14px] w-[1px] h-4  z-[1]`}
                        />
                      )}
                      <span className="mb-1 sm-group-status-icon">
                        {renderActivitiesEventIcon(
                          activity?.status,
                          isHaveIssue ? "failure" : "success",
                        )}
                      </span>
                      <span className={`text-secondaryText500 ml-2`}>
                        {SHIPMENT_STATUSES()?.[`${activity?.status}`]?.label}
                      </span>
                      {isHaveIssue &&
                        ![
                          SHIPMENT_STATUS.DELIVERED_LATE as string,
                          SHIPMENT_STATUS.RECEIVED_LATE as string,
                        ]?.includes(activity?.status) && (
                          <Tooltip
                            placement="top"
                            overlayClassName={" max-w-[300px]"}
                            defaultVisible={false}
                            title={
                              <div className="flex flex-col">
                                <div className="pb-2 last:pb-0">
                                  {
                                    STATUS_EXCEPTIONS_DESCRIPTION(t)[
                                      issue?.subCode ? issue?.subCode : (issue?.code as string)
                                    ]
                                  }
                                </div>
                              </div>
                            }
                          >
                            <Info className="text-red500 hover:text-red400 w-[16px] h-[16px] ml-2" />
                          </Tooltip>
                        )}
                      {!isEmpty(activity?.dataExceptions) &&
                        exceptionsCpn(activity?.dataExceptions)}
                    </div>

                    {isGroupByLocation ? (
                      activity?.events?.map((event: any, i: number) => {
                        return (
                          <div className="pl-[9px] relative">
                            {index === dataList?.length - 1 &&
                            i === activity?.events?.length - 1 ? null : (
                              <div
                                className={`tail-location bg-gray200 absolute top-7 left-4 w-[1px] z-[1]`}
                              />
                            )}
                            <div className="flex">
                              <span className="mb-1 h-fit sm-group-status-icon z-10 bg-white">
                                <Marker />
                              </span>
                              <span className={`text-gray800 sm_body_b2_semi ml-4`}>
                                {event?.location || t("Unknown Location")}
                              </span>
                            </div>
                            <div className="pt-6 pl-8">
                              <ShipmentStep
                                dataList={event?.eventsByLocation}
                                isGroupByLocation={isGroupByLocation}
                                maxLengthOfCode={maxLengthOfCode}
                                {...{
                                  X2list: estimatedDateTimeList,
                                  onShowMoreInfo,
                                  B6Events,
                                  C1Events,
                                  language,
                                  eventMasterData,
                                }}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <ShipmentStep
                        dataList={activity?.events}
                        isLastGroup={index === dataList?.length - 1}
                        isGroupByLocation={isGroupByLocation}
                        maxLengthOfCode={maxLengthOfCode}
                        {...{
                          X2list: estimatedDateTimeList,
                          onShowMoreInfo,
                          B6Events,
                          C1Events,
                          language,
                          eventMasterData,
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <EstimateDateTimeModal
        isDetail={true}
        code={eventCodeActive}
        visible={eventCodeActive !== null}
        setVisible={() => setEventCodeActive(null)}
        data={getEstimateTimeList()}
      />
    </WrapperSection>
  );
};

export default ActivitiesListView;
