import * as React from "react";

function Warning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1733 13.0191L9.17532 1.71706C9.06289 1.50253 8.89391 1.32286 8.68667 1.19749C8.47944 1.07213 8.24185 1.00586 7.99965 1.00586C7.75745 1.00586 7.51986 1.07213 7.31263 1.19749C7.10539 1.32286 6.93641 1.50253 6.82398 1.71706L0.825983 13.0191C0.715156 13.2261 0.660191 13.4585 0.666504 13.6933C0.672817 13.9281 0.740189 14.1572 0.861983 14.3581C0.979294 14.5558 1.14614 14.7196 1.34606 14.8331C1.54598 14.9467 1.77205 15.0062 2.00198 15.0057H13.9973C14.2272 15.0062 14.4533 14.9467 14.6532 14.8331C14.8532 14.7196 15.02 14.5558 15.1373 14.3581C15.2591 14.1572 15.3265 13.9281 15.3328 13.6933C15.3391 13.4585 15.2841 13.2261 15.1733 13.0191Z"
        fill="#FEC400"
      />
      <path d="M8.762 5.33984L8.381 10.8635H7.619L7.238 5.33984H8.762Z" fill="#1F2937" />
      <path
        d="M8 13.6732C8.55228 13.6732 9 13.2255 9 12.6732C9 12.1209 8.55228 11.6732 8 11.6732C7.44772 11.6732 7 12.1209 7 12.6732C7 13.2255 7.44772 13.6732 8 13.6732Z"
        fill="#1F2937"
      />
    </svg>
  );
}

export default Warning;
