import LogoPS from "components/svgs/LogoPS";
import { Modal } from "console-system-components";
import { useTranslation } from "react-i18next";
import { convertToDisplay, getEventDateTime, getVariance } from "scripts/helpers";

const DeliveredNodeModal = ({
  isOpenDeliveredEventModal,
  datePopupDelivered,
  setIsOpenDeliveredEventModal,
  pascalEstimatedDeliveryDate,
  firstEstimatedDateTime,
  lastEstimatedDateTime,
  estimatedDateTimeList,
}: any) => {
  const { t } = useTranslation();

  const items = [
    {
      left: {
        label: t("Delivered"),
        value: getEventDateTime(datePopupDelivered)?.datetime ?? "-",
      },
    },
    {
      left: {
        icon: <LogoPS className="w-6 h-6" />,
        label: t("Pascal Estimated Delivered"),
        value: getEventDateTime(pascalEstimatedDeliveryDate)?.datetime ?? "-",
      },
      right: {
        label: t("Variance"),
        value: getVariance(datePopupDelivered, pascalEstimatedDeliveryDate),
      },
    },
    {
      left: {
        label: t("1st Estimated Delivered"),
        value: getEventDateTime(firstEstimatedDateTime)?.datetime ?? "-",
      },
      right: {
        label: t("Variance"),
        value: getVariance(datePopupDelivered, firstEstimatedDateTime),
      },
    },
    {
      left: {
        label: t("Last Estimated Delivered"),
        value: getEventDateTime(lastEstimatedDateTime)?.datetime ?? "-",
      },
      right: {
        label: t("Variance"),
        value: getVariance(datePopupDelivered, lastEstimatedDateTime),
      },
    },
    {
      left: {
        label: t("Number of Estimates"),
        value: convertToDisplay(estimatedDateTimeList?.length),
      },
    },
  ];

  return (
    <Modal
      visible={isOpenDeliveredEventModal}
      title={t("Estimation Accuracy")}
      className="delivered-estimation-accuracy-modal"
      onCancel={() => setIsOpenDeliveredEventModal(false)}
      centered
      customFooter={null}
    >
      <div className="delivered-estimation-accuracy-modal__wrapper">
        {items?.map((item: any, index: number) => {
          return <DeliveredEstimationAccuracyRow key={index} data={item} />;
        })}
      </div>
    </Modal>
  );
};

export const DeliveredEstimationAccuracyRow = ({ data = {} }: any) => {
  return (
    <div className="delivered-estimation-accuracy-modal__row delivered-estimation-accuracy__row mb-6 last:mb-0 flex justify-between">
      <div className="delivered-estimation-accuracy__row-left flex-[3]">
        <DeliveredEstimationAccuracyItem {...(data?.left ?? {})} />
      </div>
      <div className="delivered-estimation-accuracy__row-right flex-1">
        <DeliveredEstimationAccuracyItem {...(data?.right ?? {})} />
      </div>
    </div>
  );
};

export const DeliveredEstimationAccuracyItem = ({ icon = null, label, value }: any) => {
  return (
    <div className="delivered-estimation-accuracy__item">
      <div className="delivered-estimation-accuracy__item-label flex items-center justify-start gap-2">
        {icon}
        <span className="sm_body_b1_reg text-gray500">{label}</span>
      </div>
      <div className="delivered-estimation-accuracy__item-value sm_body_b1_semi text-gray800">
        {value}
      </div>
    </div>
  );
};

export default DeliveredNodeModal;
