import * as React from "react";

function Download(props: any) {
  return (
    <svg width={17} height={16} fill="none" {...props}>
      <path
        d="M8.387 10.657a.142.142 0 00.225 0l2-2.53a.142.142 0 00-.112-.23H9.177V1.854a.143.143 0 00-.143-.143H7.962a.143.143 0 00-.143.143v6.04H6.5a.142.142 0 00-.112.231l2 2.532zm6.649-.625h-1.072a.143.143 0 00-.143.143v2.75H3.178v-2.75a.143.143 0 00-.142-.143H1.964a.143.143 0 00-.143.143v3.536a.57.57 0 00.572.571h12.214a.57.57 0 00.571-.571v-3.536a.143.143 0 00-.142-.143z"
        fill="#1F2937"
      />
    </svg>
  );
}

export default Download;
