import Tag from "components/Tag";
import { ExceptionProps } from "interface/general";
import { ShipmentDetailsProps } from "interface/shipment";
import { useTranslation } from "react-i18next";
import { SHIPMENT_STATUS } from "scripts/constants";
import {
  getGeneralShipmentStatus,
  getShipmentStatusUI,
  getStatusAndDeliveryType,
} from "scripts/helpers";
const GeneralInformationHeader = ({
  shipmentItemData,
}: {
  shipmentItemData: ShipmentDetailsProps;
}) => {
  const { t } = useTranslation();

  const { statusExceptions } = shipmentItemData ?? {};

  const { status: shipmentStatus, deliveryType } = getStatusAndDeliveryType(
    shipmentItemData as ShipmentDetailsProps,
  );

  const issue: ExceptionProps | undefined =
    shipmentItemData?.status?.code !== SHIPMENT_STATUS.DELIVERED
      ? statusExceptions?.[statusExceptions?.length - 1]
      : undefined;

  const status = getGeneralShipmentStatus(shipmentStatus, deliveryType);
  const shipmentAttributesByCode = getShipmentStatusUI(status?.code);
  console.log("shipmentAttributesByCode ===> ", shipmentAttributesByCode);
  const issueAttributesByCode = getShipmentStatusUI(issue?.code);

  return (
    <div className="flex flex-row justify-between">
      <div className="sm_sub_title_semi text-gray800">{t("General Information")}</div>
      <div className="inline pb-8">
        {shipmentAttributesByCode?.label && shipmentAttributesByCode?.color && (
          <Tag
            className="mr-1 bg-secondary100 text-secondary900 border-none"
            theme={shipmentAttributesByCode?.color}
          >
            {shipmentAttributesByCode?.label}
          </Tag>
        )}

        {issue && issueAttributesByCode?.label && (
          <Tag className="ml-2" theme={issueAttributesByCode?.color}>
            {issueAttributesByCode?.label}
          </Tag>
        )}
        {/* {locationData?.location && (
          <Tag theme="grey" className="ml-2 mr-0">
            <span className="sm_body_b2_semi">{locationData?.location}</span>
            {locationData?.dstOffsetStr && (
              <span className="sm_body_b2_semi ml-1">{`(${locationData?.dstOffsetStr})`}</span>
            )}
          </Tag>
        )} */}
      </div>
    </div>
  );
};

export default GeneralInformationHeader;
