import { compact, some } from "lodash";
import { useEffect, useState } from "react";
import { renderHighlight } from "scripts/helpers";
import ContainerListView from "./ContainerListView";

const ContainerListContainer = ({
  containers,
  loading = false,
  tabActive,
  shipmentDetails,
}: {
  containers: any[];
  loading?: boolean;
  tabActive?: string;
  shipmentDetails?: any;
}) => {
  const [containerList, setContainerList]: any = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([] as any);

  useEffect(() => {
    setContainerList(containers);
    setCollapseActiveKeys(containers?.map(() => "uncollapse"));
  }, [containers]);

  const onSearchProduct = (keyword: string) => {
    setIsSearchLoading(true);
    const containerResult: any = [];
    if (keyword !== "") {
      containers?.map((containerItem) => {
        const products = containerItem?.products;
        const isExist = some(products, (e) => e?.sku.includes(keyword));
        if (isExist) {
          containerResult.push({
            ...containerItem,
            products: products?.map((productItem: any) => {
              return {
                ...productItem,
                sku: renderHighlight(productItem?.sku, keyword),
              };
            }),
          });
        }
      });
      setTimeout(() => {
        setContainerList(containerResult);
        setCollapseActiveKeys(
          compact(containerResult?.map((e: any) => `panel-` + e?.containerNumber)),
        );
        setIsSearchLoading(false);
      }, 1000);
    } else {
      setContainerList(containers);
      setIsSearchLoading(false);
    }
  };

  return (
    <ContainerListView
      {...{
        collapseActiveKeys,
        setCollapseActiveKeys,
        containerList,
        loading,
        isSearchLoading,
        onSearchProduct,
        tabActive,
        shipmentDetails,
      }}
    />
  );
};

export default ContainerListContainer;
