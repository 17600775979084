import queryString from "query-string";
import { SEPARATE_ARR_CHARACTER } from "scripts/constants";

export const useQueryData = () => {  
  return queryString?.parse(location?.search, {
    arrayFormat: "bracket-separator",
    arrayFormatSeparator: SEPARATE_ARR_CHARACTER,
  }) as any | null;
};

export default useQueryData;
