import { NotificationOutlined, ShareAltOutlined } from "@ant-design/icons";
import SwitchButton from "components/SwitchButton";
import WrapperSection from "components/WrapperSection";
import { Button } from "console-system-components";
import useOnChangeFilter from "hooks/useOnChangeFilter";
import useQueryData from "hooks/useQueryData";
import { ShipmentDetailsProps } from "interface/shipment";
import { upperFirst } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ShareOptionModal from "../ShareOptionsModal/ShareOptionModal";

const ShipmentDetailsHeader = ({
  shipmentItemData,
  isLoading = false,
}: {
  shipmentItemData?: ShipmentDetailsProps;
  isLoading?: boolean;
  onChangeStatus?: (id: string, status: boolean) => void;
  isLoadingFollow?: boolean;
  handleShare?: () => void;
}) => {
  const onChangeFilter = useOnChangeFilter();
  const QUERY_DATA = useQueryData();
  const { t } = useTranslation();

  const { tab = `overview` } = QUERY_DATA;
  const tabItems = ["overview", "freight", "activities"];

  const [isOpenShare, setIsOpenShare] = useState(false);

  return (
    <WrapperSection
      type="shadow"
      className="sm-details-header mb-[24px] mt-0 flex flex-col tablet:flex-row items-start tablet:items-center gap-[8px]"
    >
      <div className="sm-details-header-left">
        <div className="sm-details-header-left-info block">
          {isLoading ? (
            <div className="sm_title_t1_semi">{t("Loading...")}</div>
          ) : (
            <h1 className="m-0">{shipmentItemData?.shipmentTrackingId}</h1>
          )}
        </div>
      </div>
      <div className="sm-details-header-right">
        <Button
          disabled={isLoading}
          onClick={() => setIsOpenShare(true)}
          className="bg-primary500 text-white hover:bg-primary600 border-none ml-0 tablet:ml-[8px]"
          icon={<ShareAltOutlined rev={undefined} />}
        >
          {t("Share")}
        </Button>
        <Button
          className="hidden bg-primary500 text-white hover:bg-primary600 border-none"
          type="primary"
          icon={<NotificationOutlined rev={undefined} />}
        >
          {t("Subscribe for Updates")}
        </Button>
      </div>
      <div className="flex w-full mt-[10px] tablet:hidden">
        <SwitchButton
          className="grid w-full grid-cols-3"
          active={tab}
          items={tabItems?.map((tabItem: any) => {
            return {
              label: upperFirst(tabItem),
              value: tabItem,
              className: `justify-center`,
              onClick: () =>
                onChangeFilter({
                  ...QUERY_DATA,
                  tab: tabItem,
                }),
            };
          })}
        />
      </div>
      <ShareOptionModal
        {...{ isOpenShare, setIsOpenShare, billOfLading: shipmentItemData?.billOfLadingNumber }}
      />
    </WrapperSection>
  );
};

export default ShipmentDetailsHeader;
