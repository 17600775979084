import { updateFollowStatus } from "apis/shipments";
import { memo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { emptyFunction } from "scripts/helpers";
import ShipmentDetailsHeader from "./ShipmentDetailsHeader";

const ShipmentDetailsHeaderContainer = ({
  isLoadingShipmentDetails,
  shipmentDetails,
  handleShare = emptyFunction,
}: any) => {
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);

  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateFollowStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(`shipment-${shipmentDetails?.shipmentId}`);
      setIsLoadingFollow(false);
    },
  });

  const onChangeFollowStatus = (shipmentTrackingId: string, status: boolean) => {
    setIsLoadingFollow(true);
    mutate({
      shipmentTrackingId,
      isFollowed: status,
    });
  };

  return (
    <ShipmentDetailsHeader
      isLoading={isLoadingShipmentDetails}
      isLoadingFollow={isLoadingFollow}
      shipmentItemData={shipmentDetails}
      onChangeStatus={onChangeFollowStatus}
      handleShare={handleShare}
    />
  );
};

export default memo(ShipmentDetailsHeaderContainer);
