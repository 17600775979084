import EmptyIcon from "components/svgs/Empty";
import { useTranslation } from "react-i18next";
const Empty = ({message = "No Data"} : {message?:string}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col p-6 items-center">
      <EmptyIcon className="mb-2" />
      <span className='sm_body_b2_reg text-gray400'>{t(message)}</span>
    </div>
  );
};
export default Empty;
