const PascalLogo = () => (
  <svg width="155" height="32" viewBox="0 0 155 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M154.073 26.94C153.693 26.29 153.043 25.92 152.293 25.92H146.103C145.483 25.92 145.063 25.8 144.903 25.59C144.813 25.46 144.513 24.92 144.513 23.33V2.94996C144.513 2.16996 144.113 1.49996 143.423 1.10996C142.953 0.839961 142.343 0.709961 141.583 0.709961C140.823 0.709961 140.223 0.839961 139.743 1.10996C139.053 1.49996 138.653 2.16996 138.653 2.94996V23.34C138.653 26.02 139.213 27.96 140.383 29.29C141.573 30.61 143.493 31.28 146.093 31.28H152.283C153.043 31.28 153.683 30.91 154.063 30.26C154.313 29.83 154.443 29.28 154.443 28.6C154.443 27.92 154.323 27.37 154.063 26.93L154.073 26.94Z"
      fill="#6085FF"
    />
    <path
      d="M128.133 2.15994C126.433 0.999941 124.053 0.439941 120.853 0.439941C118.973 0.439941 117.303 0.649941 115.893 1.04994C114.343 1.46994 113.043 2.23994 112.023 3.34994C110.943 4.49994 110.393 5.96994 110.393 7.70994V29.3299C110.393 30.1099 110.793 30.7799 111.483 31.1699C111.953 31.4399 112.553 31.5599 113.323 31.5599C114.093 31.5599 114.683 31.4299 115.163 31.1699C115.853 30.7799 116.253 30.1099 116.253 29.3299V9.30994C116.253 7.32994 117.403 5.79994 120.633 5.79994C123.863 5.79994 125.053 7.26994 125.053 9.30994V29.3299C125.053 30.1099 125.453 30.7799 126.143 31.1699C126.613 31.4399 127.213 31.5699 127.983 31.5699C128.753 31.5699 129.343 31.4399 129.823 31.1799C130.513 30.7899 130.913 30.1199 130.913 29.3399V7.70994C130.913 5.30994 129.963 3.38994 128.153 2.15994H128.133Z"
      fill="#6085FF"
    />
    <path
      d="M120.643 20.4599C122.212 20.4599 123.483 19.1884 123.483 17.6199C123.483 16.0515 122.212 14.7799 120.643 14.7799C119.075 14.7799 117.803 16.0515 117.803 17.6199C117.803 19.1884 119.075 20.4599 120.643 20.4599Z"
      fill="#6085FF"
    />
    <path
      d="M99.0231 11.68C99.4931 11.95 100.093 12.07 100.863 12.07C101.633 12.07 102.223 11.94 102.703 11.68C103.393 11.29 103.793 10.62 103.793 9.84V7.52C103.793 5.09 102.873 3.22 101.073 1.97C99.3931 0.81 97.0431 0.25 93.8731 0.25C90.9831 0.25 88.5931 0.79 86.7831 1.86C84.7531 3.02 83.6831 4.98 83.6831 7.52V24.48C83.6831 27.02 84.7531 28.99 86.7831 30.18C88.5931 31.22 90.9831 31.75 93.8731 31.75C97.0331 31.75 99.3931 31.19 101.073 30.03C102.883 28.78 103.793 26.91 103.793 24.48V22.17C103.793 21.39 103.393 20.72 102.703 20.33C102.233 20.06 101.633 19.94 100.863 19.94C100.093 19.94 99.5031 20.07 99.0231 20.34C98.3331 20.73 97.9331 21.4 97.9331 22.18V24.87C97.9331 25.09 97.8931 25.27 97.7931 25.41C97.6931 25.57 97.5131 25.71 97.2631 25.84C96.7631 26.1 95.7331 26.41 93.7031 26.41C91.6731 26.41 90.6631 26.1 90.1731 25.84C89.9231 25.71 89.7531 25.57 89.6531 25.41C89.5631 25.27 89.5131 25.09 89.5131 24.87V7.15C89.5131 6.93 89.5531 6.75 89.6431 6.61C89.7431 6.45 89.9231 6.31 90.1631 6.18C90.6631 5.92 91.6731 5.61 93.6931 5.61C95.7131 5.61 96.7531 5.92 97.2631 6.18C97.5131 6.31 97.6931 6.45 97.7831 6.61C97.8731 6.75 97.9231 6.93 97.9231 7.15V9.84C97.9231 10.62 98.3231 11.29 99.0131 11.68H99.0231Z"
      fill="#6085FF"
    />
    <path
      d="M76.1431 17.53C75.9131 17.17 75.6931 16.87 75.5131 16.65C75.2131 16.2 74.7431 15.82 74.0631 15.49L72.8931 14.92C72.6731 14.8 72.4131 14.71 72.2131 14.64C71.9631 14.56 71.6531 14.47 71.2831 14.38C70.5631 14.19 69.5831 13.97 68.3431 13.7L64.9431 12.95C63.8231 12.71 63.4531 12.41 63.3731 12.33C63.3231 12.26 63.0531 11.82 63.0531 10.34V7.17003C63.0531 6.69003 63.2131 6.42003 63.6431 6.18003C64.0931 5.93003 65.0131 5.63003 66.8531 5.63003C68.6931 5.63003 69.6431 5.93003 70.0931 6.18003C70.5331 6.42003 70.6931 6.69003 70.6931 7.17003V9.19003C70.6931 9.97003 71.0931 10.64 71.7831 11.03C72.2531 11.3 72.8531 11.43 73.6231 11.43C74.3931 11.43 74.9831 11.3 75.4631 11.03C76.1531 10.64 76.5531 9.97003 76.5531 9.19003V7.55003C76.5531 5.03003 75.5431 3.07003 73.6531 1.91003C71.9331 0.830029 69.6631 0.280029 66.9031 0.280029C64.1431 0.280029 61.8731 0.830029 60.1231 1.91003C58.2131 3.08003 57.2131 5.03003 57.2131 7.56003V10.44C57.2131 13.71 58.3131 15.94 60.4731 17.06C61.3831 17.55 62.6431 17.97 64.3431 18.35L67.7431 19.1C69.5931 19.53 70.4331 19.86 70.8031 20.07C70.9731 20.16 71.0931 20.27 71.0931 20.67V24.89C71.0931 25.15 71.0131 25.28 70.9531 25.36C70.8231 25.53 70.5831 25.69 70.2431 25.83C69.3331 26.22 68.1431 26.42 66.7131 26.42C65.2831 26.42 64.1431 26.23 63.2731 25.87C62.9931 25.74 62.7831 25.59 62.6731 25.43C62.5731 25.29 62.5231 25.11 62.5231 24.88V22.86C62.5231 22.08 62.1231 21.41 61.4331 21.02C60.9631 20.75 60.3531 20.63 59.5931 20.63C58.8331 20.63 58.2331 20.76 57.7531 21.03C57.0631 21.42 56.6631 22.09 56.6631 22.87V24.51C56.6631 27.02 57.7331 28.98 59.7631 30.17C61.6031 31.24 63.9431 31.78 66.7031 31.78C69.4631 31.78 71.8431 31.24 73.7031 30.18C75.8131 28.99 76.9231 27.03 76.9231 24.51V20.96C76.9231 19.48 76.6631 18.37 76.1231 17.56L76.1431 17.53Z"
      fill="#6085FF"
    />
    <path
      d="M47.3733 2.15994C45.6733 0.999941 43.2933 0.439941 40.0933 0.439941C38.2133 0.439941 36.5433 0.649941 35.1333 1.04994C33.5833 1.46994 32.2833 2.23994 31.2633 3.34994C30.1833 4.49994 29.6333 5.96994 29.6333 7.70994V29.3299C29.6333 30.1099 30.0333 30.7799 30.7233 31.1699C31.1933 31.4399 31.7933 31.5599 32.5633 31.5599C33.3333 31.5599 33.9233 31.4299 34.4033 31.1699C35.0933 30.7799 35.4933 30.1099 35.4933 29.3299V9.30994C35.4933 7.32994 36.6433 5.79994 39.8733 5.79994C43.1033 5.79994 44.2933 7.26994 44.2933 9.30994V29.3299C44.2933 30.1099 44.6933 30.7799 45.3833 31.1699C45.8533 31.4399 46.4533 31.5699 47.2233 31.5699C47.9933 31.5699 48.5833 31.4399 49.0633 31.1799C49.7533 30.7899 50.1533 30.1199 50.1533 29.3399V7.70994C50.1533 5.30994 49.2033 3.38994 47.3933 2.15994H47.3733Z"
      fill="#6085FF"
    />
    <path
      d="M39.8933 20.4599C41.4618 20.4599 42.7333 19.1884 42.7333 17.6199C42.7333 16.0515 41.4618 14.7799 39.8933 14.7799C38.3248 14.7799 37.0533 16.0515 37.0533 17.6199C37.0533 19.1884 38.3248 20.4599 39.8933 20.4599Z"
      fill="#6085FF"
    />
    <path
      d="M0.453125 29.94C0.453125 30.56 0.723125 32 3.25312 32C5.78312 32 6.05312 30.56 6.05312 29.94V9.77C6.05312 6.71 8.45312 5.09 12.0331 5.09C15.6131 5.09 18.0531 7.09 18.0531 9.77V14.2C18.0531 16.18 16.7931 17.73 13.2231 17.73H10.4431C9.99312 17.73 8.52312 17.92 8.52312 20.31C8.52312 22.7 9.99312 22.89 10.4431 22.89H13.2231C15.8331 22.89 18.4731 22.42 20.5831 21.1C22.6331 19.78 23.6631 17.69 23.6631 14.9V9.05C23.6631 4.12 20.0231 0 12.0631 0C0.453125 0 0.453125 7.15 0.453125 12.5V29.94Z"
      fill="#6085FF"
    />
  </svg>
);

export default PascalLogo;
