import AUTH_TYPES from "../types/auth";

const initState = {
  currentAuth: {},
  currentUser: {},
};

const auth = (state: any = initState, action: any) => {
  switch (action.type) {
    case AUTH_TYPES?.UPDATE_NEW_TOKEN: {
      const { payload } = action;
      return {
        ...state,
        currentAuth: payload,
      };
    }
    default:
      return { ...state };
  }
};

export default auth;
