import * as React from "react";

const Dashboard = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6663 5.3335V10.6668C14.6663 11.7277 14.2449 12.7451 13.4948 13.4953C12.7446 14.2454 11.7272 14.6668 10.6663 14.6668H5.33301C4.27214 14.6668 3.25473 14.2454 2.50458 13.4953C1.75444 12.7451 1.33301 11.7277 1.33301 10.6668V5.3335C1.33301 4.27263 1.75444 3.25521 2.50458 2.50507C3.25473 1.75492 4.27214 1.3335 5.33301 1.3335H10.6663C11.7272 1.3335 12.7446 1.75492 13.4948 2.50507C14.2449 3.25521 14.6663 4.27263 14.6663 5.3335ZM26.6663 1.3335H21.333C20.2721 1.3335 19.2547 1.75492 18.5046 2.50507C17.7544 3.25521 17.333 4.27263 17.333 5.3335V10.6668C17.333 11.7277 17.7544 12.7451 18.5046 13.4953C19.2547 14.2454 20.2721 14.6668 21.333 14.6668H26.6663C27.7272 14.6668 28.7446 14.2454 29.4948 13.4953C30.2449 12.7451 30.6663 11.7277 30.6663 10.6668V5.3335C30.6663 4.27263 30.2449 3.25521 29.4948 2.50507C28.7446 1.75492 27.7272 1.3335 26.6663 1.3335ZM10.6663 17.3335H5.33301C4.27214 17.3335 3.25473 17.7549 2.50458 18.5051C1.75444 19.2552 1.33301 20.2726 1.33301 21.3335V26.6668C1.33301 27.7277 1.75444 28.7451 2.50458 29.4953C3.25473 30.2454 4.27214 30.6668 5.33301 30.6668H10.6663C11.7272 30.6668 12.7446 30.2454 13.4948 29.4953C14.2449 28.7451 14.6663 27.7277 14.6663 26.6668V21.3335C14.6663 20.2726 14.2449 19.2552 13.4948 18.5051C12.7446 17.7549 11.7272 17.3335 10.6663 17.3335ZM26.6663 17.3335H21.333C20.2721 17.3335 19.2547 17.7549 18.5046 18.5051C17.7544 19.2552 17.333 20.2726 17.333 21.3335V26.6668C17.333 27.7277 17.7544 28.7451 18.5046 29.4953C19.2547 30.2454 20.2721 30.6668 21.333 30.6668H26.6663C27.7272 30.6668 28.7446 30.2454 29.4948 29.4953C30.2449 28.7451 30.6663 27.7277 30.6663 26.6668V21.3335C30.6663 20.2726 30.2449 19.2552 29.4948 18.5051C28.7446 17.7549 27.7272 17.3335 26.6663 17.3335Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default Dashboard;
