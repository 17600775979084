import * as React from "react";

function NotFoundIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="257"
      height="142"
      viewBox="0 0 257 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.2"
        d="M256.5 127.734C256.5 131.902 199.193 135.28 128.5 135.28C57.8079 135.28 0.5 131.902 0.5 127.734C0.5 123.566 57.8079 120.187 128.5 120.187C199.193 120.187 256.5 123.566 256.5 127.734Z"
        fill="#7897FF"
      />
      <path
        d="M86.0676 29.8732V33.0352H73.1903V29.8732L73.198 29.736C73.2663 27.8613 74.8055 26.3679 76.6951 26.3679H82.57C83.6445 26.3679 84.6122 26.8633 85.2597 27.6331C85.7628 28.2349 86.0676 29.0196 86.0676 29.8732Z"
        fill="#4B5563"
      />
      <path d="M75.6895 119.122H83.5686V32.5933H75.6895V119.122Z" fill="#4B5563" />
      <path d="M91.7363 119.083V125.598H67.5215V119.083H91.7363Z" fill="#4B5563" />
      <path
        d="M91.7363 119.083V125.598H79.5606C79.2024 123.373 77.2742 121.682 74.9504 121.682H67.5215V119.083H91.7363Z"
        fill="#1F2937"
      />
      <path
        d="M86.0675 29.8732V33.0352H83.5685V119.122H79.6212C79.5832 117.712 79.1412 116.417 78.4022 115.327C77.7542 114.374 76.8857 113.582 75.8646 113.026C75.804 112.995 75.7505 112.965 75.6894 112.934V105.627H79.6289V81.2373C79.6289 79.7208 79.1721 78.3112 78.3797 77.1374C77.7013 76.1317 76.7794 75.3012 75.6894 74.7447V67.5142H79.6289V36.7532C79.6289 35.3893 79.2404 34.1169 78.5621 33.0352C78.4783 32.8826 78.3797 32.7376 78.265 32.5932C78.0595 32.2962 77.8154 32.0141 77.5487 31.7551C76.916 31.1225 76.1545 30.6116 75.3163 30.254C74.6611 29.9796 73.9447 29.7966 73.198 29.736C73.2663 27.8613 74.8055 26.3679 76.6951 26.3679H82.5699C83.6445 26.3679 84.6122 26.8633 85.2597 27.6331C85.7628 28.2349 86.0675 29.0196 86.0675 29.8732Z"
        fill="#1F2937"
      />
      <path
        d="M89.6958 16.8273C89.6958 22.338 85.2283 26.8054 79.7177 26.8054C74.207 26.8054 69.7401 22.338 69.7401 16.8273C69.7401 11.3167 74.207 6.84921 79.7177 6.84921C85.2283 6.84921 89.6958 11.3167 89.6958 16.8273Z"
        fill="#4F71E3"
      />
      <path
        d="M89.6958 16.8273C89.6958 22.338 85.2283 26.8054 79.7177 26.8054C74.207 26.8054 69.7401 22.338 69.7401 16.8273C69.7401 11.3167 74.207 6.84921 79.7177 6.84921C85.2283 6.84921 89.6958 11.3167 89.6958 16.8273Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M86.8633 20.833C86.2351 21.9517 85.3517 22.9078 84.2915 23.6225C84.1653 23.7079 84.1322 23.8793 84.2171 24.0049C84.3025 24.1311 84.4739 24.1647 84.6001 24.0793C85.7308 23.3167 86.6732 22.2966 87.3438 21.103C87.4182 20.9702 87.3714 20.8022 87.2386 20.7278C87.1057 20.6534 86.9382 20.7002 86.8633 20.833Z"
        fill="#FDE047"
      />
      <path
        d="M81.8244 24.7445C81.248 24.8971 80.6468 24.9892 80.0274 25.0123C79.8753 25.0178 79.7562 25.1457 79.7623 25.2978C79.7678 25.4498 79.8957 25.5683 80.0478 25.5628C80.7079 25.538 81.3505 25.4405 81.966 25.2768C82.1132 25.2377 82.2008 25.0872 82.1616 24.9401C82.1225 24.793 81.9715 24.7054 81.8244 24.7445Z"
        fill="#FDE047"
      />
      <path
        d="M73.0184 12.1146C73.2487 11.7878 73.5028 11.4787 73.7772 11.1888C73.8819 11.0786 73.8775 10.9039 73.7667 10.7992C73.6565 10.6945 73.4824 10.6995 73.3777 10.8097C73.0845 11.1183 72.8139 11.4484 72.5682 11.7972C72.4805 11.9212 72.5103 12.0931 72.6343 12.1813C72.7588 12.2689 72.9307 12.2392 73.0184 12.1146Z"
        fill="#FDE047"
      />
      <path
        d="M75.3185 9.91755C76.5893 9.10693 78.0975 8.63743 79.7177 8.63688C81.1912 8.63743 82.5716 9.02538 83.7658 9.7054C83.898 9.78089 84.0661 9.7346 84.1416 9.60234C84.2165 9.47064 84.1708 9.30201 84.0386 9.22651C82.7645 8.50076 81.2882 8.08581 79.7177 8.08581C77.9906 8.08581 76.3782 8.58783 75.022 9.453C74.8942 9.53456 74.8562 9.70483 74.9383 9.83323C75.0198 9.96163 75.1907 9.9991 75.3185 9.91755Z"
        fill="#FDE047"
      />
      <path
        d="M79.7177 23.5993C75.9776 23.5993 72.9457 20.5674 72.9457 16.8273C72.9457 13.0872 75.9776 10.0553 79.7177 10.0553C83.4578 10.0553 86.4897 13.0872 86.4897 16.8273C86.4897 20.5674 83.4578 23.5993 79.7177 23.5993Z"
        fill="#4F71E3"
      />
      <path
        d="M79.7177 23.5993C75.9776 23.5993 72.9457 20.5674 72.9457 16.8273C72.9457 13.0872 75.9776 10.0553 79.7177 10.0553C83.4578 10.0553 86.4897 13.0872 86.4897 16.8273C86.4897 20.5674 83.4578 23.5993 79.7177 23.5993Z"
        fill="white"
        fillOpacity="0.45"
      />
      <path
        d="M84.1658 16.8273C84.1658 19.284 82.1743 21.2755 79.7177 21.2755C77.2616 21.2755 75.27 19.284 75.27 16.8273C75.27 14.3707 77.2616 12.3797 79.7177 12.3797C82.1743 12.3797 84.1658 14.3707 84.1658 16.8273Z"
        fill="#FDE047"
      />
      <path
        d="M76.8918 80.7413C76.8918 80.743 76.8918 81.3574 76.8918 82.4309C76.8918 83.5044 76.8918 85.0358 76.8918 86.8664C76.8918 87.0191 77.0153 87.142 77.1674 87.142C77.3195 87.142 77.4429 87.0191 77.4429 86.8664C77.4429 85.0358 77.4429 83.5044 77.4429 82.4309C77.4429 81.3574 77.4429 80.743 77.4429 80.7413C77.4429 80.5892 77.3195 80.4658 77.1674 80.4658C77.0153 80.4658 76.8918 80.5892 76.8918 80.7413Z"
        fill="#6B7280"
      />
      <path
        d="M76.8918 115.715C76.8918 117.2 76.8918 118.078 76.8918 118.078C76.8918 118.23 77.0153 118.353 77.1674 118.353C77.3195 118.353 77.4429 118.23 77.4429 118.078C77.4429 118.078 77.4429 117.2 77.4429 115.715C77.4429 115.563 77.3195 115.44 77.1674 115.44C77.0153 115.44 76.8918 115.563 76.8918 115.715Z"
        fill="#6B7280"
      />
      <path
        d="M183.808 29.8732V33.0352H170.931V29.8732L170.939 29.736C171.007 27.8613 172.547 26.3679 174.436 26.3679H180.31C181.386 26.3679 182.353 26.8633 183.001 27.6331C183.503 28.2349 183.808 29.0196 183.808 29.8732Z"
        fill="#4B5563"
      />
      <path d="M173.43 119.122H181.309V32.5933H173.43V119.122Z" fill="#4B5563" />
      <path d="M189.477 119.083V125.598H165.262V119.083H189.477Z" fill="#4B5563" />
      <path
        d="M189.477 119.083V125.598H177.301C176.943 123.373 175.015 121.682 172.692 121.682H165.262V119.083H189.477Z"
        fill="#1F2937"
      />
      <path
        d="M183.808 29.8732V33.0352H181.309V119.122H177.362C177.324 117.712 176.882 116.417 176.143 115.327C175.495 114.374 174.627 113.582 173.606 113.026C173.545 112.995 173.491 112.965 173.431 112.934V105.627H177.37V81.2373C177.37 79.7208 176.913 78.3112 176.12 77.1374C175.442 76.1317 174.521 75.3012 173.431 74.7447V67.5142H177.37V36.7532C177.37 35.3893 176.981 34.1169 176.303 33.0352C176.219 32.8826 176.12 32.7376 176.006 32.5932C175.8 32.2962 175.556 32.0141 175.29 31.7551C174.657 31.1225 173.896 30.6116 173.057 30.254C172.402 29.9796 171.686 29.7966 170.939 29.736C171.007 27.8613 172.547 26.3679 174.436 26.3679H180.311C181.386 26.3679 182.353 26.8633 183.001 27.6331C183.503 28.2349 183.808 29.0196 183.808 29.8732Z"
        fill="#1F2937"
      />
      <path
        d="M187.437 16.8273C187.437 22.338 182.969 26.8054 177.459 26.8054C171.948 26.8054 167.481 22.338 167.481 16.8273C167.481 11.3167 171.948 6.84921 177.459 6.84921C182.969 6.84921 187.437 11.3167 187.437 16.8273Z"
        fill="#4F71E3"
      />
      <path
        d="M187.437 16.8273C187.437 22.338 182.969 26.8054 177.459 26.8054C171.948 26.8054 167.481 22.338 167.481 16.8273C167.481 11.3167 171.948 6.84921 177.459 6.84921C182.969 6.84921 187.437 11.3167 187.437 16.8273Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M184.604 20.833C183.976 21.9516 183.092 22.9077 182.033 23.6225C181.906 23.7079 181.873 23.8793 181.958 24.0055C182.044 24.1311 182.214 24.1647 182.341 24.0793C183.471 23.3166 184.414 22.2966 185.085 21.103C185.159 20.9702 185.112 20.8021 184.98 20.7277C184.847 20.6533 184.679 20.7002 184.604 20.833Z"
        fill="#FDE047"
      />
      <path
        d="M179.565 24.7445C178.989 24.8971 178.387 24.9892 177.768 25.0123C177.616 25.0178 177.497 25.1457 177.503 25.2978C177.508 25.4498 177.636 25.5683 177.788 25.5628C178.449 25.538 179.091 25.4405 179.707 25.2768C179.854 25.2377 179.941 25.0867 179.902 24.9401C179.863 24.793 179.712 24.7054 179.565 24.7445Z"
        fill="#FDE047"
      />
      <path
        d="M170.76 12.1146C170.989 11.7878 171.243 11.4787 171.518 11.1888C171.623 11.0786 171.618 10.9045 171.508 10.7998C171.398 10.6945 171.223 10.6995 171.118 10.8097C170.825 11.1183 170.554 11.4484 170.309 11.7972C170.221 11.9218 170.251 12.0937 170.375 12.1813C170.5 12.2689 170.672 12.2392 170.76 12.1146Z"
        fill="#FDE047"
      />
      <path
        d="M173.059 9.91755C174.33 9.10693 175.839 8.63743 177.459 8.63688C178.932 8.63743 180.312 9.02538 181.506 9.7054C181.639 9.78089 181.807 9.7346 181.882 9.60234C181.957 9.47064 181.911 9.30201 181.779 9.22651C180.505 8.50076 179.029 8.08581 177.459 8.08581C175.731 8.08581 174.119 8.58783 172.763 9.453C172.635 9.53456 172.597 9.70483 172.679 9.83323C172.761 9.96163 172.931 9.9991 173.059 9.91755Z"
        fill="#FDE047"
      />
      <path
        d="M177.459 23.5993C173.718 23.5993 170.686 20.5674 170.686 16.8273C170.686 13.0872 173.718 10.0553 177.459 10.0553C181.199 10.0553 184.231 13.0872 184.231 16.8273C184.231 20.5674 181.199 23.5993 177.459 23.5993Z"
        fill="#4F71E3"
      />
      <path
        d="M177.459 23.5993C173.718 23.5993 170.686 20.5674 170.686 16.8273C170.686 13.0872 173.718 10.0553 177.459 10.0553C181.199 10.0553 184.231 13.0872 184.231 16.8273C184.231 20.5674 181.199 23.5993 177.459 23.5993Z"
        fill="white"
        fillOpacity="0.45"
      />
      <path
        d="M181.907 16.8273C181.907 19.284 179.915 21.2755 177.459 21.2755C175.003 21.2755 173.01 19.284 173.01 16.8273C173.01 14.3707 175.003 12.3797 177.459 12.3797C179.915 12.3797 181.907 14.3707 181.907 16.8273Z"
        fill="#FDE047"
      />
      <path
        d="M174.633 80.7413C174.633 80.743 174.633 81.3574 174.633 82.4309C174.633 83.5044 174.633 85.0358 174.633 86.8664C174.633 87.0191 174.756 87.142 174.908 87.142C175.06 87.142 175.184 87.0191 175.184 86.8664C175.184 85.0358 175.184 83.5044 175.184 82.4309C175.184 81.3574 175.184 80.743 175.184 80.7413C175.184 80.5892 175.06 80.4658 174.908 80.4658C174.756 80.4658 174.633 80.5892 174.633 80.7413Z"
        fill="#6B7280"
      />
      <path
        d="M174.633 115.715C174.633 117.2 174.633 118.078 174.633 118.078C174.633 118.23 174.756 118.353 174.908 118.353C175.06 118.353 175.184 118.23 175.184 118.078C175.184 118.078 175.184 117.2 175.184 115.715C175.184 115.563 175.06 115.44 174.908 115.44C174.756 115.44 174.633 115.563 174.633 115.715Z"
        fill="#6B7280"
      />
      <path d="M62.6694 109.048H194.329V89.931H62.6694V109.048Z" fill="#4F71E3" />
      <path d="M62.6694 109.048H194.329V89.931H62.6694V109.048Z" fill="white" fillOpacity="0.9" />
      <path d="M77.1927 89.9309L62.6694 104.484V96.6671L69.3902 89.9309H77.1927Z" fill="#4F71E3" />
      <path
        d="M77.1927 89.9309L62.6694 104.484V96.6671L69.3902 89.9309H77.1927Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M92.4776 89.9309L73.3981 109.048H65.5879L84.6674 89.9309H92.4776Z" fill="#4F71E3" />
      <path
        d="M92.4776 89.9309L73.3981 109.048H65.5879L84.6674 89.9309H92.4776Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M107.755 89.9309L88.6752 109.048H80.8727L99.9527 89.9309H107.755Z" fill="#4F71E3" />
      <path
        d="M107.755 89.9309L88.6752 109.048H80.8727L99.9527 89.9309H107.755Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M123.04 89.9309L103.961 109.048H96.1504L115.23 89.9309H123.04Z" fill="#4F71E3" />
      <path
        d="M123.04 89.9309L103.961 109.048H96.1504L115.23 89.9309H123.04Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M138.325 89.9309L119.246 109.048H111.435L130.515 89.9309H138.325Z" fill="#4F71E3" />
      <path
        d="M138.325 89.9309L119.246 109.048H111.435L130.515 89.9309H138.325Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M153.602 89.9309L134.523 109.048H126.712L145.792 89.9309H153.602Z" fill="#4F71E3" />
      <path
        d="M153.602 89.9309L134.523 109.048H126.712L145.792 89.9309H153.602Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M168.888 89.9309L149.808 109.048H141.998L161.077 89.9309H168.888Z" fill="#4F71E3" />
      <path
        d="M168.888 89.9309L149.808 109.048H141.998L161.077 89.9309H168.888Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M184.164 89.9309L165.085 109.048H157.282L176.362 89.9309H184.164Z" fill="#4F71E3" />
      <path
        d="M184.164 89.9309L165.085 109.048H157.282L176.362 89.9309H184.164Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M194.329 89.9309V95.0662L180.37 109.048H172.56L191.639 89.9309H194.329Z"
        fill="#4F71E3"
      />
      <path
        d="M194.329 89.9309V95.0662L180.37 109.048H172.56L191.639 89.9309H194.329Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M194.329 102.549V109.048H187.845L194.329 102.549Z" fill="#4F71E3" />
      <path
        d="M194.329 102.549V109.048H187.845L194.329 102.549Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M194.329 109.048H181.309H194.329V89.9309V109.048Z" fill="#9CA3AF" />
      <path
        d="M187.845 109.048H180.37L194.329 95.0662V102.549L187.845 109.048ZM172.56 109.048H165.085L184.164 89.9309H191.639L172.56 109.048ZM157.282 109.048H155.965C154.972 107.986 153.928 106.975 152.833 106.018L168.888 89.9309H176.362L157.282 109.048ZM148.404 102.629C146.89 101.619 145.306 100.703 143.663 99.8903L153.602 89.9309H161.077L148.404 102.629ZM138.119 97.6193C136.185 96.9823 134.19 96.4803 132.145 96.1237L138.325 89.9309H145.792L138.119 97.6193ZM124.97 95.4862C124.896 95.4856 124.821 95.4856 124.747 95.4856H117.496L123.04 89.9309H130.515L124.97 95.4862ZM109.686 95.4856H102.211L107.755 89.9309H115.23L109.686 95.4856ZM94.409 95.4856H86.9339L92.4776 89.9309H99.9527L94.409 95.4856ZM79.1236 95.4856H71.6496L77.1927 89.9309H84.6673L79.1236 95.4856ZM63.8482 95.4856H62.6694V89.9309H69.3902L63.8482 95.4856Z"
        fill="#D1D5DB"
      />
      <path d="M71.6496 95.4856H63.8481L69.3902 89.9309H77.1927L71.6496 95.4856Z" fill="#4F71E3" />
      <path
        d="M71.6496 95.4856H63.8481L69.3902 89.9309H77.1927L71.6496 95.4856Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M86.9339 95.4856H79.1237L84.6674 89.9309H92.4776L86.9339 95.4856Z" fill="#4F71E3" />
      <path
        d="M86.9339 95.4856H79.1237L84.6674 89.9309H92.4776L86.9339 95.4856Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M102.211 95.4856H94.4091L99.9528 89.9309H107.755L102.211 95.4856Z" fill="#4F71E3" />
      <path
        d="M102.211 95.4856H94.4091L99.9528 89.9309H107.755L102.211 95.4856Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M117.496 95.4856H109.686L115.23 89.9309H123.04L117.496 95.4856Z" fill="#4F71E3" />
      <path
        d="M117.496 95.4856H109.686L115.23 89.9309H123.04L117.496 95.4856Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M132.145 96.1238C129.813 95.7165 127.416 95.4989 124.97 95.4862L130.515 89.9309H138.325L132.145 96.1238Z"
        fill="#4F71E3"
      />
      <path
        d="M132.145 96.1238C129.813 95.7165 127.416 95.4989 124.97 95.4862L130.515 89.9309H138.325L132.145 96.1238Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M143.663 99.8903C141.882 99.0091 140.031 98.2486 138.119 97.6193L145.792 89.9309H153.602L143.663 99.8903Z"
        fill="#4F71E3"
      />
      <path
        d="M143.663 99.8903C141.882 99.0091 140.031 98.2486 138.119 97.6193L145.792 89.9309H153.602L143.663 99.8903Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M152.833 106.018C151.434 104.796 149.955 103.662 148.404 102.629L161.077 89.9309H168.887L152.833 106.018Z"
        fill="#4F71E3"
      />
      <path
        d="M152.833 106.018C151.434 104.796 149.955 103.662 148.404 102.629L161.077 89.9309H168.887L152.833 106.018Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M165.085 109.048H157.282L176.362 89.9309H184.164L165.085 109.048Z" fill="#4F71E3" />
      <path
        d="M165.085 109.048H157.282L176.362 89.9309H184.164L165.085 109.048Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M180.37 109.048H172.56L191.639 89.9309H194.329V95.0662L180.37 109.048Z"
        fill="#4F71E3"
      />
      <path
        d="M180.37 109.048H172.56L191.639 89.9309H194.329V95.0662L180.37 109.048Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M194.329 109.048H187.845L194.329 102.549V109.048Z" fill="#4F71E3" />
      <path
        d="M194.329 109.048H187.845L194.329 102.549V109.048Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M62.671 70.8184H194.331V32.5917H62.671V70.8184Z" fill="#4F71E3" />
      <path d="M62.671 70.8184H194.331V32.5917H62.671V70.8184Z" fill="white" fillOpacity="0.9" />
      <path d="M85.7111 32.5933L62.6694 55.6806V43.3825L73.4438 32.5933H85.7111Z" fill="#4F71E3" />
      <path
        d="M85.7111 32.5933L62.6694 55.6806V43.3825L73.4438 32.5933H85.7111Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M110.254 32.5932L72.1031 70.8205H62.6694V67.971L97.9788 32.5932H110.254Z"
        fill="#4F71E3"
      />
      <path
        d="M110.254 32.5932L72.1031 70.8205H62.6694V67.971L97.9788 32.5932H110.254Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M134.789 32.5932L96.6375 70.8205H84.3702L122.521 32.5932H134.789Z" fill="#4F71E3" />
      <path
        d="M134.789 32.5932L96.6375 70.8205H84.3702L122.521 32.5932H134.789Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M159.332 32.5932L121.181 70.8205H108.913L147.064 32.5932H159.332Z" fill="#4F71E3" />
      <path
        d="M159.332 32.5932L121.181 70.8205H108.913L147.064 32.5932H159.332Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M183.875 32.5932L145.723 70.8205H133.448L171.6 32.5932H183.875Z" fill="#4F71E3" />
      <path
        d="M183.875 32.5932L145.723 70.8205H133.448L171.6 32.5932H183.875Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M194.329 34.4068V46.6972L170.259 70.8205H157.991L194.329 34.4068Z" fill="#4F71E3" />
      <path
        d="M194.329 34.4068V46.6972L170.259 70.8205H157.991L194.329 34.4068Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M194.329 60.2754V70.8205H183.806L194.329 60.2754Z" fill="#4F71E3" />
      <path
        d="M194.329 60.2754V70.8205H183.806L194.329 60.2754Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M127.446 64.6272H127.362L128.501 63.4859V63.5719L127.446 64.6272ZM115.095 64.6272H102.819L110.465 56.9652H122.741L115.095 64.6272ZM90.5515 64.6272H78.2843L85.9309 56.9652H98.1981L90.5515 64.6272ZM67.9926 62.6378C67.6923 62.0912 67.5215 61.4635 67.5215 60.7962C67.5215 58.6806 69.2364 56.9652 71.3519 56.9652H73.6543L67.9926 62.6378Z"
        fill="#EFF3FF"
      />
      <path
        d="M78.2843 64.6272H71.352C69.9043 64.6272 68.6435 63.8232 67.9927 62.6378L73.6543 56.9652H85.9309L78.2843 64.6272Z"
        fill="#4F71E3"
      />
      <path
        d="M78.2843 64.6272H71.352C69.9043 64.6272 68.6435 63.8232 67.9927 62.6378L73.6543 56.9652H85.9309L78.2843 64.6272Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M102.819 64.6272H90.5516L98.1982 56.9652H110.465L102.819 64.6272Z" fill="#4F71E3" />
      <path
        d="M102.819 64.6272H90.5516L98.1982 56.9652H110.465L102.819 64.6272Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M127.362 64.6272H115.095L122.741 56.9652H128.501V63.4859L127.362 64.6272Z"
        fill="#4F71E3"
      />
      <path
        d="M127.362 64.6272H115.095L122.741 56.9652H128.501V63.4859L127.362 64.6272Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M114.098 106.38H106.624L114.27 98.7181H117.604C118.468 98.7181 119.265 99.0041 119.906 99.4863C120.109 99.639 120.296 99.8115 120.465 100.001L114.098 106.38ZM98.8136 106.38H91.3385L98.985 98.7181H106.46L98.8136 106.38ZM83.536 106.38H76.0613L83.7079 98.7181H91.1825L83.536 106.38ZM69.032 105.598C68.1139 104.898 67.5215 103.792 67.5215 102.549C67.5215 100.434 69.2364 98.7181 71.3519 98.7181H75.8977L69.032 105.598Z"
        fill="#EFF3FF"
      />
      <path
        d="M76.0614 106.38H71.352C70.4802 106.38 69.6762 106.089 69.032 105.598L75.8977 98.7181H83.7079L76.0614 106.38Z"
        fill="#4F71E3"
      />
      <path
        d="M76.0614 106.38H71.352C70.4802 106.38 69.6762 106.089 69.032 105.598L75.8977 98.7181H83.7079L76.0614 106.38Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M91.3385 106.38H83.536L91.1826 98.7181H98.9851L91.3385 106.38Z" fill="#4F71E3" />
      <path
        d="M91.3385 106.38H83.536L91.1826 98.7181H98.9851L91.3385 106.38Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M106.624 106.38H98.8137L106.46 98.7181H114.27L106.624 106.38Z" fill="#4F71E3" />
      <path
        d="M106.624 106.38H98.8137L106.46 98.7181H114.27L106.624 106.38Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M117.604 106.38H114.099L120.465 100.001C121.068 100.678 121.435 101.57 121.435 102.549C121.435 104.665 119.72 106.38 117.604 106.38Z"
        fill="#4F71E3"
      />
      <path
        d="M117.604 106.38H114.099L120.465 100.001C121.068 100.678 121.435 101.57 121.435 102.549C121.435 104.665 119.72 106.38 117.604 106.38Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M194.329 70.8205H181.309V70.8183H183.809L183.806 70.8205H194.329V70.8183H194.331L194.329 70.8205ZM173.431 70.8205H168.514H170.259L170.261 70.8183H173.431V70.8205Z"
        fill="#9CA3AF"
      />
      <path d="M173.43 70.8205H181.309V70.8182H173.43V70.8205Z" fill="#4B5563" />
      <path
        d="M194.331 70.8184L194.329 60.2754L183.809 70.8184H181.309H173.43H170.261L194.329 46.6972V34.4068L164.92 63.8771C164.549 63.2974 164.164 62.7271 163.766 62.1667L155.115 70.8184H150.113L165.648 55.2789C166.155 54.7774 166.48 54.1437 166.602 53.4935C166.791 52.5015 166.552 51.4468 165.877 50.6268L183.875 32.5933H194.329V70.8184H194.331ZM159.746 44.4703L153.607 38.3293L159.332 32.5933H171.6L159.746 44.4703Z"
        fill="#D1D5DB"
      />
      <path d="M153.607 38.3293L147.873 32.5933H159.332L153.607 38.3293Z" fill="#4F71E3" />
      <path
        d="M153.607 38.3293L147.873 32.5933H159.332L153.607 38.3293Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M165.877 50.6268C165.806 50.5397 165.729 50.4554 165.648 50.3744L159.746 44.4703L171.6 32.5932H183.875L165.877 50.6268Z"
        fill="#4F71E3"
      />
      <path
        d="M165.877 50.6268C165.806 50.5397 165.729 50.4554 165.648 50.3744L159.746 44.4703L171.6 32.5932H183.875L165.877 50.6268Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M170.259 70.8205H168.514C167.527 68.3854 166.32 66.0643 164.92 63.8771L194.329 34.4068V46.6972L170.261 70.8183L170.259 70.8205Z"
        fill="#4F71E3"
      />
      <path
        d="M170.259 70.8205H168.514C167.527 68.3854 166.32 66.0643 164.92 63.8771L194.329 34.4068V46.6972L170.261 70.8183L170.259 70.8205Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M194.329 70.8205H183.806L183.809 70.8183L194.329 60.2754V70.8183V70.8205Z"
        fill="#4F71E3"
      />
      <path
        d="M194.329 70.8205H183.806L183.809 70.8183L194.329 60.2754V70.8183V70.8205Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M128.501 70.8184H121.254L127.446 64.6272H128.501V70.8184Z" fill="#D1D5DB" />
      <path d="M128.501 64.6272H127.446L128.501 63.5719V64.6272Z" fill="#D1D5DB" />
      <path
        d="M128.501 44.2923C127.264 44.1843 126.011 44.1292 124.747 44.1292H123.276L128.501 38.8941V44.2923ZM111.008 44.1292H98.741L110.254 32.5932H122.522L111.008 44.1292Z"
        fill="#D1D5DB"
      />
      <path d="M62.6694 43.3825V32.5933L62.6711 43.3809L62.6694 43.3825Z" fill="#9CA3AF" />
      <path
        d="M86.4648 44.1292H74.1976L85.711 32.5932H97.9788L86.4648 44.1292ZM62.671 43.3809V32.5932H73.4438L62.671 43.3809Z"
        fill="#D1D5DB"
      />
      <path
        d="M74.1977 44.1292H62.6694V43.3825L62.6711 43.3809L73.4438 32.5932H85.7111L74.1977 44.1292Z"
        fill="#4F71E3"
      />
      <path
        d="M74.1977 44.1292H62.6694V43.3825L62.6711 43.3809L73.4438 32.5932H85.7111L74.1977 44.1292Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M98.7409 44.1292H86.4648L97.9788 32.5932H110.254L98.7409 44.1292Z" fill="#4F71E3" />
      <path
        d="M98.7409 44.1292H86.4648L97.9788 32.5932H110.254L98.7409 44.1292Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M123.276 44.1292H111.008L122.522 32.5932H128.501V38.8941L123.276 44.1292Z"
        fill="#4F71E3"
      />
      <path
        d="M123.276 44.1292H111.008L122.522 32.5932H128.501V38.8941L123.276 44.1292Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M134.655 57.4176C134.634 57.406 134.612 57.395 134.591 57.3839L143.499 48.4573C143.525 48.4699 143.551 48.4826 143.577 48.4953L134.655 57.4176ZM128.501 51.1938V44.2923C130.527 44.4687 132.512 44.7866 134.447 45.2363L128.501 51.1938Z"
        fill="#D1D5DB"
      />
      <path
        d="M134.591 57.384C134.068 57.1161 133.476 56.9651 132.847 56.9651H128.501V51.1939L134.447 45.2363C137.613 45.9725 140.645 47.0609 143.499 48.4573L134.591 57.384Z"
        fill="#4F71E3"
      />
      <path
        d="M134.591 57.384C134.068 57.1161 133.476 56.9651 132.847 56.9651H128.501V51.1939L134.447 45.2363C137.613 45.9725 140.645 47.0609 143.499 48.4573L134.591 57.384Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M128.501 63.5719V63.4859L134.591 57.384C134.612 57.395 134.634 57.406 134.655 57.4176L128.501 63.5719Z"
        fill="#D1D5DB"
      />
      <path
        d="M128.501 63.4859V56.9652H132.847C133.476 56.9652 134.068 57.1162 134.591 57.384L128.501 63.4859Z"
        fill="#4F71E3"
      />
      <path
        d="M128.501 63.4859V56.9652H132.847C133.476 56.9652 134.068 57.1162 134.591 57.384L128.501 63.4859Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path d="M147.873 32.5932H128.501V32.5916H147.871L147.873 32.5932Z" fill="#D1D5DB" />
      <path
        d="M150.113 70.8184H145.726L165.877 50.6268C166.552 51.4468 166.791 52.5015 166.602 53.4935C166.48 54.1437 166.155 54.7774 165.648 55.2789L150.113 70.8184Z"
        fill="#9CA3AF"
      />
      <path
        d="M133.45 70.8184H128.501V64.6272H132.847C134.963 64.6272 136.679 62.9117 136.679 60.7962C136.679 59.7381 136.25 58.7804 135.556 58.0871C135.291 57.8221 134.988 57.5961 134.655 57.4176L143.577 48.4953C143.551 48.4826 143.525 48.47 143.499 48.4573L153.607 38.3293L159.746 44.4703L133.45 70.8184ZM134.447 45.2363C132.512 44.7867 130.527 44.4687 128.501 44.2924V38.8941L134.789 32.5933H147.064L134.447 45.2363Z"
        fill="#9CA3AF"
      />
      <path d="M128.501 38.8941V32.5933H134.789L128.501 38.8941Z" fill="#4F71E3" />
      <path d="M128.501 38.8941V32.5933H134.789L128.501 38.8941Z" fill="black" fillOpacity="0.2" />
      <path
        d="M143.499 48.4573C140.645 47.0609 137.613 45.9725 134.447 45.2363L147.064 32.5933H147.873L153.607 38.3293L143.499 48.4573Z"
        fill="#4F71E3"
      />
      <path
        d="M143.499 48.4573C140.645 47.0609 137.613 45.9725 134.447 45.2363L147.064 32.5933H147.873L153.607 38.3293L143.499 48.4573Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M145.726 70.8184H133.45L159.746 44.4703L165.648 50.3744C165.729 50.4555 165.806 50.5398 165.877 50.6268L145.726 70.8184Z"
        fill="#4F71E3"
      />
      <path
        d="M145.726 70.8184H133.45L159.746 44.4703L165.648 50.3744C165.729 50.4555 165.806 50.5398 165.877 50.6268L145.726 70.8184Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M132.847 64.6272H128.501V63.5719L134.655 57.4176C134.988 57.5961 135.291 57.8221 135.556 58.0871C136.25 58.7804 136.679 59.7381 136.679 60.7962C136.679 62.9117 134.963 64.6272 132.847 64.6272Z"
        fill="#9CA3AF"
      />
      <path
        d="M162.793 51.7399C162.793 52.4034 162.54 53.0675 162.034 53.5733L128.151 87.4566C127.14 88.4683 125.502 88.4683 124.49 87.4566L90.6072 53.5733C89.5955 52.5616 89.5955 50.9244 90.6072 49.9126L116.593 23.9327L124.49 16.0294C125.502 15.0176 127.14 15.0176 128.151 16.0294L162.034 49.9126C162.54 50.4185 162.793 51.082 162.793 51.7399Z"
        fill="#FDE047"
      />
      <path
        d="M162.793 51.7399C162.793 52.4034 162.54 53.0675 162.034 53.5733L128.151 87.4566C127.14 88.4683 125.502 88.4683 124.49 87.4566L118.029 80.9948L132.116 66.9014C139.696 59.3209 139.696 47.0361 132.116 39.4551L116.593 23.9327L124.49 16.0294C125.502 15.0176 127.14 15.0176 128.151 16.0294L162.034 49.9126C162.54 50.4185 162.793 51.082 162.793 51.7399Z"
        fill="#FEC400"
      />
      <path
        d="M123.601 56.1931L122.139 38.6174H131.066L129.565 56.1931H123.601ZM126.603 66.62C125.286 66.62 124.206 66.2119 123.364 65.3957C122.521 64.5794 122.1 63.6052 122.1 62.473C122.1 61.3144 122.521 60.3533 123.364 59.5897C124.206 58.7998 125.286 58.4049 126.603 58.4049C127.919 58.4049 128.985 58.7998 129.802 59.5897C130.644 60.3533 131.066 61.3144 131.066 62.473C131.066 63.6052 130.644 64.5794 129.802 65.3957C128.985 66.2119 127.919 66.62 126.603 66.62Z"
        fill="#1F2937"
      />
      <path
        d="M97.3495 52.8366C96.8067 52.8366 96.3669 52.3968 96.3669 51.854C96.3669 51.3112 96.8067 50.8715 97.3495 50.8715C97.8917 50.8715 98.332 51.3112 98.332 51.854C98.332 52.3968 97.8917 52.8366 97.3495 52.8366Z"
        fill="#1F2937"
      />
      <path
        d="M97.5468 52.8366C97.0046 52.8366 96.5648 52.3968 96.5648 51.854C96.5648 51.3112 97.0046 50.8715 97.5468 50.8715C98.0896 50.8715 98.5294 51.3112 98.5294 51.854C98.5294 52.3968 98.0896 52.8366 97.5468 52.8366Z"
        fill="#1F2937"
      />
      <path
        d="M155.312 50.8715C155.855 50.8715 156.295 51.3112 156.295 51.854C156.295 52.3968 155.855 52.8366 155.312 52.8366C154.77 52.8366 154.33 52.3968 154.33 51.854C154.33 51.3112 154.77 50.8715 155.312 50.8715Z"
        fill="#1F2937"
      />
      <path
        d="M155.115 50.8715C155.657 50.8715 156.097 51.3112 156.097 51.854C156.097 52.3968 155.657 52.8366 155.115 52.8366C154.572 52.8366 154.132 52.3968 154.132 51.854C154.132 51.3112 154.572 50.8715 155.115 50.8715Z"
        fill="#1F2937"
      />
      <path
        d="M126.321 19.4741C125.818 19.4741 125.346 19.6697 124.99 20.0252L94.6035 50.4119C93.87 51.1454 93.87 52.339 94.6035 53.073L124.99 83.4598C125.701 84.1706 126.941 84.1706 127.651 83.4598L158.039 53.073C158.394 52.717 158.59 52.2448 158.59 51.7416C158.59 51.2396 158.394 50.7674 158.039 50.4119L127.651 20.0252C127.296 19.6697 126.824 19.4741 126.321 19.4741ZM126.321 84.8854C125.585 84.8854 124.893 84.5983 124.372 84.0775L93.9852 53.6907C92.9112 52.6167 92.9112 50.8682 93.9852 49.7936L124.372 19.4069C125.413 18.3653 127.23 18.3653 128.27 19.4069L158.657 49.7936C159.177 50.3144 159.464 51.0065 159.464 51.7416C159.464 52.4784 159.177 53.17 158.657 53.6907L128.27 84.0775C127.749 84.5983 127.057 84.8854 126.321 84.8854Z"
        fill="#EFF3FF"
      />
      <path
        d="M234.547 119.12H203.181L205.534 110.032L207.517 102.323L209.176 95.9253L211.16 88.2209L215.988 69.5404H221.741L226.562 88.2209L228.551 95.9253L230.204 102.323L232.194 110.032L234.547 119.12Z"
        fill="#4F71E3"
      />
      <path
        d="M234.547 119.12H203.181L205.534 110.032L207.517 102.323L209.176 95.9253L211.16 88.2209L215.988 69.5404H221.741L226.562 88.2209L228.551 95.9253L230.204 102.323L232.194 110.032L234.547 119.12Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M228.55 95.9265H209.175L211.161 88.2198H226.565L228.55 95.9265Z" fill="#4F71E3" />
      <path
        d="M228.55 95.9265H209.175L211.161 88.2198H226.565L228.55 95.9265Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path d="M232.192 110.031H205.534L207.519 102.325H230.207L232.192 110.031Z" fill="#4F71E3" />
      <path
        d="M232.192 110.031H205.534L207.519 102.325H230.207L232.192 110.031Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path d="M196.414 125.598H241.313V119.083H196.414V125.598Z" fill="#4F71E3" />
      <path d="M196.414 125.598H241.313V119.083H196.414V125.598Z" fill="black" fillOpacity="0.2" />
      <path
        d="M241.313 125.598H220.927C220.569 123.374 218.641 121.682 216.317 121.682H196.414V119.083H219.787C219.784 119.094 219.781 119.109 219.778 119.12H234.547L234.538 119.083H241.313V125.598Z"
        fill="#4F71E3"
      />
      <path
        d="M241.313 125.598H220.927C220.569 123.374 218.641 121.682 216.317 121.682H196.414V119.083H219.787C219.784 119.094 219.781 119.109 219.778 119.12H234.547L234.538 119.083H241.313V125.598Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M234.538 119.083H219.786C220.479 115.943 220.987 112.923 221.342 110.031H232.192L231.107 105.819L232.194 110.032L234.538 119.083ZM230.205 102.325H221.96C222.043 100.094 222.036 97.9582 221.96 95.9265H228.55L227.942 93.5635L228.551 95.9254L230.204 102.323L230.205 102.325ZM226.562 88.2198H221.302C219.702 76.3262 215.987 69.5404 215.987 69.5404H221.741L226.562 88.2198Z"
        fill="#4F71E3"
      />
      <path
        d="M234.538 119.083H219.786C220.479 115.943 220.987 112.923 221.342 110.031H232.192L231.107 105.819L232.194 110.032L234.538 119.083ZM230.205 102.325H221.96C222.043 100.094 222.036 97.9582 221.96 95.9265H228.55L227.942 93.5635L228.551 95.9254L230.204 102.323L230.205 102.325ZM226.562 88.2198H221.302C219.702 76.3262 215.987 69.5404 215.987 69.5404H221.741L226.562 88.2198Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M228.55 95.9265H221.96C221.855 93.1634 221.62 90.5899 221.302 88.2198H226.562L226.562 88.2209L227.942 93.5635L228.55 95.9265Z"
        fill="#D1D5DB"
      />
      <path
        d="M232.192 110.031H221.342C221.672 107.348 221.87 104.78 221.96 102.325H230.205L231.107 105.819L232.192 110.031Z"
        fill="#D1D5DB"
      />
      <path
        d="M234.547 119.12H219.778C219.781 119.109 219.784 119.094 219.786 119.083H234.538L234.547 119.12Z"
        fill="#4F71E3"
      />
      <path
        d="M234.547 119.12H219.778C219.781 119.109 219.784 119.094 219.786 119.083H234.538L234.547 119.12Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M211.297 112.986C211.263 112.986 211.23 112.982 211.196 112.976C210.616 112.863 210.268 111.782 210.15 110.031H213.546C212.726 111.889 211.932 112.986 211.297 112.986ZM216.001 102.325H210.577C210.765 100.908 211.01 99.4075 211.31 97.859C211.437 97.2022 211.571 96.5569 211.71 95.9265H217.261C217.112 96.8941 216.938 97.8926 216.741 98.9121C216.512 100.092 216.263 101.235 216.001 102.325ZM217.947 88.2198H213.905C214.931 85.4783 215.965 83.7854 216.753 83.7854C216.788 83.7854 216.821 83.7882 216.855 83.7948C217.584 83.9364 217.947 85.61 217.947 88.2198Z"
        fill="#4F71E3"
      />
      <path
        d="M211.297 112.986C211.263 112.986 211.23 112.982 211.196 112.976C210.616 112.863 210.268 111.782 210.15 110.031H213.546C212.726 111.889 211.932 112.986 211.297 112.986ZM216.001 102.325H210.577C210.765 100.908 211.01 99.4075 211.31 97.859C211.437 97.2022 211.571 96.5569 211.71 95.9265H217.261C217.112 96.8941 216.938 97.8926 216.741 98.9121C216.512 100.092 216.263 101.235 216.001 102.325ZM217.947 88.2198H213.905C214.931 85.4783 215.965 83.7854 216.753 83.7854C216.788 83.7854 216.821 83.7882 216.855 83.7948C217.584 83.9364 217.947 85.61 217.947 88.2198Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M217.261 95.9265H211.71C212.364 92.9457 213.132 90.2852 213.905 88.2198H217.947C217.947 90.2962 217.717 92.9656 217.261 95.9265Z"
        fill="#EFF3FF"
      />
      <path
        d="M213.546 110.031H210.15C210.021 108.126 210.164 105.428 210.577 102.325H216.001C215.249 105.448 214.383 108.136 213.546 110.031Z"
        fill="#EFF3FF"
      />
      <path
        d="M53.821 119.12H22.4547L24.8077 110.032L26.7915 102.323L28.4503 95.9253L30.4339 88.2209L35.2613 69.5404H41.0146L45.8364 88.2209L47.8254 95.9253L49.4786 102.323L51.4679 110.032L53.821 119.12Z"
        fill="#4F71E3"
      />
      <path
        d="M53.821 119.12H22.4547L24.8077 110.032L26.7915 102.323L28.4503 95.9253L30.4339 88.2209L35.2613 69.5404H41.0146L45.8364 88.2209L47.8254 95.9253L49.4786 102.323L51.4679 110.032L53.821 119.12Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path d="M47.8243 95.9265H28.4495L30.435 88.2198H45.839L47.8243 95.9265Z" fill="#4F71E3" />
      <path
        d="M47.8243 95.9265H28.4495L30.435 88.2198H45.839L47.8243 95.9265Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path d="M51.4662 110.031H24.8079L26.7934 102.325H49.4808L51.4662 110.031Z" fill="#4F71E3" />
      <path
        d="M51.4662 110.031H24.8079L26.7934 102.325H49.4808L51.4662 110.031Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path d="M15.6874 125.598H60.587V119.083H15.6874V125.598Z" fill="#4F71E3" />
      <path d="M15.6874 125.598H60.587V119.083H15.6874V125.598Z" fill="black" fillOpacity="0.2" />
      <path
        d="M60.5869 125.598H40.2011C39.8429 123.374 37.9151 121.682 35.5914 121.682H15.6874V119.083H39.0607C39.0581 119.094 39.0551 119.109 39.0527 119.12H53.821L53.8116 119.083H60.5869V125.598Z"
        fill="#4F71E3"
      />
      <path
        d="M60.5869 125.598H40.2011C39.8429 123.374 37.9151 121.682 35.5914 121.682H15.6874V119.083H39.0607C39.0581 119.094 39.0551 119.109 39.0527 119.12H53.821L53.8116 119.083H60.5869V125.598Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M53.8116 119.083H39.0607C39.753 115.943 40.2612 112.923 40.6166 110.031H51.4663L50.3201 105.584L51.468 110.032L53.8116 119.083ZM49.4792 102.325H41.2346C41.3168 100.094 41.3106 97.9582 41.2337 95.9265H47.8243L47.216 93.5635L47.8254 95.9254L49.4786 102.323L49.4792 102.325ZM45.836 88.2198H40.5759C38.9765 76.3262 35.2614 69.5404 35.2614 69.5404H41.0146L45.836 88.2198Z"
        fill="#4F71E3"
      />
      <path
        d="M53.8116 119.083H39.0607C39.753 115.943 40.2612 112.923 40.6166 110.031H51.4663L50.3201 105.584L51.468 110.032L53.8116 119.083ZM49.4792 102.325H41.2346C41.3168 100.094 41.3106 97.9582 41.2337 95.9265H47.8243L47.216 93.5635L47.8254 95.9254L49.4786 102.323L49.4792 102.325ZM45.836 88.2198H40.5759C38.9765 76.3262 35.2614 69.5404 35.2614 69.5404H41.0146L45.836 88.2198Z"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M47.8243 95.9265H41.2337C41.1294 93.1634 40.8947 90.5899 40.5759 88.2198H45.836L45.8364 88.2209L47.216 93.5635L47.8243 95.9265Z"
        fill="#D1D5DB"
      />
      <path
        d="M51.4663 110.031H40.6166C40.9464 107.348 41.1439 104.78 41.2346 102.325H49.4792L50.3201 105.584L51.4663 110.031Z"
        fill="#D1D5DB"
      />
      <path
        d="M53.821 119.12H39.0527C39.0551 119.109 39.0581 119.094 39.0607 119.083H53.8117L53.821 119.12Z"
        fill="#4F71E3"
      />
      <path
        d="M53.821 119.12H39.0527C39.0551 119.109 39.0581 119.094 39.0607 119.083H53.8117L53.821 119.12Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M30.5715 112.986C30.537 112.986 30.5035 112.982 30.4701 112.976C29.89 112.863 29.5419 111.782 29.4237 110.031H32.8199C31.9995 111.889 31.2058 112.986 30.5715 112.986ZM35.2742 102.325H29.8512C30.0396 100.908 30.2839 99.4075 30.5844 97.859C30.7116 97.2022 30.8453 96.5569 30.9837 95.9265H36.5348C36.3854 96.8941 36.2121 97.8926 36.0143 98.9121C35.7855 100.092 35.5367 101.235 35.2742 102.325ZM37.2206 88.2198H33.1793C34.2048 85.4783 35.2389 83.7854 36.0272 83.7854C36.0617 83.7854 36.0952 83.7882 36.1286 83.7948C36.8579 83.9364 37.2206 85.61 37.2206 88.2198Z"
        fill="#4F71E3"
      />
      <path
        d="M30.5715 112.986C30.537 112.986 30.5035 112.982 30.4701 112.976C29.89 112.863 29.5419 111.782 29.4237 110.031H32.8199C31.9995 111.889 31.2058 112.986 30.5715 112.986ZM35.2742 102.325H29.8512C30.0396 100.908 30.2839 99.4075 30.5844 97.859C30.7116 97.2022 30.8453 96.5569 30.9837 95.9265H36.5348C36.3854 96.8941 36.2121 97.8926 36.0143 98.9121C35.7855 100.092 35.5367 101.235 35.2742 102.325ZM37.2206 88.2198H33.1793C34.2048 85.4783 35.2389 83.7854 36.0272 83.7854C36.0617 83.7854 36.0952 83.7882 36.1286 83.7948C36.8579 83.9364 37.2206 85.61 37.2206 88.2198Z"
        fill="white"
        fillOpacity="0.25"
      />
      <path
        d="M36.5348 95.9265H30.9836C31.6383 92.9457 32.4065 90.2852 33.1793 88.2198H37.2206C37.2208 90.2962 36.9913 92.9656 36.5348 95.9265Z"
        fill="#EFF3FF"
      />
      <path
        d="M32.8199 110.031H29.4238C29.295 108.126 29.4384 105.428 29.8513 102.325H35.2743C34.5222 105.448 33.657 108.136 32.8199 110.031Z"
        fill="#EFF3FF"
      />
    </svg>
  );
}

export default NotFoundIcon;
