import DASHBOARD_TYPES from "../types/dashboard";

const initState = {
  layouts: [],
  dashboardList: [],
  views: [],
};

const dashboard = (state: any = initState, action: any) => {
  switch (action.type) {
    case DASHBOARD_TYPES?.GET_LAYOUTS:
      return {
        ...state,
        layouts: [],
        dashboardList: [],
        views: [],
      };
    case DASHBOARD_TYPES?.SAVE_LAYOUTS:
      return {
        ...state,
        layouts: action.data?.layouts,
        dashboardList: action.data?.dashboardList,
        views: action.data?.views,
      };
    default:
      return { ...state };
  }
};

export default dashboard;
