import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentDelivered(props: any) {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <rect x={1} y={1} width={26} height={26} rx={9} fill={backgroundColor} />
      <path
        d="M11.334 17.25l-3.5-3.5-1.167 1.166 4.667 4.667 10-10-1.167-1.167-8.833 8.833z"
        fill={iconColor}
      />
      <rect x={1} y={1} width={26} height={26} rx={9} stroke={borderColor} strokeWidth={2} />
    </svg>
  );
}

export default ShipmentDelivered;
