import FILTER_TYPES from "../types/filter";

const initState: any = {
  products: null,
  origins: null,
  destination: null,
};

const filter = (state = initState, action: any) => {
  switch (action.type) {
    case FILTER_TYPES.CHANGE_MULTI_FILTERS:
      return {
        ...state,
        ...action?.payload,
      };

    default:
      return { ...state };
  }
};
export default filter;
