import SpinLoading from "components/Loading/SpinLoding";
import ShipmentEvents from "components/ShipmentEvents";
import WrapperSection from "components/WrapperSection";
import { ShipmentDetailsProps } from "interface/shipment";
import { memo } from "react";
import CarrierInformation from "./CarrierInformation";
import "./GeneralInformation.scss";
import GeneralInformationHeader from "./GeneralInformationHeader";
import ShipmentInformation from "./ShipmentInformation";
export interface ShipmentItemProps {
  shipmentItemData: ShipmentDetailsProps;
  isLoading?: boolean;
  tabActive?: string;
}

const GeneralInformation = ({
  shipmentItemData,
  isLoading = false,
  tabActive,
}: ShipmentItemProps) => {
  return (
    <WrapperSection
      type="shadow"
      className={`shipment_sm-general-info-container mb-[24px] mt-0 p-6
    ${tabActive === `overview` ? "flex" : "hidden"} tablet:flex rounded-[0px] tablet:rounded-[12px]
    `}
    >
      <div className="sm-tab">
        <div className="sm-item">
          <div>
            <GeneralInformationHeader {...{ shipmentItemData }} />
            {isLoading ? (
              <div className="sm-loading-general-info">
                <SpinLoading />
              </div>
            ) : (
              <>
                <ShipmentInformation {...{ shipmentItemData }} />
                <CarrierInformation {...{ shipmentItemData }} />
                {shipmentItemData?.events && (
                  <div className="sm-body p-0 mt-[32px]">
                    <ShipmentEvents shipmentItemData={shipmentItemData || {}} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </WrapperSection>
  );
};

export default memo(GeneralInformation);
