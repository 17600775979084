import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { decodeAes, isJson } from "scripts/helpers";
import variables from "scripts/variables";
import reducer from "./reducer";

const sagaMiddleware = createSagaMiddleware();
const composeSetup =
  variables?.nodeEnv !== "production" &&
  typeof window === "object" &&
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 0 })
    : compose;

const currentAuth: any = decodeAes(
  localStorage.getItem("currentAuth") || {},
  variables?.cryptoAesKey,
);
const currentUser: any = decodeAes(
  localStorage.getItem("currentUser") || {},
  variables?.cryptoAesKey,
);
const currentLanguage: any = localStorage.getItem("currentLanguage") || "en";

const sessionStorageData: any = Object.keys(sessionStorage).reduce(function (obj, key) {
  const sessionItemValue: any = sessionStorage.getItem(key);
  obj[key] = isJson(sessionItemValue) ? JSON.parse(sessionItemValue) : {};
  return obj;
}, {});
delete sessionStorageData.sessionHistory;

const currentMultiFilters: any = sessionStorageData || {};

const initialState: any = {
  auth: {
    currentAuth: isJson(currentAuth) ? JSON.parse(currentAuth) : {},
    currentUser: isJson(currentUser) ? JSON.parse(currentUser) : {},
  },
  app: { currentLanguage },
  dashboard: {},
  filter: currentMultiFilters,
};

const store = createStore(reducer, initialState, composeSetup(applyMiddleware(sagaMiddleware)));
export default store;
