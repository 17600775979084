import * as React from "react";

const Enterprise = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9999 1.00001C15.8462 0.998135 15.6943 1.03212 15.5561 1.09926C15.4179 1.1664 15.2972 1.26485 15.2038 1.38678C15.1103 1.50871 15.0465 1.65077 15.0175 1.80166C14.9886 1.95256 14.9952 2.10813 15.0369 2.25601L11.2559 6.03702C11.0888 5.98768 10.911 5.98768 10.7439 6.03702L8.46288 3.75601C8.50447 3.6074 8.5111 3.45118 8.48225 3.29958C8.4534 3.14797 8.38986 3.0051 8.29659 2.88215C8.20333 2.7592 8.08287 2.6595 7.94465 2.59087C7.80643 2.52223 7.6542 2.48651 7.49988 2.48651C7.34556 2.48651 7.19333 2.52223 7.05511 2.59087C6.91689 2.6595 6.79643 2.7592 6.70317 2.88215C6.6099 3.0051 6.54636 3.14797 6.51751 3.29958C6.48866 3.45118 6.49529 3.6074 6.53688 3.75601L3.75588 6.53702C3.5395 6.47684 3.30929 6.49003 3.1012 6.5745C2.8931 6.65898 2.71884 6.80999 2.60563 7.00396C2.49242 7.19793 2.44664 7.42392 2.47543 7.64666C2.50422 7.86939 2.60596 8.07632 2.76477 8.23513C2.92358 8.39394 3.1305 8.49568 3.35324 8.52447C3.57597 8.55326 3.80197 8.50747 3.99594 8.39426C4.1899 8.28105 4.34092 8.10679 4.42539 7.8987C4.50987 7.69061 4.52305 7.46039 4.46288 7.24402L7.24388 4.46301C7.41098 4.51235 7.58878 4.51235 7.75588 4.46301L10.0369 6.74402C9.99529 6.89263 9.98866 7.04885 10.0175 7.20045C10.0464 7.35206 10.1099 7.49493 10.2032 7.61788C10.2964 7.74083 10.4169 7.84052 10.5551 7.90916C10.6933 7.9778 10.8456 8.01352 10.9999 8.01352C11.1542 8.01352 11.3064 7.9778 11.4447 7.90916C11.5829 7.84052 11.7033 7.74083 11.7966 7.61788C11.8899 7.49493 11.9534 7.35206 11.9823 7.20045C12.0111 7.04885 12.0045 6.89263 11.9629 6.74402L15.7439 2.96301C15.8819 3.0016 16.0267 3.01006 16.1683 2.9878C16.3099 2.96555 16.4451 2.91311 16.5647 2.83403C16.6842 2.75496 16.7854 2.6511 16.8613 2.5295C16.9373 2.4079 16.9861 2.27139 17.0047 2.12924C17.0232 1.98709 17.011 1.84262 16.9688 1.70561C16.9266 1.56861 16.8554 1.44227 16.7601 1.33518C16.6648 1.22808 16.5476 1.14273 16.4165 1.0849C16.2853 1.02707 16.1432 0.99812 15.9999 1.00001Z"
      fill="currentColor"
    />
    <path
      d="M25.5 12V20.5H28.5V3.5H27C26.8674 3.5 26.7402 3.44732 26.6464 3.35355C26.5527 3.25979 26.5 3.13261 26.5 3V1.5H21.5V3C21.5 3.13261 21.4473 3.25979 21.3536 3.35355C21.2598 3.44732 21.1326 3.5 21 3.5H19.5V11.5H25C25.1326 11.5 25.2598 11.5527 25.3536 11.6464C25.4473 11.7402 25.5 11.8674 25.5 12Z"
      fill="currentColor"
    />
    <path
      d="M29.5 30.5V21.5H23V30.5H22V21C22 20.8674 22.0527 20.7402 22.1464 20.6465C22.2402 20.5527 22.3674 20.5 22.5 20.5H24.5V12.5H15.5V21.5H17.5C17.6326 21.5 17.7598 21.5527 17.8536 21.6465C17.9473 21.7402 18 21.8674 18 22V30.5H17V22.5H9V30.5H8V22C8 21.8674 8.05268 21.7402 8.14645 21.6465C8.24021 21.5527 8.36739 21.5 8.5 21.5H12.5V12.5H2.5V30.5H0.5V31.5H31.5V30.5H29.5ZM24 23.5H28.5V24.5H24V23.5ZM24 25.5H28.5V26.5H24V25.5ZM24 27.5H28.5V28.5H24V27.5ZM8.5 14H10.5V16H8.5V14ZM8.5 18H10.5V20H8.5V18ZM4.5 14H6.5V16H4.5V14ZM4.5 18H6.5V20H4.5V18ZM4.5 22H6.5V24H4.5V22ZM4.5 26H6.5V28H4.5V26Z"
      fill="currentColor"
    />
  </svg>
);

export default Enterprise;
