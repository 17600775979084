import React from "react";
import { useTranslation } from "react-i18next";
import { sourceWrapper } from "scripts/helpers";
import loading from "../../assets/icons/loading.svg";
const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="loading">
      <img src={sourceWrapper(loading)} alt={`${t("Loading")}...`} />
    </div>
  );
};

export default Loading;
