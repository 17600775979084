import { Modal } from "console-system-components";
import moment from "moment-with-locales-es6";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_TO_DISPLAY } from "scripts/constants";
import { emptyFunction, randomUniqueKey } from "scripts/helpers";
import "./TimeRangeDetailModal.scss";

const TimeRangeDetailModal = ({
  visible = false,
  setVisible = emptyFunction,
  data = [],
  code = "",
  dateTimeRange,
}: any) => {
  const { t } = useTranslation();

  const DISPLAY_LABEL = {
    RECEIVED: { title: t("Received"), label: t("Received Date") },
    COMMITTED: { title: t("Committed"), label: t("Committed Date") },
  };

  return (
    <Modal
      visible={visible}
      title={
        <>
          <div className="sm_title_t1_semi text-gray800 mb-[2px]">
            {DISPLAY_LABEL?.[code]?.title}
          </div>
          <div className="sm_body_b1_reg text-gray500">{dateTimeRange}</div>
        </>
      }
      className="time-range-event-modal hide-footer-modal"
      width={640}
      onCancel={() => setVisible(false)}
      cancelText={t("Cancel")}
      okText={t("Apply")}
      centered
    >
      <div className="flex flex-col max-h-[500px] overflow-y-auto shipment-date-time-list">
        {data?.map((it: any) => (
          <div
            className="flex justify-between gap-6 py-2 border border-transparent border-solid border-b-[1px] border-b-gray200 last:border-none last:pb-0"
            key={randomUniqueKey()}
          >
            <div className="flex-1">
              <div className="label">{t("Container #")}</div>
              <div className="value">{it?.container}</div>
            </div>
            <div className="min-w-[115px]">
              <div className="label">{DISPLAY_LABEL?.[code]?.label}</div>
              <div className="value">
                {moment.utc((it?.date + it?.time) * 1000)?.format(DATE_FORMAT_TO_DISPLAY)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TimeRangeDetailModal;
