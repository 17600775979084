import Image from "components/Image";
import { ReactNode } from "react";

interface IColumInfo {
  label: string | ReactNode;
  value: string | ReactNode;
  icon?: string;
}
const ColumInfo = ({ label, value, icon }: IColumInfo) => {
  return (
    <div className="sm-colum-info flex flex-col flex-1">
      <div className="sm-colum-info-label  sm_body_b3_reg text-gray-500">{label}</div>
      <div className="sm-colum-info-value sm_body_b2_semi text-gray-800 mt-1 pr-2 flex items-center">
        {value}
        {icon && (
          <span className="inline whitespace-nowrap">
            &nbsp;
            <Image
              type="favicon"
              src={icon}
              className="w-4 h-4 relative bottom-[1px] object-contain aspect-square"
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default ColumInfo;
