import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { getEventDateTime } from "scripts/helpers";

const EventDetailsTooltip = ({ children, data, eventTime }: any) => {
  const { t } = useTranslation();

  const { datetime: receivedTime } = getEventDateTime(data?.receivedTime);

  return (
    <Tooltip
      overlayClassName="sm-tooltip-event-details"
      autoAdjustOverflow={true}
      placement="left"
      overlayStyle={{ width: 400, maxWidth: "unset" }}
      title={
        <>
          <div className="p-2 bg-gray600 rounded-t-[4px] flex gap-2">
            {data?.code && (
              <div className="w-fit bg-gray500 h-5 rounded-[4px] text-[#ffffff] px-1 text-center">
                <span className="sm_body_b2_semi">{data?.code}</span>
              </div>
            )}
            <div className="sm_body_b2_semi text-[#ffffff]">{data?.message}</div>
          </div>
          <div className="event-detail-content p-2 bg-[#00000066] grid grid-cols-2 gap-2 rounded-b-[4px] overflow-auto max-h-[250px]">
            {data?.containerNumber && (
              <div>
                <div className="label">{t("Container #")}</div>
                <div className="value">{data?.containerNumber}</div>
              </div>
            )}
            {data?.location?.location && (
              <div className={`${!data?.containerNumber ? "col-span-2" : ""}`}>
                <div className="label">{t("Location")}</div>
                <div className="value">{data?.location?.location}</div>
              </div>
            )}
            {eventTime && (
              <div>
                <div className="label">{t("Event Time")}</div>
                <div className="value whitespace-nowrap">{eventTime}</div>
              </div>
            )}
            {receivedTime && (
              <div>
                <div className="label">{t("Received Time")}</div>
                <div className="value whitespace-nowrap">{receivedTime}</div>
              </div>
            )}
          </div>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

export default EventDetailsTooltip;
