import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentInTransit(props: any) {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <rect x={1} y={1} width={26} height={26} rx={9} fill={backgroundColor} />
      <g clipPath="url(#prefix__clip0_9422_133175)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.606 21.959v-7.388L24 10.879v7.106a.48.48 0 01-.245.424l-6.149 3.55zm-7.69-3.672h-5.16a.757.757 0 010-1.513h5.132c.888 0 .888-1.417 0-1.417H6.805a.757.757 0 010-1.514h2.412c.887 0 .887-1.417 0-1.417h-4.46a.757.757 0 010-1.513H9.94l6.336 3.658v7.388l-6.36-3.672zm.627-8.559l6.398 3.694 6.398-3.694-6.386-3.687h-4.19a.757.757 0 000 1.513h.886c.081 0 .15.053.17.131a.174.174 0 01-.082.199l-3.194 1.844zm-.365-3.687a.757.757 0 110 1.513H5.58a.757.757 0 010-1.513h4.6z"
          fill={iconColor}
        />
      </g>
      <rect x={1} y={1} width={26} height={26} rx={9} stroke={borderColor} strokeWidth={2} />
      <defs>
        <clipPath id="prefix__clip0_9422_133175">
          <path fill="#fff" transform="translate(4 4)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShipmentInTransit
