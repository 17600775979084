import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentReceived(props: any) {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <rect x={1} y={1} width={26} height={26} rx={9} fill={backgroundColor} />
      <path
        d="M18.97 9.587l-1.174-1.175-5.284 5.283 1.175 1.175 5.284-5.283zm3.534-1.175l-8.817 8.817-3.483-3.475-1.175 1.175 4.659 4.658 10-10-1.184-1.175zM4.312 14.93l4.659 4.658 1.175-1.175-4.65-4.658-1.184 1.175z"
        fill={iconColor}
      />
      <rect x={1} y={1} width={26} height={26} rx={9} stroke={borderColor} strokeWidth={2} />
    </svg>
  );
}

export default ShipmentReceived;
