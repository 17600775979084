import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentReceivedNoSquare(props: any) {
  const { backgroundColor }: any = getShipmentIconColor(props?.status);
  return (
    <svg width={16} height={17} fill="none" {...props}>
      <path
        d="M11.977 4.481l-.94-.94L6.81 7.768l.94.94 4.227-4.227zm2.826-.94L7.75 10.594l-2.787-2.78-.94.94 3.727 3.727 8-8-.947-.94zM.25 8.754l3.727 3.727.94-.94-3.72-3.727-.947.94z"
        fill={backgroundColor}
      />
    </svg>
  );
}

export default ShipmentReceivedNoSquare;
