import APP_TYPES from "../types/app";

export const showLoading = () => ({
  type: APP_TYPES.SHOW_LOADING,
});

export const hideLoading = () => ({
  type: APP_TYPES.HIDE_LOADING,
});

export const showNotification = (message: string) => ({
  type: APP_TYPES.SHOW_NOTIFICATION,
  message,
});

export const hideNotification = () => ({
  type: APP_TYPES.HIDE_NOTIFICATION,
});

export const setPageInfo = (payload: any) => ({
  type: APP_TYPES?.SET_PAGE_INFO,
  payload,
});

export const changeLanguage = (payload: any) => ({
  type: APP_TYPES?.CHANGE_LANGUAGE,
  payload,
});

export const saveEventMasterData = (payload: any) => ({
  type: APP_TYPES?.SAVE_EVENTS_MASTER_DATA,
  payload,
});
