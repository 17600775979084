import { SHIPMENT_API_URL } from "scripts/constants";
import baseApi from "./baseApi";

export const getFilterList = async () => {
  const { data } = await baseApi(`${SHIPMENT_API_URL}/all-filters`, "GET");
  return data;
};

export const getEventMasterData = async () => {
  const { data } = await baseApi(`/master-data/events`, "GET");
  return data;
};
