import USER_TYPES from "../types/user";

export interface IState {
  changePasswordSuccess: boolean;
  callForgotPasswordSuccess: boolean;
  resetPassword: {
    errorCode?: number;
    errorMessage?: string;
    wrongTooManyTimes?: boolean;
  };
}
const initState: IState = {
  changePasswordSuccess: false,
  callForgotPasswordSuccess: false,
  resetPassword: {
    errorCode: 0,
    errorMessage: "",
    wrongTooManyTimes: false,
  },
};

const user = (state: any = initState, action: any) => {
  switch (action.type) {
    case USER_TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        callForgotPasswordSuccess: true,
      };
    case USER_TYPES.RETSET_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccess: true,
      };
    default:
      return { ...state };
  }
};
export default user;
