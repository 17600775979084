import { Steps, Tooltip } from "antd";
import EstimateDateTimeModal from "components/EstimateDateTimeModal";
import Info from "components/svgs/Info";
import LogoPS from "components/svgs/LogoPS";
import { EventDateTimeProps, ExceptionProps } from "interface/general";
import {
  ShipmentDetailsProps,
  ShipmentEventLineProps,
  ShipmentEventProps,
  ShipmentStatusTimeLineProps,
} from "interface/shipment";
import { findLast, isEmpty } from "lodash";
import moment from "moment-with-locales-es6";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  SHIPMENT_STATUS,
  SHIPMENT_STATUS_EVENTS,
  SHIPMENT_STATUS_EVENTS_ORDER,
  STATUS_EXCEPTIONS_DESCRIPTION,
} from "scripts/constants";
import {
  getEventDateTime,
  getGeneralShipmentStatus,
  getShipmentEventIcon,
  getStatusAndDeliveryType,
  renderTimeRangeEventNode,
} from "scripts/helpers";
import DeliveredNodeModal from "./DeliveredNodeModal";

import useWindowDimensions from "hooks/useWindowDimensions";
import "./ShipmentEvents.scss";
import TimeRangeDetailModal from "components/TimeRangeDetailModal";
import InTransitNodeModal from "./InTransitNodeModal";
const { Step } = Steps;

const initIssue = {
  code: "",
  name: "",
  status: "",
  description: "",
};

const NON_ACTUAL_EVENTS = [
  SHIPMENT_STATUS_EVENTS?.EST_SHIPPED,
  SHIPMENT_STATUS_EVENTS?.ESTDELIVERED,
  SHIPMENT_STATUS_EVENTS?.PASCAL_ESTDELIVERED,
  SHIPMENT_STATUS_EVENTS?.COMMITTED,
];

export interface ShipmentItemProps {
  shipmentItemData: ShipmentDetailsProps;
}

const ShipmentEventsV2 = ({ shipmentItemData }: ShipmentItemProps) => {
  const { t } = useTranslation();
  const data: ShipmentDetailsProps = shipmentItemData;
  const [selectedEvent, setSelectedEvent] = useState<EventDateTimeProps[]>([]);
  const [isOpenEstimatedEventModal, setIsOpenEstimatedEventModal] = useState(false);
  const [isOpenDeliveredEventModal, setIsOpenDeliveredEventModal] = useState(false);
  const [isOpenInTransitEventModal, setIsOpenInTransitEventModal] = useState(false);
  const [statusPopupDelivered, setStatusPopupDelivered] = useState("");
  const [datePopupDelivered, setDatePopupDelivered] = useState({});
  const [selectedNode, setSelectedNode] = useState<ShipmentEventLineProps>();
  const [isOpenTimeRangeModal, setIsOpenTimeRangeModal] = useState(false);
  /**
   * Note: Don't delete below comment
   */
  // const SHIPMENT_STATUS_TIMELINE: ShipmentStatusTimeLineProps[] = [
  //   { code: SHIPMENT_STATUS_EVENTS?.BOOKED, label: t("Booked") },
  //   { code: SHIPMENT_STATUS_EVENTS?.EST_SHIPPED, label: t("Est. Shipped") },
  //   { code: SHIPMENT_STATUS_EVENTS?.INTRANSIT, label: t("In-transit") },
  //   { code: SHIPMENT_STATUS_EVENTS?.AT_PORT, label: t("At Port") },
  //   { code: SHIPMENT_STATUS_EVENTS?.ESTDELIVERED, label: t("Est. Delivered") },
  //   { code: SHIPMENT_STATUS_EVENTS?.PASCAL_ESTDELIVERED, label: t("Est. Delivered") },
  //   { code: SHIPMENT_STATUS_EVENTS?.DELIVERED, label: t("Delivered") },
  //   { code: SHIPMENT_STATUS_EVENTS?.RECEIVED, label: t("Received") },
  //   { code: SHIPMENT_STATUS_EVENTS?.COMMITTED, label: t("Committed") },
  // ].map((event: ShipmentStatusTimeLineProps, index: number) => ({ ...event, index: index + 1 }));

  // CA-1389 change position event
  const SHIPMENT_STATUS_TIMELINE: ShipmentStatusTimeLineProps[] = [
    { index: 1, code: SHIPMENT_STATUS_EVENTS?.BOOKED, label: t("Booked") },
    { index: 2, code: SHIPMENT_STATUS_EVENTS?.EST_SHIPPED, label: t("Est. Shipped") },
    { index: 2, code: SHIPMENT_STATUS_EVENTS?.INTRANSIT, label: t("In-transit") },
    { index: 3, code: SHIPMENT_STATUS_EVENTS?.ESTDELIVERED, label: t("Est. Delivered") },
    { index: 3, code: SHIPMENT_STATUS_EVENTS?.PASCAL_ESTDELIVERED, label: t("Est. Delivered") },
    { index: 4, code: SHIPMENT_STATUS_EVENTS?.DELIVERED, label: t("Delivered") },
    { index: 4, code: SHIPMENT_STATUS_EVENTS?.COMMITTED, label: t("Committed") },
    { index: 4, code: SHIPMENT_STATUS_EVENTS?.RECEIVED, label: t("Received") },
  ];

  const {
    events,
    estimatedDateTimeList,
    status,
    committedDate,
    committedTimeRange,
    receivedDate,
    receivedTimeRange,
    bookedTime,
    pascalEstimatedDeliveryDate,
    statusExceptions,
    deliveryDateTime,
    isReceivedOnTime,
    firstEstimatedDateTime,
    lastEstimatedDateTime,
    plannedShipDate,
    estimatedDateTime,
    actualShipDate,
    committedTimeList,
    receivedTimeList,
  } = data;
  const isLogistic = location?.pathname?.includes("logistic");

  const { status: shipmentStatus, deliveryType } = getStatusAndDeliveryType(
    shipmentItemData as ShipmentDetailsProps,
  );

  const isDelivered =
    SHIPMENT_STATUS_EVENTS_ORDER[status?.code] > SHIPMENT_STATUS_EVENTS_ORDER.INTRANSIT;

  const isReceived = isLogistic
    ? SHIPMENT_STATUS_EVENTS_ORDER?.[status?.code] == SHIPMENT_STATUS_EVENTS_ORDER?.RECEIVED
    : SHIPMENT_STATUS_EVENTS_ORDER?.[status?.code] >= SHIPMENT_STATUS_EVENTS_ORDER?.DELIVERED;

  const getEventByCode = (events: ShipmentEventProps[], code: string) =>
    events?.find((el: ShipmentEventProps) => el?.status?.code === code && el?.eventDateTime?.date);

  const handleStepInTransit = (
    actualShipDate: EventDateTimeProps | undefined,
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    return {
      eventDateTime: actualShipDate,
      ...milestone,
      passed: true,
    };
  };

  const handleStepDelivered = (
    dateTime: EventDateTimeProps,
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    const isDeliveredLateStep =
      milestone?.code === SHIPMENT_STATUS_EVENTS?.DELIVERED && data?.deliveryType === "LATE";
    const event = getEventByCode(events, SHIPMENT_STATUS_EVENTS.DELIVERED);
    return {
      ...event,
      ...milestone,
      eventDateTime: {
        ...dateTime,
      },
      passed: true,
      ...(isDeliveredLateStep && {
        label: t("Delivered Late"),
        exception: { ...initIssue },
      }),
    };
  };

  const handleEstimatedData = (
    estimatedDateTime: EventDateTimeProps | undefined,
    estimatedDateTimeList: EventDateTimeProps[],
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    if (!isEmpty(estimatedDateTime))
      return {
        ...milestone,
        eventDateTime: {
          ...estimatedDateTime,
        },
        estimatedTimes: [...estimatedDateTimeList],
        passed: moment
          .utc((estimatedDateTime?.date + estimatedDateTime?.time ?? 0) * 1000)
          ?.isBefore(moment().utc()),
      };
  };

  const handleReceivedEvent = (
    dateTime: EventDateTimeProps,
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    const isReceivedLateStep = milestone?.code === "RECEIVED" && isReceivedOnTime === false;
    const isPassed =
      status.code === SHIPMENT_STATUS_EVENTS.RECEIVED && dateTime?.date
        ? moment.utc((dateTime?.date + dateTime?.time) * 1000)?.isBefore(moment().utc())
        : false;

    return {
      ...milestone,
      eventDateTime: status.code === SHIPMENT_STATUS_EVENTS.RECEIVED && {
        ...dateTime,
      },
      passed: isPassed,
      ...(isReceivedLateStep &&
        isPassed && {
          label: t("Received Late"),
          exception: { ...initIssue },
        }),
      timeRange: receivedTimeRange ? receivedTimeRange : null,
      timeList: receivedTimeList ? receivedTimeList : null,
    };
  };

  const handleCommittedEvent = (
    dateTime: EventDateTimeProps,
    receivedDate: EventDateTimeProps,
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    const eventStatus = isReceived
      ? moment.utc((receivedDate?.date + receivedDate?.time) * 1000)?.isBefore(moment().utc())
      : moment.utc((dateTime?.date + dateTime?.time) * 1000)?.isBefore(moment().utc());
    return {
      ...milestone,
      eventDateTime: {
        ...dateTime,
      },
      passed: eventStatus,
      timeRange: committedTimeRange ? committedTimeRange : null,
      timeList: committedTimeList ? committedTimeList : null,
    };
  };

  const handleBookEvent = (
    bookedTime: EventDateTimeProps,
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    return {
      ...milestone,
      eventDateTime: {
        ...bookedTime,
      },
      passed: true,
    };
  };

  const handleEstShipEvent = (
    plannedShipDate: EventDateTimeProps,
    milestone: ShipmentStatusTimeLineProps,
  ) => {
    if (plannedShipDate?.date)
      return {
        ...milestone,
        eventDateTime: {
          ...plannedShipDate,
        },
        passed: true,
      };
    return null;
  };

  const handlePascalEstimated = (
    dateTime: EventDateTimeProps,
    milestone: ShipmentStatusTimeLineProps,
    // timezone: any,
  ) => {
    if (dateTime)
      return {
        ...milestone,
        eventDateTime: {
          date: dateTime?.date,
          time: dateTime?.time,
          // timezone,
        },
        passed: moment.utc((dateTime?.date + dateTime?.time) * 1000)?.isBefore(moment().utc()),
      };
    return null;
  };

  const sortStepsWithTime = (steps: ShipmentEventLineProps[]) => {
    steps = steps?.sort((a: ShipmentEventProps, b: ShipmentEventProps) => {
      if (a.index === 1) return -1;
      if (b.index === 1) return 1;
      if (a?.index == b?.index)
        return (
          a.eventDateTime?.date +
          a.eventDateTime?.time -
          (b.eventDateTime?.date + b.eventDateTime?.time)
        );
      return a?.index - b?.index;
    });
    return steps;
  };
  /**
   * Don't remove this code
   */
  // const sortStepsWithTime = (steps: ShipmentEventLineProps[]) => {
  //   steps = steps?.sort((a: ShipmentEventProps, b: ShipmentEventProps) => {
  //     if (a.index === 1) return -1;
  //     if (b.index === 1) return 1;
  //     if (a.eventDateTime?.date === b.eventDateTime?.date) return a?.index - b?.index;
  //     return a.eventDateTime?.date - b.eventDateTime?.date;
  //   });

  //   //move received step to the last position if it doesn't have datetime.

  //   const receivedEventIdx = steps.findIndex(
  //     (ev: ShipmentEventLineProps) => ev.code === SHIPMENT_STATUS_EVENTS?.RECEIVED,
  //   );
  //   if (receivedEventIdx !== -1 && !steps[receivedEventIdx]?.eventDateTime?.date) {
  //     steps.push(steps.splice(receivedEventIdx, 1)[0]);
  //   }
  //   return steps;
  // };

  const steps: ShipmentEventLineProps[] = [];

  SHIPMENT_STATUS_TIMELINE?.forEach((item: ShipmentStatusTimeLineProps) => {
    let stepData: any = null;
    const exception = findLast(statusExceptions, (it: ExceptionProps) => it?.status === item?.code);
    switch (item?.code) {
      case SHIPMENT_STATUS_EVENTS?.BOOKED:
        stepData = handleBookEvent(bookedTime, item);
        break;
      case SHIPMENT_STATUS_EVENTS?.EST_SHIPPED:
        stepData =
          status?.code === SHIPMENT_STATUS_EVENTS?.BOOKED &&
          handleEstShipEvent(plannedShipDate, item);
        break;

      case SHIPMENT_STATUS_EVENTS?.INTRANSIT:
        stepData =
          status?.code !== SHIPMENT_STATUS_EVENTS?.BOOKED &&
          handleStepInTransit(actualShipDate, item);
        break;
      // case SHIPMENT_STATUS_EVENTS?.AT_PORT:
      //   stepData = !isDelivered && handleStepAtPort(data, item);
      //   break;
      case SHIPMENT_STATUS_EVENTS?.ESTDELIVERED:
        stepData =
          !isDelivered && handleEstimatedData(estimatedDateTime, estimatedDateTimeList, item);
        break;
      case SHIPMENT_STATUS_EVENTS?.PASCAL_ESTDELIVERED:
        stepData = !isDelivered
          ? handlePascalEstimated(pascalEstimatedDeliveryDate ?? 0, item)
          : null;
        break;
      case SHIPMENT_STATUS_EVENTS?.DELIVERED:
        stepData = isDelivered && handleStepDelivered(deliveryDateTime ?? [], item);
        break;
      case SHIPMENT_STATUS_EVENTS?.RECEIVED:
        stepData = isReceived && handleReceivedEvent(receivedDate ?? 0, item);
        break;
      case SHIPMENT_STATUS_EVENTS?.COMMITTED:
        stepData = committedDate && handleCommittedEvent(committedDate ?? 0, receivedDate, item);
        break;
    }
    if (!isEmpty(stepData))
      steps?.push({
        ...stepData,
        ...(!isEmpty(exception) &&
          item?.code !== SHIPMENT_STATUS_EVENTS?.DELIVERED && { exception: exception }),
      });
  });

  let stepsSorted = sortStepsWithTime(steps);

  stepsSorted = stepsSorted?.map((it: ShipmentEventLineProps, index: number) => {
    const unixFirstTime =
      it?.timeRange && !isEmpty(it?.timeRange)
        ? (it?.timeRange?.from?.date) * 1000
        : (it?.eventDateTime?.date) * 1000;
    const unixSecondTime =
      steps[index + 1]?.timeRange && !isEmpty(steps[index + 1]?.timeRange)
        ? steps[index + 1]?.timeRange
          ? steps[index + 1]?.timeRange?.from?.date * 1000
          : steps[index + 1]?.eventDateTime?.date * 1000
        : steps[index + 1]?.eventDateTime?.date * 1000;

    const firstDay = moment?.utc(unixFirstTime);
    const secondDay = moment?.utc(unixSecondTime);

    const dayBetween =
      it?.eventDateTime?.date + it?.eventDateTime?.time &&
      stepsSorted[index + 1]?.eventDateTime?.date
        ? secondDay?.diff(firstDay, "days")
        : -1;

    const passed = NON_ACTUAL_EVENTS?.includes(stepsSorted?.[index]?.code ?? "")
      ? false
      : it?.passed;

    return {
      ...it,
      passed: passed,
      dateBetween: steps?.[index + 1]
        ? dayBetween < 0 || isNaN(dayBetween)
          ? null
          : dayBetween
        : null,
    };
  });
  const { width } = useWindowDimensions();
  return (
    <>
      <Steps
        current={1}
        className="sm-steps shipment-item__events-wrapper items-center tablet:items-start"
        direction={width && width <= 800 ? "vertical" : "horizontal"}
      >
        {stepsSorted?.map((event: ShipmentEventLineProps, index: number) => {
          const { date, datetime } = getEventDateTime(event?.eventDateTime);
          const isLastEvent = stepsSorted?.length - 1 === index;
          const isHasIssue = !isEmpty(event?.exception);
          const showError = isHasIssue && !isEmpty(event?.exception?.description);
          const showEstimatedTimes = event?.estimatedTimes?.length > 1;
          const isPascalEstimated = event?.code === SHIPMENT_STATUS_EVENTS?.PASCAL_ESTDELIVERED;
          const isPassedStep = stepsSorted?.[index + 1]?.passed;

          const stepIcon = getShipmentEventIcon(
            event?.code,
            event?.passed ? (!isHasIssue ? "success" : "failure") : "",
          );
          const isLineError = !isEmpty(stepsSorted?.[index + 1]?.exception);
          return (
            <Step
              key={index}
              className={`shipment-item__events-item ${
                !isPassedStep ? `dashed` : !isLineError ? "success" : "failure"
              }`}
              icon={
                showError || showEstimatedTimes ? (
                  <Tooltip
                    autoAdjustOverflow={true}
                    overlayClassName={`min-w-[340px] shipment-tooltip ${
                      event?.estimatedTimes?.length > 8
                        ? "left-tooltip overflow-y-auto"
                        : isLastEvent
                        ? `last-tooltip`
                        : ""
                    }`}
                    placement={
                      event?.estimatedTimes?.length > 8 ? "left" : isLastEvent ? `topRight` : `top`
                    }
                    title={
                      !event?.estimatedTimes?.length ? (
                        <div className="p-2">
                          {
                            STATUS_EXCEPTIONS_DESCRIPTION(t)[
                              event?.exception?.subCode
                                ? event?.exception?.subCode
                                : (event?.exception?.code as string)
                            ]
                          }
                        </div>
                      ) : null
                    }
                  >
                    {stepIcon}
                  </Tooltip>
                ) : (
                  stepIcon
                )
              }
              description={
                <>
                  {event?.dateBetween != null && (
                    <div className="shipment-item__events-item--day-between-container">
                      {event?.dateBetween == 0 ? (
                        <span className="day-between">{t("Same day")}</span>
                      ) : event?.dateBetween > 1 ? (
                        <span className="day-between">
                          <Trans
                            defaults="{{number}} days"
                            values={{ number: event?.dateBetween }}
                          />
                        </span>
                      ) : (
                        <span className="day-between">
                          <Trans
                            defaults="{{number}} day"
                            values={{ number: event?.dateBetween }}
                          />
                        </span>
                      )}
                    </div>
                  )}
                  <div className="shipment-item__events-item--info">
                    <span className="sm_body_b1_semi sm-step-name cc whitespace-nowrap">
                      {event?.label} {isPascalEstimated && <LogoPS className="ml-1 w-4 h-4" />}
                      {showEstimatedTimes ? `(${event?.estimatedTimes?.length})` : ""}
                      {showEstimatedTimes && (
                        <Tooltip
                          autoAdjustOverflow={true}
                          overlayClassName={`min-w-[340px] shipment-tooltip ${
                            event?.estimatedTimes?.length > 8
                              ? "left-tooltip overflow-y-auto"
                              : isLastEvent
                              ? `last-tooltip`
                              : ""
                          }`}
                          placement={
                            event?.estimatedTimes?.length > 8
                              ? "left"
                              : isLastEvent
                              ? `topRight`
                              : `top`
                          }
                          title={null}
                        >
                          <Info
                            className={`text-gray400 hover:text-blue500 ${
                              event?.estimatedTimes?.length ? "cursor-pointer" : ""
                            } min-w-[16px] h-[16px] ml-[5px]`}
                            onClick={() => {
                              if (event?.estimatedTimes?.length) {
                                setSelectedEvent(event?.estimatedTimes);
                                setIsOpenEstimatedEventModal(true);
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                      {event?.code === SHIPMENT_STATUS.INTRANSIT &&
                        actualShipDate &&
                        plannedShipDate && (
                          <Info
                            className={`${
                              showError ? "text-red500" : "text-gray400 hover:text-blue500"
                            } cursor-pointer min-w-[16px] h-[16px] ml-[5px]`}
                            onClick={() => {
                              setIsOpenInTransitEventModal(true);
                            }}
                          />
                        )}
                      {event?.code === SHIPMENT_STATUS.DELIVERED &&
                        deliveryDateTime &&
                        (pascalEstimatedDeliveryDate ||
                          (estimatedDateTimeList && estimatedDateTimeList?.length > 0)) && (
                          <Info
                            className={`${
                              showError ? "text-red500" : "text-gray400 hover:text-blue500"
                            } cursor-pointer min-w-[16px] h-[16px] ml-[5px]`}
                            onClick={() => {
                              setStatusPopupDelivered(
                                getGeneralShipmentStatus(shipmentStatus, deliveryType),
                              );
                              setDatePopupDelivered(deliveryDateTime);
                              setIsOpenDeliveredEventModal(true);
                            }}
                          />
                        )}
                      {event?.timeRange && !isEmpty(event?.timeRange) && (
                        <Info
                          className={`${
                            showError ? "text-red500" : "text-gray400 hover:text-blue500"
                          } cursor-pointer min-w-[16px] h-[16px] ml-[5px]`}
                          onClick={() => {
                            setSelectedNode(event);
                            setIsOpenTimeRangeModal(true);
                          }}
                        />
                      )}
                    </span>
                    {date && (
                      <span className={`sm-step-desc sm_body_b2_reg mt-[4px]`}>
                        {event?.timeRange && !isEmpty(event?.timeRange) ? (
                          <Tooltip
                            autoAdjustOverflow={true}
                            overlayClassName={`min-w-max ${isLastEvent ? `last-tooltip` : ""}`}
                            placement={index === 0 ? "topLeft" : isLastEvent ? `topRight` : `top`}
                            title={renderTimeRangeEventNode(
                              event?.eventDateTime,
                              event?.timeRange,
                              true,
                            )}
                          >
                            {renderTimeRangeEventNode(event?.eventDateTime, event?.timeRange)}
                          </Tooltip>
                        ) : (
                          <Tooltip autoAdjustOverflow={true} title={datetime}>
                            {date}
                          </Tooltip>
                        )}
                        {/* {date} {offsetStr} */}
                      </span>
                    )}
                  </div>
                </>
              }
            />
          );
        })}
      </Steps>
      <EstimateDateTimeModal
        visible={isOpenEstimatedEventModal}
        setVisible={(visible: boolean) => setIsOpenEstimatedEventModal(visible)}
        data={selectedEvent}
      />
      <DeliveredNodeModal
        {...{
          statusPopupDelivered,
          isOpenDeliveredEventModal,
          datePopupDelivered,
          setIsOpenDeliveredEventModal,
          pascalEstimatedDeliveryDate,
          firstEstimatedDateTime,
          lastEstimatedDateTime,
          estimatedDateTimeList,
        }}
      />
      <InTransitNodeModal
        {...{
          isOpenInTransitEventModal,
          actualShipDate,
          setIsOpenInTransitEventModal,
          plannedShipDate,
        }}
      />
      <TimeRangeDetailModal
        visible={isOpenTimeRangeModal}
        data={selectedNode?.timeList}
        dateTimeRange={renderTimeRangeEventNode(
          selectedNode?.eventDateTime,
          selectedNode?.timeRange,
        )}
        code={selectedNode?.code}
        {...{ setVisible: setIsOpenTimeRangeModal }}
      />
    </>
  );
};

export default ShipmentEventsV2;
