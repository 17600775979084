import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Collapse, Divider, Space, Tooltip } from "antd";
import React, { ReactNode, useState } from "react";
// import { FaDollarSign } from "react-icons/fa";
// import { Container } from "types/shipmentDetail";
// import ContainerDataList from "./ContainerDataList";
import CopyButton from "components/Button/CopyButton";
import DataExceptionTooltip from "components/DataExceptionTooltip";
import Input from "components/Input";
import SpinLoading from "components/Loading/SpinLoding";
import WrapperSection from "components/WrapperSection";
import CheckCircle from "components/svgs/CheckCircle";
import NoData from "components/svgs/NoData";
import { Modal } from "console-system-components";
import { ContainerProps } from "interface/container";
import { cloneDeep, compact, isEmpty } from "lodash";
import moment from "moment-with-locales-es6";
import { Trans, useTranslation } from "react-i18next";
import {
  DATA_EXCEPTION_MESSAGE,
  DATE_TIME_FORMAT_TO_DISPLAY,
  DEFAULT_VALUES,
} from "scripts/constants";
import { convertToDisplay, emptyFunction } from "scripts/helpers";
import ContainerDataList from "./ContainerDataList";
import "./ContainerList.scss";

const { Panel } = Collapse;
let delaySearchTimer: any = null;

interface ContainerListViewProps {
  containerList: ContainerProps[];
  loading?: boolean;
  isSearchLoading?: boolean;
  onSearchProduct?: any;
  collapseActiveKeys?: any;
  setCollapseActiveKeys?: any;
  tabActive?: string;
  shipmentDetails?: any;
}

const ContainerListView: React.FC<ContainerListViewProps> = ({
  containerList = [],
  loading = false,
  isSearchLoading = false,
  onSearchProduct = emptyFunction,
  collapseActiveKeys = [],
  setCollapseActiveKeys = emptyFunction,
  tabActive,
  // shipmentDetails = {},
}) => {
  const { t } = useTranslation();
  const [visibleModalTracking, setVisibleModalTracking] = useState(false);
  const [containerSelected, setContainerSelected]: any = useState({});
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(t(`Search by product SKU`) as string);
  const [searchText, setSearchText] = useState(``);

  const onSearch = (e: any) => {
    clearTimeout(delaySearchTimer);
    if (e?.target?.value?.trim() === "") {
      onSearchProduct("");
      setSearchText(``);
    } else {
      if (e?.target?.value?.trim()?.length < 3) return;
      delaySearchTimer = setTimeout(() => {
        onSearchProduct(e?.target?.value);
        setSearchText(e?.target?.value);
      }, 1000);
    }
  };

  return (
    <WrapperSection
      type="shadow"
      className={`sm-detail-container-list h-auto
    ${
      tabActive === `freight` ? "flex mt-0" : "hidden"
    } tablet:flex rounded-[0px] tablet:rounded-[12px]
    
    `}
    >
      {(() => {
        return (
          <>
            <Modal
              width={480}
              visible={visibleModalTracking}
              onCancel={() => {
                setVisibleModalTracking(false);
                setContainerSelected({});
              }}
              centered
              title={`${t("Tracking")} # (${containerSelected?.trackingIds?.length})`}
              className="container-ids-modal"
            >
              <div>
                {containerSelected?.trackingIds?.map((it: string) => (
                  <div
                    key={JSON.stringify(it)}
                    className="flex w-full gap-2 py-2 items-center border border-transparent border-solid border-b border-b-gray200"
                  >
                    <div className="sm_body_b1_reg py-1">{it}</div>
                    <CopyButton message={it} />
                  </div>
                ))}
              </div>
            </Modal>
            <div className="sm-header">
              <div className="sm-header-search-wrapper">
                <div className="title sm_sub_title_semi">
                  {loading ? (
                    t("Freight")
                  ) : (
                    <Trans
                      defaults="Freight ({{totalItems}})"
                      values={{
                        totalItems: +containerList?.length || 0,
                      }}
                    />
                  )}
                </div>
                {!loading && (
                  <div className="shipment-search relative  hidden">
                    <Input
                      className="sm-custom-search-input"
                      placeholder={searchPlaceHolder}
                      allowClear
                      onChange={onSearch}
                      onFocus={() => {
                        setSearchPlaceHolder(t(`Min 3 characters`));
                      }}
                      onBlur={() => {
                        setSearchPlaceHolder(t(`Search by product SKU`));
                      }}
                    />
                    <div
                      className={`search-icon bg-blue400 text-white mr-[-2px] rounded-r border-blue400`}
                    >
                      {isSearchLoading ? (
                        <LoadingOutlined className="px-3 py-[9px] w-full" rev={undefined} />
                      ) : (
                        <SearchOutlined
                          onClick={() => searchText?.length > 2 && onSearchProduct(searchText)}
                          className={`px-3 py-[9px] w-full ${
                            searchText?.length > 2 ? "cursor-pointer" : "cursor-not-allowed"
                          }`}
                          rev={undefined}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Divider style={{ margin: "16px 0" }} />
            {loading ? (
              <SpinLoading />
            ) : (
              <>
                {isEmpty(containerList) ? (
                  <div className="w-full flex justify-center mt-6">
                    <NoData />
                  </div>
                ) : (
                  <Space className="sm-space-vertical " direction="vertical">
                    {containerList?.map((container: any, index: number) => {
                      const isHaveException = !isEmpty(container?.dataExceptions);
                      const dataException = cloneDeep(container?.dataExceptions);
                      const isAbleToMapExceptionCode = dataException?.some(
                        (it: any) => DATA_EXCEPTION_MESSAGE[`${it?.exception}`],
                      );

                      const eventDate = +(container?.actualReceiptDate?.date || 0);
                      const eventTime = +(container?.actualReceiptDate?.time || 0);

                      return (
                        <Collapse
                          className="sm-collapse cursor-default"
                          onChange={(e: any) => {
                            return false;
                            const newCollapseActiveKeys: any = collapseActiveKeys;
                            newCollapseActiveKeys[index] = isEmpty(e) ? "uncollapse" : e?.[1];
                            setCollapseActiveKeys(compact(newCollapseActiveKeys));
                          }}
                          activeKey={[collapseActiveKeys?.[index]]}
                          key={container?.containerNumber}
                          expandIcon={() => (
                            <div className="relative top-[-35px]">
                              <Tooltip
                                autoAdjustOverflow
                                placement="top"
                                title={
                                  container?.isReceived
                                    ? `${t("Received")} ${
                                        eventDate
                                          ? `: ${moment
                                              .unix(eventDate + eventTime)
                                              .utc()
                                              .format(DATE_TIME_FORMAT_TO_DISPLAY)}`
                                          : ``
                                      }`
                                    : t("Not Received")
                                }
                              >
                                <CheckCircle
                                  className={`${
                                    container?.isReceived ? "text-primary500" : "text-gray400"
                                  }`}
                                  rotate={0}
                                />
                              </Tooltip>
                            </div>
                          )}
                        >
                          <Panel
                            key={`panel-${container?.containerNumber}`}
                            header={
                              <div className="flex flex-col gap-y-[10px] w-full cursor-default grid grid-cols-2 tablet:grid-cols-3">
                                <ContainerInfo
                                  title={
                                    <>
                                      <span>{t("Container #")}</span>
                                      <span>
                                        {isHaveException && isAbleToMapExceptionCode && (
                                          <DataExceptionTooltip dataExceptions={dataException} />
                                        )}
                                      </span>
                                    </>
                                  }
                                  value={
                                    <div className="container-id-wrapper">
                                      <div className="sub-title sm_body_b2_semi">
                                        {container?.containerNumber}
                                      </div>
                                      <CopyButton message={container?.containerNumber} />
                                    </div>
                                  }
                                />
                                <ContainerInfo
                                  title={<span>{t("Tracking #")}</span>}
                                  value={
                                    <div className="container-id-wrapper">
                                      <div
                                        className="sub-title sm_body_b2_semi"
                                        onClick={(ev: any) => {
                                          ev.stopPropagation();
                                          if ((container?.trackingIds?.length as number) > 1) {
                                            setVisibleModalTracking(true);
                                          }
                                          setContainerSelected(container);
                                        }}
                                      >
                                        {container?.trackingIds?.[0]}
                                      </div>
                                      {(container?.trackingIds?.length as number) === 1 ? (
                                        <CopyButton message={container?.trackingIds?.[0]} />
                                      ) : (
                                        <div
                                          className="sm_body_b3_reg text-gray800 bg-gray100 rounded-[4px] px-1 py-2 h-6 cursor-pointer hover:bg-gray200 flex items-center"
                                          onClick={(ev: any) => {
                                            ev.stopPropagation();
                                            setVisibleModalTracking(true);
                                            setContainerSelected(container);
                                          }}
                                        >
                                          +{(container?.trackingIds?.length as number) - 1}
                                        </div>
                                      )}
                                    </div>
                                  }
                                />
                                <ContainerInfo
                                  title={t("Container Type")}
                                  value={container?.containerType}
                                />

                                <ContainerInfo
                                  title={t("Gross Weight")}
                                  value={
                                    container?.grossWeight &&
                                    `${convertToDisplay(container?.grossWeight)} ${(
                                      container?.shipmentWeightUnitCode ??
                                      container?.containerWeightUnit
                                    )?.toLowerCase()}`
                                  }
                                />
                                <ContainerInfo
                                  title={t("Products")}
                                  value={convertToDisplay(container?.products)}
                                />
                                <ContainerInfo
                                  title={t("Units")}
                                  value={convertToDisplay(container?.units)}
                                />
                              </div>
                            }
                          >
                            <ContainerDataList products={container?.products ?? []} />
                          </Panel>
                        </Collapse>
                      );
                    })}
                  </Space>
                )}
              </>
            )}
          </>
        );
      })()}
    </WrapperSection>
  );
};

const ContainerInfo = ({ title = "", value }: { title: ReactNode; value: ReactNode }) => {
  return (
    <div className="header-item flex flex-col flex-1">
      <div className="title sm_body_b3_reg">{title}</div>
      <div className="sub-title sm_body_b2_semi">{value || DEFAULT_VALUES?.noData}</div>
    </div>
  );
};

export default ContainerListView;
