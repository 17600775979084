import { getEventMasterData } from "apis/general";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getRedux } from "scripts/helpers";
import { saveEventMasterData } from "store/actions/app";
import ActivitiesListView from "./ActivitiesListView";

const ActivitiesListContainer = ({
  shipmentDetails,
  isLoading,
  isOpenActivities,
  setIsOpenActivities,
  tabActive,
}: any) => {
  const dispatch = useDispatch();

  const eventMasterData = getRedux("app.eventMasterData", []);

  const { isFetching } = useQuery(`getEventsMasterData`, getEventMasterData, {
    onSuccess: (data: any[]) => dispatch(saveEventMasterData(data)),
    enabled: !eventMasterData?.length,
  });

  return (
    <ActivitiesListView
      shipmentDetails={shipmentDetails}
      isLoading={isLoading || isFetching}
      {...{ isOpenActivities, setIsOpenActivities, tabActive, eventMasterData }}
    />
  );
};

export default ActivitiesListContainer;
