const variables = {
  local: {
    nodeEnv: "local",
    apiUrl: "http://localhost:3000",
    cryptoAesKey: "58re97235srasdf24sdfaa2fe03ec434c0a911182",
    loginUrl: "http://localhost:6970",
    skyviewsUrl: "https://s3.ap-southeast-1.amazonaws.com/dev-dev.skyviews.console.pascalsoftware.com",
  },
  development: {
    nodeEnv: "development",
    apiUrl: "https://dev-sgp-api.console.pascalsoftware.com",
    cryptoAesKey: "58re97235srasdf24sdfaa2fe03ec434c0a911182",
    loginUrl: "https://dev-sgp.console.pascalsoftware.com",
    skyviewsUrl: "https://s3.ap-southeast-1.amazonaws.com/dev-sgp.skyviews.console.pascalsoftware.com",
  },
  stage: {
    nodeEnv: "stage",
    apiUrl: "https://stage-sgp-api.console.pascalsoftware.com",
    cryptoAesKey: "58re97235srasdf24sdfaa2fe03ec434c0a911182",
    loginUrl: "https://stage-sgp.console.pascalsoftware.com",
    skyviewsUrl: "https://s3.ap-southeast-1.amazonaws.com/stage-sgp.skyviews.console.pascalsoftware.com",
  },
  production: {
    nodeEnv: "production",
    apiUrl: "https://api.console.pascalsoftware.com",
    cryptoAesKey: "58re97235ddda2fe03ec434c0a911182",
    loginUrl: "https://console.pascalsoftware.com",
    skyviewsUrl: "https://s3.ap-southeast-1.amazonaws.com/skyviews.console.pascalsoftware.com",
  },
};
export default process?.env?.REACT_APP_ENVIRONMENT
  ? variables[process?.env?.REACT_APP_ENVIRONMENT]
  : {};
