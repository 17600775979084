import { SHIPMENT_API_URL } from "scripts/constants";
import { parseRequestParams } from "scripts/helpers";
import { RequestDataProps } from "interface/general";
import baseApi from "./baseApi";

export const getPublicShipmentDetails = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/get-shipment-public-detail`,
    "POST",
    parseRequestParams(reqData),
    // {},
    // shipmentDetailsMock,
  );
  return data;
};

export const getPublicLogisticShipmentDetails = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/logistic/get-shipment-public-detail`,
    "POST",
    parseRequestParams(reqData),
    // {},
    // shipmentDetailsMock,
  );
  return data;
};

export const getShipmentList = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/shipments`,
    "POST",
    parseRequestParams(reqData),
  );
  return data;
};

export const getTotalShipments = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/total-shipment-count`,
    "POST",
    parseRequestParams(reqData),
  );
  return data;
};

export const getTotalUnassignedShipments = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/unassigned-shipments/count`,
    "POST",
    parseRequestParams(reqData),
  );
  return data;
};

export const getShipmentDetails = async (reqData: RequestDataProps) => {
  // THIS API IS WRONG, NEED TO UPDATE FROM BACKEND
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/shipment?stid=${reqData?.stid}`,
    "GET",
    parseRequestParams(reqData),
  );
  return data;
};

export const updateFollowStatus = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/shipment/follow`,
    "POST",
    parseRequestParams(reqData),
  );
  return data;
};

export const exportShipments = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/shipment/export-on-time`,
    "POST",
    parseRequestParams(reqData),
  );
  return data;
};

export const assignCarrier = async (reqData: any) => {
  const { data } = await baseApi(`/data-integration/generate-properties-shipment`, "POST", reqData);
  return data;
};

export const searchShipment = async (reqData: RequestDataProps) => {
  const { data } = await baseApi(
    `${SHIPMENT_API_URL}/search-shipment?q=${encodeURIComponent(reqData?.searchText ?? ``)}`,
    "GET",
  );
  return data;
};

// Get filter data

/*------ Get products filter -----*/
export const getProductFamiliesFilter = async () => {
  const { data } = await baseApi(`/master-data/product-families`, "GET");
  return data;
};

export const getProductCategoriesFilter = async (reqData: any) => {
  const { data } = await baseApi(`/master-data/product-categories`, "POST", reqData);
  return data;
};

export const getProductsFilter = async (reqData: any) => {
  const { data } = await baseApi(`/master-data/products`, "POST", reqData);
  return data;
};

export const getProductSKUsFilter = async (reqData: any) => {
  const { data } = await baseApi(`/master-data/product-skus`, "POST", reqData);
  return data;
};

/*------ Get destination filter -----*/

export const getDestinationRegionsFilter = async () => {
  const { data } = await baseApi(`/master-data/destination-regions`, "GET");
  return data;
};

export const getDestinationCountriesFilter = async (reqData: any) => {
  const { data } = await baseApi(`/master-data/destination-countries`, "POST", reqData);
  return data;
};

export const getDestinationSitesFilter = async (reqData: any) => {
  const { data } = await baseApi(`/master-data/destination-sites`, "POST", reqData);
  return data;
};

/*------ Get origin filter -----*/

export const getOriginRegionsFilter = async () => {
  const { data } = await baseApi(`/master-data/origin-regions`, "GET");
  return data;
};

export const getOriginCountriesFilter = async (reqData: any) => {
  const { data } = await baseApi(`/master-data/origin-countries`, "POST", reqData);
  return data;
};
