import * as React from "react";

const GroupUser = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.3808 17.0825H24.906C25.1583 17.7732 25.2961 18.5185 25.2961 19.2953V28.6484C25.2961 28.9723 25.2397 29.2832 25.1371 29.5723H29.2284C30.7567 29.5723 32.0001 28.3289 32.0001 26.8007V21.7019C32.0001 19.1548 29.9279 17.0825 27.3808 17.0825Z"
      fill="currentColor"
    />
    <path
      d="M6.70408 19.2953C6.70408 18.5185 6.84189 17.7732 7.09414 17.0826H4.61938C2.07225 17.0826 0 19.1548 0 21.702V26.8008C0 28.3291 1.24331 29.5724 2.77163 29.5724H6.86301C6.76045 29.2832 6.70408 28.9724 6.70408 28.6485V19.2953Z"
      fill="currentColor"
    />
    <path
      d="M18.8288 14.676H13.1711C10.624 14.676 8.55176 16.7482 8.55176 19.2953V28.6485C8.55176 29.1587 8.96538 29.5724 9.47563 29.5724H22.5243C23.0345 29.5724 23.4481 29.1587 23.4481 28.6485V19.2953C23.4481 16.7482 21.3759 14.676 18.8288 14.676Z"
      fill="currentColor"
    />
    <path
      d="M16 2.42751C12.9367 2.42751 10.4446 4.91964 10.4446 7.98295C10.4446 10.0608 11.5914 11.8755 13.285 12.8282C14.0883 13.2801 15.0145 13.5383 16 13.5383C16.9855 13.5383 17.9116 13.2801 18.7149 12.8282C20.4086 11.8755 21.5553 10.0607 21.5553 7.98295C21.5553 4.9197 19.0632 2.42751 16 2.42751Z"
      fill="currentColor"
    />
    <path
      d="M6.24478 7.60558C3.95384 7.60558 2.09009 9.46933 2.09009 11.7603C2.09009 14.0512 3.95384 15.915 6.24478 15.915C6.82591 15.915 7.37929 15.7946 7.88197 15.5782C8.7511 15.204 9.46773 14.5417 9.91117 13.712C10.2224 13.1297 10.3995 12.4653 10.3995 11.7603C10.3995 9.46939 8.53573 7.60558 6.24478 7.60558Z"
      fill="currentColor"
    />
    <path
      d="M25.7553 7.60558C23.4643 7.60558 21.6006 9.46933 21.6006 11.7603C21.6006 12.4654 21.7776 13.1297 22.0889 13.712C22.5323 14.5417 23.249 15.2041 24.1181 15.5782C24.6208 15.7946 25.1742 15.915 25.7553 15.915C28.0462 15.915 29.91 14.0512 29.91 11.7603C29.91 9.46933 28.0462 7.60558 25.7553 7.60558Z"
      fill="currentColor"
    />
  </svg>
);

export default GroupUser;
