const CheckCircle = (props: any) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 1.333A6.67 6.67 0 0 0 1.335 8a6.67 6.67 0 0 0 6.667 6.666A6.669 6.669 0 0 0 14.667 8a6.669 6.669 0 0 0-6.666-6.667Zm-1.333 10L3.334 8l.94-.94 2.393 2.386 5.06-5.06.94.947-6 6Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckCircle;
