import NotFound from "pages/NotFound";
import ShipmentDetails from "pages/ShipmentDetails";
import ShipmentLogisticDetails from "pages/ShipmentLogisticDetails";
import { userRoles } from "scripts/constants";

const { ADMINISTRATOR, EDITOR, CONTRIBUTOR, AUTHOR } = userRoles;
const ALL = [ADMINISTRATOR, EDITOR, CONTRIBUTOR, AUTHOR];

export const PUBLIC_ROUTES = [
  {
    path: "/shipment/:shipmentPublicKey",
    component: ShipmentDetails,
    role: ALL,
  },
  {
    path: "/shipment/:shipmentPublicKey/:organizationPublicKey",
    component: ShipmentDetails,
    role: ALL,
  },
  {
    path: "/logistic/:shipmentPublicKey/:organizationPublicKey",
    component: ShipmentLogisticDetails,
    role: ALL,
  },
  {
    path: "/logistic/:shipmentPublicKey",
    component: ShipmentLogisticDetails,
    role: ALL,
  },
  /* ================================================================= */
  {
    path: "/page-not-found",
    component: NotFound,
    role: ALL,
  },
];

export const PRIVATE_ROUTES = [];
