import REGISTRATION_TYPES from "../types/registration";

const initState = {
  kpiList: [],
};

const registration = (state: any = initState, action: any) => {
  switch (action.type) {
    case REGISTRATION_TYPES?.GET_KPI:
      return {
        ...state,
        kpiList: [],
      };
    case REGISTRATION_TYPES?.SAVE_KPI:
      return {
        ...state,
        kpiList: action.data,
      };

    case REGISTRATION_TYPES?.ADD_DEVICE:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default registration;
