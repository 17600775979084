import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentBooked(props: any) {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <rect x={1} y={1} width={26} height={26} rx={9} fill={backgroundColor} />
      <path
        d="M19.729 7.75h-1.563V6.056a.392.392 0 00-.39-.391h-1.302a.392.392 0 00-.391.39V7.75h-4.167V6.057a.392.392 0 00-.39-.391h-1.302a.392.392 0 00-.391.39V7.75H8.271c-.863 0-1.563.7-1.563 1.563V20.77c0 .863.7 1.563 1.563 1.563h11.458c.863 0 1.562-.7 1.562-1.563V9.312c0-.863-.7-1.563-1.562-1.563zm-.195 13.02H8.466a.196.196 0 01-.195-.195v-9.7h11.458v9.7a.196.196 0 01-.195.195z"
        fill={iconColor}
      />
      <rect x={1} y={1} width={26} height={26} rx={9} stroke={borderColor} strokeWidth={2} />
    </svg>
  );
}

export default ShipmentBooked;
