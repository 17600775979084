import { Table } from "antd";
import Empty from "components/Empty";
import { ContainerProductProps } from "interface/container";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToDisplay } from "scripts/helpers";

interface ContainerDataListProps {
  products: ContainerProductProps[];
}

const ContainerDataList: React.FC<ContainerDataListProps> = ({ products = [] }) => {
  const [_selectedRow, setSelectedRow] = useState<ContainerProductProps>();
  const [_modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation();

  if (isEmpty(products)) {
    return <Empty message="Inventory data is not available." />;
  }

  return (
    <Table
      className="sm-detail-container-list-table"
      onRow={(record) => {
        return {
          onClick: () => {
            setSelectedRow(record);
            setModalVisible(true);
          },
        };
      }}
      columns={[
        {
          title: "",
          className: "p-0",
          width: 8,
        },
        {
          title: t("SKU #"),
          dataIndex: "sku",
          key: "sku",
          className: "model",
          render: (text) => {
            return (
              <div className="content-wrapper">
                <div className="title sm_body_b3_reg">{t("SKU #")}</div>
                <div className="sm_body_b2_reg sub-title">{text}</div>
              </div>
            );
          },
          width: "20%",
        },
        {
          title: t("SKU Description"),
          dataIndex: "name",
          key: "name",
          render: (_, record) => {
            return (
              <div className="content-wrapper name">
                <div className="title sm_body_b3_reg">
                  {record?.material ? t("Material") : t("SKU Description")}
                </div>
                <div className="sm_body_b2_reg sub-title">
                  {record?.material ? record?.material : record?.name}
                </div>
              </div>
            );
          },
          width: "30%",
        },
        {
          title: "Qty",
          dataIndex: "qty",
          key: "qty",
          render: (_, record) => {
            return (
              <div className="content-wrapper">
                <div className="title sm_body_b3_reg">{t("Qty")}</div>
                <div className="sm_body_b2_reg sub-tile">{convertToDisplay(record.qty)}</div>
              </div>
            );
          },
        },
        {
          title: t("Unit Price"),
          dataIndex: "unitPrice",
          key: "unitPrice",
          render: (text) => {
            return (
              <div className="content-wrapper">
                <div className="title sm_body_b3_reg">{t("Unit Price")}</div>
                <div className="sm_body_b2_reg sub-title">{convertToDisplay(text, "money")}</div>
              </div>
            );
          },
        },
        {
          title: t("Total Price"),
          dataIndex: "totalPrice",
          key: "totalPrice",
          render: (_, record) => {
            return (
              <div className="price flex flex-col items-end">
                <div className="title sm_body_b3_reg text-right">&#8205;{t("Total Price")}</div>
                <div className="sm_body_b2_reg sub-title text-right">
                  {convertToDisplay(record.unitPrice, "money")}
                </div>
              </div>
            );
          },
        },
      ]}
      pagination={false}
      showHeader={false}
      dataSource={products}
      rowKey="name"
    />
  );
};

export default ContainerDataList;
