import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pageContainerId } from "scripts/constants";

const ScrollToTop = (props: any) => {
  const location = useLocation();
  useEffect(() => {
    const container = document.getElementById(pageContainerId);
    if (container?.parentElement) {
      container.parentElement.scrollTop = 0;
    }
  }, [location?.pathname]);

  return <>{props.children}</>;
};

export default ScrollToTop;
