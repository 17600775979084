import { Steps } from "antd";
import Image from "components/Image";
import Info from "components/svgs/Info";
import { EventDateTimeProps, EventMasterData } from "interface/general";
import { ShipmentEventLineProps, ShipmentEventProps } from "interface/shipment";
import { isEqual } from "lodash";
import { SHIPMENT_EVENTS_ORDER } from "scripts/constants";
import {
  emptyFunction,
  getEventDateTime,
  renderActivitiesStepIcon,
  translateEvent,
} from "scripts/helpers";
import "./ActivitiesList.scss";
import EventDetailsTooltip from "./EventDetailsTooltip";

const { Step } = Steps;

const ShipmentStep = ({
  dataList: events = [],
  isGroupByLocation = true,
  isLastGroup = false,
  maxLengthOfCode = 0,
  X2list = [],
  language = "en",
  eventMasterData,
  B6Events = [],
  C1Events = [],
  onShowMoreInfo = emptyFunction,
}: {
  onShowMoreInfo?: (eventCode: any) => void;
  dataList: ShipmentEventProps[];
  isGroupByLocation?: boolean;
  isLastGroup?: boolean;
  maxLengthOfCode?: number;
  X2list: EventDateTimeProps[];
  B6Events: EventDateTimeProps[];
  C1Events: EventDateTimeProps[];
  language?: string;
  eventMasterData: EventMasterData[];
  setIsOpenEstimatedEventModal?: (visible: boolean) => void;
}) => {
  const sortEvents = (events: ShipmentEventLineProps[]) => {
    const eventsSorted = [...events]?.sort(
      (firstEvent: ShipmentEventProps, secondEvent: ShipmentEventProps) => {
        const firstEventDateTime = firstEvent.eventDateTime?.date + firstEvent.eventDateTime?.time;
        const secondEventDateTime =
          secondEvent.eventDateTime?.date + secondEvent.eventDateTime?.time;
        const isSameTime = firstEventDateTime === secondEventDateTime;

        // Sort events by the event time
        if (!isSameTime) return secondEventDateTime - firstEventDateTime;

        // Sort by index from https://pascalstudio.atlassian.net/wiki/spaces/CON/pages/4553179152/Shipment+business+rules#Shipment-Events, if two events have the same time
        if (secondEvent?.index && firstEvent?.index && secondEvent?.index !== firstEvent?.index)
          return secondEvent?.index - firstEvent?.index;

        // Sort by order if these events (PAP, PAT, PDT, PDP) have the same time and location.
        const isSameLocation = isEqual(firstEvent?.location, secondEvent?.location);
        if (isSameLocation && secondEvent?.subIndex && firstEvent?.subIndex)
          return secondEvent?.subIndex - firstEvent?.subIndex;

        return (
          secondEvent?.receivedTime?.date +
          secondEvent?.receivedTime?.time -
          (firstEvent?.receivedTime?.date + firstEvent?.receivedTime?.time)
        );
      },
    );

    return eventsSorted;
  };

  const mappingEventsData = (events: ShipmentEventProps[]) => {
    const newEvents = [...events]?.map((it: ShipmentEventProps, index: number) => {
      return {
        ...it,
        index: it?.code ? SHIPMENT_EVENTS_ORDER[it?.code]?.index : events?.length + index,
        subIndex: it?.code ? SHIPMENT_EVENTS_ORDER[it?.code]?.subIndex : 0,
      };
    });

    return newEvents;
  };

  events = sortEvents(mappingEventsData([...events])) ?? [];

  return (
    <Steps current={-1} direction="vertical">
      {events?.map((activity: ShipmentEventProps, index: number) => {
        const { datetime } = getEventDateTime(activity?.eventDateTime);

        return (
          <Step
            key={index}
            icon={
              <div
                className={`${
                  maxLengthOfCode === 4
                    ? "min-w-[45px]"
                    : maxLengthOfCode === 3
                    ? "min-w-[37px]"
                    : "min-w-[32px]"
                } hover:cursor-default`}
              >
                {renderActivitiesStepIcon(
                  activity?.eventCodeType as string,
                  activity?.code as string,
                )}
              </div>
            }
            className={`sm-step sm-big-step ${
              activity?.eventCodeType !== "normal" ? "" : "un-highlight-title"
            } ${
              (isLastGroup && index === events?.length - 1) || isGroupByLocation ? "un-tail" : ""
            }`}
            title={
              <StepTitle
                {...{
                  data: activity,
                  language,
                  eventMasterData,
                  onShowMore: () => onShowMoreInfo(activity?.code ?? ""),
                  showInfoIcon:
                    (X2list?.length > 1 && activity?.code === "X2") ||
                    (B6Events?.length > 1 && activity?.code === "B6") ||
                    (C1Events?.length > 1 && activity?.code === "C1"),
                }}
              />
            }
            description={
              <div>
                {datetime && <div>{datetime}</div>}
                {!isGroupByLocation && activity?.location?.location && (
                  <div>{activity?.location?.location}</div>
                )}
              </div>
            }
          />
        );
      })}
    </Steps>
  );
};

const StepTitle = ({ data = {}, language, eventMasterData, showInfoIcon, onShowMore }: any) => {
  let activityTitle = translateEvent({
    language,
    event: data,
    eventMasterData,
  });

  const showEventContainerNumber = data?.showEventContainerNumber && data?.containerNumber;
  if (showEventContainerNumber) {
    activityTitle += ` - ${data?.containerNumber}`;
  }
  const renderRightIcon = () => {
    return (
      <div
        className={`ml-2 absolute inline-flex items-center gap-1 ${
          showInfoIcon && data.sourceIcon ? "w-[36px]" : "w-[16px]"
        }`}
      >
        {showInfoIcon && (
          <Info
            className={`text-gray400 hover:text-blue500 cursor-pointer w-4 h-4 align-sub`}
            onClick={onShowMore}
          />
        )}
        {data.sourceIcon && (
          <Image
            type="favicon"
            src={data?.sourceIcon}
            className="w-4 h-4 relative bottom-[1px] object-contain aspect-square"
          />
        )}
      </div>
    );
  };

  const { datetime } = getEventDateTime(data?.eventDateTime);

  return (
    <span
      className={`relative hover:cursor-default ${
        showInfoIcon && data.sourceIcon ? "pr-[36px]" : "pr-[16px]"
      }`}
    >
      <EventDetailsTooltip data={{ ...data, message: activityTitle }} eventTime={datetime}>
        <span>{activityTitle}</span>
      </EventDetailsTooltip>
      {renderRightIcon()}
    </span>
  );
};

export default ShipmentStep;
