import { defaultThumbnailBase64, DEFAULT_FAVICON_IMAGE_BASE64 } from "scripts/constants";
import { emptyFunction } from "scripts/helpers";

const Image = ({
  src = "",
  title = "",
  alt = "",
  className = "inline-block",
  width,
  height,
  onClick = emptyFunction,
  type = "image",
}: {
  src: string;
  title?: string;
  alt?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
  type?: "image" | "favicon";
}) => (
  <img
    {...{ src: src ?? "", title, alt, className, width, height, onClick }}
    onError={({ target }: any) => {
      target.src = type === "image" ? defaultThumbnailBase64 : DEFAULT_FAVICON_IMAGE_BASE64;
    }}
  />
);

export default Image;
