import CheckCircle from "components/svgs/CheckCircle";
import Copy from "components/svgs/Copy";
import Download from "components/svgs/Download";
import { Button, Modal } from "console-system-components";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyFunction } from "scripts/helpers";

const ShareOptionModal = ({ isOpenShare, setIsOpenShare = emptyFunction, billOfLading }: any) => {
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);
  const currentURL = window.location.href;

  useEffect(() => {
    setIsCopied(false);
  }, [isOpenShare]);

  const handleCopyURL = () => {
    navigator?.clipboard?.writeText(currentURL);
    setIsCopied(true);
  };

  const handleDownload = () => {
    const canvas = document.getElementById(`${window.location.href}-download`) as any;

    const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");

    downloadLink.href = pngUrl;
    downloadLink.download = `shipment-${billOfLading}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Modal
      title={t("Sharing options")}
      visible={isOpenShare}
      onCancel={() => {
        setIsOpenShare(false);
      }}
      customFooter={null}
      centered
    >
      <div className="w-full flex gap-4">
        <div className="text-blue600 bg-blue100 w-8 min-w-8 h-8 flex justify-center items-center rounded-xl">
          1
        </div>
        <div className="h-auto flex-1">
          <p className="mb-2 sm_body_b1_reg">{t("Via public link")}</p>
          <div className="bg-gray50 border border-solid border-gray200 rounded grid grid-cols-[1fr_40px]">
            <div
              className={`px-3 py-2 grid gap-1 ${
                isCopied ? "grid-cols-[16px_1fr]" : ""
              } border border-transparent border-solid border-r-[1px] border-r-gray200`}
            >
              {isCopied && <CheckCircle className="text-green500 w-4 h-4" />}
              <div className="sm_body_b2_reg truncate w-full">
                {isCopied ? t("The public URL has been copied to clipboard.") : currentURL}
              </div>
            </div>
            <div
              className="w-[40px] h-full flex items-center justify-center hover:cursor-pointer hover:text-gray100"
              onClick={handleCopyURL}
            >
              <Copy className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 mt-6">
        <div className="w-8 min-w-8 h-8 text-blue600 bg-blue100 flex justify-center items-center rounded-xl">
          2
        </div>
        <div className="flex-1">
          <p className="mb-0 sm_body_b1_reg">{t("Via QR code")}</p>
          <div className="flex flex-col items-start">
            <QRCode
              id={`${window.location.href}-download`}
              value={window.location.href}
              size={450}
              includeMargin
              className="hidden"
            />
            <QRCode
              id={window.location.href}
              value={window.location.href}
              size={160}
              includeMargin
            />
            <Button
              icon={<Download />}
              type="default"
              className="w-[160px]"
              onClick={handleDownload}
            >
              {t(`Download`)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareOptionModal;
