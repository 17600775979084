import Button from "components/Button";
import BackV2 from "components/svgs/BackV2";
import NotFoundIcon from "components/svgs/Notfound";
import React from "react";
import { useTranslation } from "react-i18next";
import useNavigateCustom from "hooks/useNavigateCustom";
import { HOME_PAGE } from "scripts/constants";
import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigateCustom();
  const { t } = useTranslation();

  return (
    <div className="page-not-found flex items-center justify-center flex-col min-h-screen">
      <NotFoundIcon />
      <div className="py-[32px]">
        <div className="text-center">
          <span className="sm_heading_h5">{t("Page not found")}</span>
        </div>
        <div className="text-center mt-2">
          <span className="sm_body_b1_reg text-[#6B7280]">
            {t("Sorry, the page you requested could not be found.")}
          </span>
        </div>
      </div>
      <div className="page-not-found__actions flex flex-row">
        <Button
          onClick={() => navigate(-1)}
          size="large"
          className="mr-4"
          icon={<BackV2 className="text-white" />}
        >
          {t("Back to Previous page")}
        </Button>
        <Button
          className="home-icon"
          onClick={() => (window.location.href = HOME_PAGE)}
          size="large"
          type="default"
          icon={<HomeIcon className="text-gray800" />}
        >
          {t("Home")}
        </Button>
      </div>
    </div>
  );
};

const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m2 6.48 6-4.667 6 4.666v7.334a1.333 1.333 0 0 1-1.333 1.333H3.333A1.333 1.333 0 0 1 2 13.813V6.479Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15.146V8.48h4v6.667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotFound;
