import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentEstDelivered(props: any) {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" {...props}>
      <rect x={1.333} y={1} width={26} height={26} rx={9} fill={backgroundColor} />
      <path
        d="M20.062 7.229h.13a.39.39 0 00.391-.391v-.781a.39.39 0 00-.39-.391H8.472a.39.39 0 00-.39.39v.782c0 .216.175.39.39.39h.13c0 2.623 1.049 5.398 3.164 6.771-2.125 1.38-3.163 4.164-3.163 6.771h-.13a.39.39 0 00-.391.39v.782c0 .216.175.39.39.39h11.72a.39.39 0 00.39-.39v-.781a.39.39 0 00-.39-.39h-.13c0-2.623-1.049-5.398-3.164-6.772 2.125-1.38 3.163-4.163 3.163-6.77zm-9.896 0H18.5c0 3.308-1.866 5.99-4.167 5.99-2.301 0-4.167-2.682-4.167-5.99zM18.5 20.77h-8.334c0-3.308 1.866-5.99 4.167-5.99 2.301 0 4.167 2.682 4.167 5.99z"
        fill={iconColor}
      />
      <rect x={1.333} y={1} width={26} height={26} rx={9} stroke={borderColor} strokeWidth={2} />
    </svg>
  );
}

const MemoShipmentEstDelivered = React.memo(ShipmentEstDelivered);
export default MemoShipmentEstDelivered;
