import * as React from "react";

const Home = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.6173 14.2664L28.6155 14.2646L17.7365 3.38598C17.2728 2.92206 16.6563 2.6665 16.0005 2.6665C15.3447 2.6665 14.7282 2.92186 14.2643 3.38577L3.39097 14.2589C3.38731 14.2625 3.38365 14.2664 3.37998 14.2701C2.42774 15.2278 2.42937 16.7817 3.38466 17.737C3.82111 18.1737 4.39754 18.4266 5.01386 18.453C5.03888 18.4555 5.06411 18.4567 5.08955 18.4567H5.52315V26.4627C5.52315 28.0469 6.81214 29.3359 8.39677 29.3359H12.653C13.0843 29.3359 13.4343 28.9861 13.4343 28.5546V22.2779C13.4343 21.5549 14.0223 20.9669 14.7453 20.9669H17.2557C17.9786 20.9669 18.5667 21.5549 18.5667 22.2779V28.5546C18.5667 28.9861 18.9164 29.3359 19.348 29.3359H23.6042C25.1888 29.3359 26.4778 28.0469 26.4778 26.4627V18.4567H26.8799C27.5355 18.4567 28.152 18.2013 28.6161 17.7374C29.5724 16.7805 29.5728 15.2239 28.6173 14.2664Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="2.66699"
          y1="2.6665"
          x2="2.66699"
          y2="29.3359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#679CF6" />
          <stop offset="1" stopColor="#4072EE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Home;
