import * as React from "react";
import { getShipmentIconColor } from "scripts/helpers";

function ShipmentIntransitNoSquare(props: any) {
  const { backgroundColor }: any = getShipmentIconColor(props?.status);
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_10535_68604)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.885 14.367v-5.91L16 5.503v5.685a.384.384 0 01-.196.34l-4.919 2.84zM4.732 11.43H.605a.605.605 0 010-1.211h4.106c.71 0 .71-1.134 0-1.134H2.244a.605.605 0 010-1.21h1.93c.71 0 .71-1.134 0-1.134H.604a.605.605 0 010-1.21h4.147L9.82 8.456v5.91L4.732 11.43zm.502-6.848l5.119 2.956 5.119-2.956-5.11-2.95H7.012a.605.605 0 000 1.211h.708a.14.14 0 01.136.105.14.14 0 01-.065.159L5.234 4.582zm-.291-2.95a.605.605 0 010 1.211h-3.68a.605.605 0 010-1.21h3.68z"
          fill={backgroundColor}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10535_68604">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShipmentIntransitNoSquare;
