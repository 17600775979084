import { ReactNode } from "react";
import "./WrapperSection.scss";

interface WrapperSectionProps {
  type?: "border" | "shadow";
  children?: ReactNode;
  className?: string;
  id?: string;
}

const WrapperSection = ({
  type = "border",
  className = "",
  id = "",
  children,
}: WrapperSectionProps) => {
  return (
    <div id={id} className={`wrapper-section wrapper-section-${type} ${className}`}>
      {children}
    </div>
  );
};

export default WrapperSection;
