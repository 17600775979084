import { memo } from "react";
import { emptyFunction } from "scripts/helpers";
import { Button as ButtonBody } from "console-system-components";

import "./Button.scss";

export const BUTTON_THEMES = {
  withIcon: "with-icon",
  primary: "primary",
  redText: "red-text",
};

const Button = ({
  children,
  onClick = emptyFunction,
  type = "primary",
  icon = undefined,
  className = "",
  theme = "standard",
  disabled = false,
  size = "",
  loading = false,
  htmlType = ''
}: {
  children: any;
  onClick?: (e: string) => void;
  type?: "default" | "primary" | "dashed" | "link" | "text" | string;
  icon?: JSX.Element;
  className?: string;
  theme?: "red" | "green" | "standard";
  disabled?: boolean;
  size?: string;
  loading?: boolean;
  htmlType?: string;
}) => {
  return (
    <ButtonBody {...{ type, icon: icon, className : `button-system ${className}`, onClick, theme, disabled , size, loading, htmlType } as any}>
      {children && children}
    </ButtonBody>
  );
};

export default memo(Button);
