import { getShipmentIconColor } from "scripts/helpers";

const ShipmentCreated = (props: any) => {
  const { iconColor, borderColor, backgroundColor }: any = getShipmentIconColor(props?.status);

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 10C2 5.58172 5.58172 2 10 2H18C22.4183 2 26 5.58172 26 10V18C26 22.4183 22.4183 26 18 26H10C5.58172 26 2 22.4183 2 18V10Z"
        fill={backgroundColor}
      />
      <path
        d="M9.5 10.5V17.5H18.5V10.5H9.5ZM11.5 11.5V12.5H10.5V11.5H11.5ZM10.5 14.5V13.5H11.5V14.5H10.5ZM10.5 15.5H11.5V16.5H10.5V15.5ZM17.5 16.5H12.5V15.5H17.5V16.5ZM17.5 14.5H12.5V13.5H17.5V14.5ZM17.5 12.5H12.5V11.5H17.5V12.5Z"
        fill={iconColor}
      />
      <path
        d="M10 4H18V0H10V4ZM24 10V18H28V10H24ZM18 24H10V28H18V24ZM4 18V10H0V18H4ZM10 24C6.68629 24 4 21.3137 4 18H0C0 23.5228 4.47715 28 10 28V24ZM24 18C24 21.3137 21.3137 24 18 24V28C23.5228 28 28 23.5228 28 18H24ZM18 4C21.3137 4 24 6.68629 24 10H28C28 4.47715 23.5228 0 18 0V4ZM10 0C4.47715 0 0 4.47715 0 10H4C4 6.68629 6.68629 4 10 4V0Z"
        fill={borderColor}
      />
    </svg>
  );
};

export default ShipmentCreated;
