import useQueryData from "hooks/useQueryData";
import { useEffect, useState } from "react";
import ActivitiesList from "./components/ActivitiesList";
import ContainerList from "./components/ContainerList";
import GeneralInformation from "./components/GeneralInformation";
import ShipmentDetailsHeader from "./components/ShipmentDetailsHeader";
import "./ShipmentDetails.scss";

interface ShipmentDetailsViewProps {
  oriMaterialData?: any;
  portData?: PortDataProps;
  shipmentDetails: any;
  isLoadingShipmentDetails: boolean;
  isLoadingContainers?: boolean;
}

export interface PortDataProps {
  ports: Port[];
  routes: Route[];
  origin: Destination;
  destination: Destination;
}

export interface Destination {
  portId: string;
  address: Address;
  imageUrl: string;
}

export interface Address {
  country: string;
}

export interface Port {
  id: string;
  name: string;
  alias: string;
  issue: Issue | null;
}

export interface Issue {
  type: Type;
  alias: string;
  description: string;
}

export enum Type {
  DeliveredLate = "DELIVERED_LATE",
  PickedUpLate = "PICKED_UP_LATE",
  PotentiallyLate = "POTENTIALLY_LATE",
  LateShipment = "LATE_SHIPMENT",
}

export interface Route {
  portFrom: string;
  portTo: string;
  status: Status;
  volume: Volume;
  mode: string;
  averageTime: number;
  averageTimeUnit: Unit;
  details: Details;
  issue: Issue | null;
  currentShipment?: {
    isCurrentRoute: boolean;
  };
  id?: string;
}

export enum Unit {
  Day = "day",
}

export interface Details {
  activities: Activity[];
}

export interface Activity {
  status: Status;
  subActivities: SubActivity[];
}

export interface Status {
  code: StatusCode;
  name: StatusName;
}

export enum StatusCode {
  Booked = "BOOKED",
  Created = "CREATED",
  Intransit = "INTRANSIT",
}

export enum StatusName {
  Booked = "Booked",
  Created = "Created",
  InTransit = "In-transit",
}

export interface SubActivity {
  name: SubActivityName;
  issue: Issue | null;
  averageTime: AverageTime;
}

export interface AverageTime {
  value: number;
  unit: Unit;
}

export enum SubActivityName {
  SubActivity = "Sub Activity",
}

export interface Volume {
  value: number;
  currency: Currency;
}

export enum Currency {
  Usd = "USD",
}

const ShipmentDetailsView = ({
  shipmentDetails,
  isLoadingShipmentDetails = true,
  isLoadingContainers,
}: ShipmentDetailsViewProps) => {
  const QUERY_DATA = useQueryData();
  const { tab = `overview` } = QUERY_DATA;
  
  const [tabActive, setTabActive] = useState(tab);

  useEffect(() => {
    setTabActive(tab);
  }, [tab]);

  return (
    <div className="flex flex-col">
      <ShipmentDetailsHeader
        {...{
          isLoadingShipmentDetails,
          shipmentDetails,
        }}
      />
      <div className="shipment_shipment-detail-container flex-col gap-0 tablet:gap-[16px] desktop:flex-row">
        <div className="sm-detail-left w-full desktop:w-3/4">
          <GeneralInformation
            {...{
              isLoading: isLoadingShipmentDetails,
              shipmentItemData: shipmentDetails ?? {},
              tabActive,
            }}
          />
          <ContainerList
            shipmentDetails={shipmentDetails}
            containers={shipmentDetails?.containers}
            loading={isLoadingContainers}
            tabActive={tabActive}
          />
        </div>
        <div className="sm-detail-right w-full desktop:w-1/4 min-w-[336px]">
          <ActivitiesList
            isLoading={isLoadingShipmentDetails}
            shipmentDetails={shipmentDetails}
            tabActive={tabActive}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetailsView;
