import { Tooltip } from "antd";
import Warning from "components/svgs/Warning";
import { ExceptionProps } from "interface/general";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { DATA_EXCEPTION_MESSAGE, DATA_EXCEPTION_TYPE } from "scripts/constants";
import "./DataExceptionTooltip.scss";

const DataExceptionTooltip = ({
  dataExceptions,
}: {
  dataExceptions: ExceptionProps[] | undefined;
}) => {
  const { t } = useTranslation();
  if (isEmpty(dataExceptions)) return null;
  return (
    <Tooltip
      overlayClassName="sm-tooltip-exception"
      autoAdjustOverflow={true}
      placement="top"
      title={
        <>
          <div className="sm_body_b1_semi text-white p-2 bg-gray600 rounded-t-[4px]">
            {t("Data Exception(s)")}
          </div>
          <div className="exception-content__wrapper p-2 bg-[#00000066] flex flex-col gap-2 rounded-b-[4px] overflow-auto max-h-[250px]">
            {dataExceptions?.map((it: ExceptionProps, index: number) => {
              const msg = DATA_EXCEPTION_MESSAGE?.[it?.exception ?? ""] ?? it?.message;
              let code: any;
              switch (it.exception) {
                case DATA_EXCEPTION_TYPE.ORIGIN_INVALID_COUNTRY_CODE:
                case DATA_EXCEPTION_TYPE.ORIGIN_INVALID_REGION:
                case DATA_EXCEPTION_TYPE.DESTINATION_INVALID_COUNTRY_CODE:
                case DATA_EXCEPTION_TYPE.DESTINATION_INVALID_REGION:
                  code = it?.code;
                  break;
                case DATA_EXCEPTION_TYPE.INCORRECT_BILL_OF_LADING_FORMAT:
                  code = it?.billOfLading;
                  break;
                case DATA_EXCEPTION_TYPE.INCORRECT_CONTAINER_NUMBER_FORMAT:
                  code = it?.container;
                  break;
                default:
                  break;
              }
              return (
                <div key={index}>
                  <div className="sm_body_b3_reg mb-1 text-white">{msg}</div>
                  {code && <div className="sm_body_b3_semi text-red400">{code}</div>}
                </div>
              );
            })}
          </div>
        </>
      }
    >
      <Warning className="align-sub" />
    </Tooltip>
  );
};

export default DataExceptionTooltip;
